<template>
  <div class="navh5">

    <div class="fixedh5">

      <RichBut @click="tapClick('goHome')">
        <template #default="{richState}">
          <img v-if="richState=='down'" 
          class="img-logoh5" src="../assets/image/v3/logo_lan_down.png">

          <img v-else
          class="img-logoh5" src="../assets/image/v3/logo_lan.png">
        </template>
      </RichBut>

      <div class="row acenter">
        
        <RichBut v-if="lang"
        @click="yy = !yy;genduo=false;"
        class="but-yyh5" styleType="bhui"
        :select="yy">
          <img class="img-gqh5" :src="lang.url">
          <span>{{lang.tag}}</span>
        </RichBut>

        <div class="but-genduoh5" @click="genduo = !genduo;yy = false">
          <img v-if="genduo" 
          class="img-guanbih5" src="../assets/image/v3/guanbi_h5.png">
          <img v-else
          class="img-genduoh5" src="../assets/image/v3/caidan_h5.png">
        </div>
      </div>
    </div>
    
    <div v-if="yy" class="yuyanh5" @click.self="yy = false">

      <div class="sizesh5">
        <div class="sizeh5"  @click.self="yy = false">
          
          <RichBut @click="tapClick('switchLang', item)"
          v-for="(item, index) in langs" :key="'lang'+index"
          class="cellh5" styleType="bhuimin">
            <img class="img-gqh5" :src="item.url"/>
            <span class="font14 fontc">{{item.label}}</span>
          </RichBut>
        </div>
      </div>
    </div>

    <div v-if="genduo" class="genduoh5">
      
      <RichBut @click="tapClick('goSetUp')"
      class="cellh5" styleType="bhuimin">
        <span class="font16 fontc">{{$t('m.个人中心')}}</span>
        <img class="img-jiantouh5" src="../assets/image/v3/jiantouyou_hui.png">
      </RichBut>

      <RichBut @click="tapClick(item.click)"
      v-for="(item, index) in menus" :key="'genduo'+index"
      class="cellh5" styleType="bhuimin">
        <span class="font16 fontc">{{$t('m.'+item.title)}}</span>
        <img class="img-jiantouh5" src="../assets/image/v3/jiantouyou_hui.png">
      </RichBut>
    </div>
  </div>
</template>

<script>

import RichBut from "@/components/RichBut.vue"
export default {
  components: {
    RichBut,
  },

  props: {
    
    menus: {
      type: Array,
      default: undefined
    },

    lang: {
      type: Object,
      default: undefined
    },

    langs: {
      type: Array,
      default: undefined
    }
  },


  data() {
    return {
      yy: false, //true 显示语言选择
      genduo: false, //true 显示更多功能
    };
  },

  mounted() {
  },

  methods: {
    
    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      this.genduo = false;
      this.yy = false;
      this.$emit('tapClick', name, param);
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.navh5 {
  width: 100%;
  height: 60px;

  .fixedh5 {
    z-index: 888;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #fff;

    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-bottom: 1px solid #E4E6EF !important;
    .img-logoh5 {
      width: 100px;
      height: 24px;
      margin-right: 80px;
    }

    .but-yyh5 {
      display: flex;
      flex-direction: row;
      align-items: center;

      height: 28px;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #E4E6EF;
      margin-right: 12px;

      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      .img-gqh5 {
        width: 24px;
        height: 16px;
        margin-right: 4px;
      }
    }
    
    .but-genduoh5 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      .img-genduoh5 {
        width: 20px;
        height: 20px;
      }
      .img-guanbih5 {
        width: 20px;
        height: 20px;
      }
    }
  }
  
  .yuyanh5 {
    z-index: 888;
    position: fixed;
    top: 60px;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    .sizesh5 {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .sizeh5 {
        z-index: 88;
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 140px;
        padding: 10px;
        box-shadow:0 5px 10px 0 rgba($color: #000000, $alpha: 0.1);
        .cellh5 {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          border-radius: 6px;
          .img-gqh5 {
            width: 24px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .genduoh5 {
    z-index: 889;
    position: fixed;
    top: 60px;
    left: 0;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #fff;
    .cellh5 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 57px;
      padding: 0 16px;
      .img-jiantouh5 {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
