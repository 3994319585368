<template>
  <div class="homeMain">
    <TopNavigation />
    
    <component v-if="current"
    :is="'Home'+current" 

    :head="head" :one="one" :two="two" :three="three" 
    :threex="threex" :rankingMenus="rankingMenus" :rankings="rankings"
    :seven="seven" :four="four"
    :eight="eight" :five="five" :nine="nine"
    :six="six" :eleven="eleven"
    @tapClick="tapClick" />

    <Bottom />
  </div>
</template>

<script>

import {getShowPage, isLogin} from "@/utils/function.js"
import { getStrategyRank } from "@/request/api";
import * as echarts from "echarts";

import TopNavigation from "@/components/TopNavigation";
import Bottom from "@/components/Bottom";
import HomePc from "./HomePc.vue"
import HomeH5 from "./HomeH5.vue"

export default {
  name: "Home",

  components: {
    TopNavigation: TopNavigation,
    Bottom: Bottom,
    HomePc: HomePc,
    HomeH5: HomeH5,
  },

  data() {
    return {
      current: '', // 当前渲染的组件

      head: {
        'span1':'无需编码的',
        'span2':'自动加密货币交易',
        'span3':'机器人',
        'span4':'像专业人士一样，释放您的交易潜力。',
        'but1':{'txt':'立即交易', 'click':'toExchange'},
        'src1': require('@/assets/image/v3/head_1.png')
      },

      one: {
        'span1': '通过 Vtrading 机器人最大化您的利润',
        'span2': '将您的交易帐户与API密钥连接起来并立即创建您的加密机器人',
        'but1': {'txt':'试试看', 'click':'toExchange'},
        'imgs': [
          {'title':'交易机器人', 
            'labels':['适用于每种市场趋势看涨、持平或看跌。'], 
            'url': require('@/assets/image/v3/home_1_1.png')},
          {'title':'终端', 
            'labels':['批量执行手动订单。'], 
            'url': require('@/assets/image/v3/home_1_2.png')},
          {'title':'移动应用程序', 
            'labels':['下载应用程序以随时随地管理您的交易机器人。', '不错过任何市场趋势。'], 
            'url': require('@/assets/image/v3/home_1_3.png')},
          {'title':'交易信号', 
            'labels':['从布林线、价格警报、交易视图信号等中进行选择，以便交易机器人在正确的时间启动和停止。'], 
            'url': require('@/assets/image/v3/home_1_4.png')},
          {'title':'跟单交易', 
            'labels':['帮助您提高机器人的利润并学习其他交易者的策略。'], 
            'url': require('@/assets/image/v3/home_1_5.png')}
        ],
      },
      
      two: {
        'span1': '在顶级加密货币交易所运行交易机器人',
        'span2': '将您的交易帐户与 API 密钥连接起来并立即创建您的加密机器人。',
        'but1': {
          'txt':'接入账户',
          'click':'toIndex'
        },
        'src1': require('@/assets/image/v3/home_2_icq.png'),
        'src2': require('@/assets/image/v3/binance.png'),
        'src3': require('@/assets/image/v3/okx.png'),
        'src4': require('@/assets/image/v3/bybit.png'),
        'src5': require('@/assets/image/v3/bitfinex.png'),
        'src6': require('@/assets/image/v3/bitstamp.png'),
        'src7': require('@/assets/image/v3/coinbase.png'),
        'src8': require('@/assets/image/v3/gemini.png'),
        'src9': require('@/assets/image/v3/cointr.png'),
        'src10': require('@/assets/image/v3/gate.png'),
        'src11': require('@/assets/image/v3/bitget.png'),
        'src12': require('@/assets/image/v3/htx.png'),
        'src13': require('@/assets/image/v3/kucoin.png'),
      },

      three: {
        'span1':'复制顶级交易机器人策略',
        'span2':'发现并复制交易市场上最好的交易机器人以增加您的利润',
        'but1': {
          'txt':'下载APP',
          'click':'scrollToId'
        },
        'imgs': [
          require('@/assets/image/v3/home_3_1.jpg'),
          require('@/assets/image/v3/home_3_2.jpg'),
          require('@/assets/image/v3/home_3_3.jpg'),
          require('@/assets/image/v3/home_3_4.jpg'),
          require('@/assets/image/v3/home_3_5.jpg'),
        ]
      },

      threex: {
        'span1':'策略收益排行榜',
        'span2':'与优秀的策略交易员同行',
        'span3':'仓位阈值',
        'span4':'总投入',
        'span5':'总收益率',
        'span6':'账户权益',
        'span7':'交易笔数',
        'span8':'持仓盈亏',
        'span9':'交易额',
        'span10':'总年化',
        'span11':'最大回撤',
        'but1': {
          'txt':'我要跟单',
          'click':'scrollToId'
        }
      },
      rankingMenus: undefined, //排行榜菜单
      rankings: undefined, //排行榜数据

      seven: {
        'span1': '从2017年开始',
        'span2': 'Vtrading和大家一起经历了熊市和牛市',
        'span3': '全球用户',
        'span4': '100W+',
        'span5': '机器人数量',
        'span6': '12W+',
        'span7': '日交易量',
        'span8': '$60M+',
        'src1': require('@/assets/image/v3/home_7_1.png'),
        'src2': require('@/assets/image/v3/home_7_1.png'),
        'src3': require('@/assets/image/v3/home_7_1.png')
      },

      four: {
        'span1':'Vtrading是怎么运行的？',
        'span2':'将您的交易所与 API 密钥连接起来并创建机器人进行交易。',
        'span3':'您的资金绝对安全，API经过多重算法加密，安全性高！',
        'span4':'您可以通过API连接多个交易所，管理不同的资金账户。',
        'span5':'更轻松地检查您的帐户或执行订单。',
        'src1': require('@/assets/image/v3/home_4_icq.png'),
        'but1': {
          'txt':'连接账户',
          'click':'toIndex'
        },
      },

      eight: {
        'span1':'关注您的利润增长',
        'span2':'从强大的交易机器人到智能订单工具，vtrading 机器人可以帮助您在加密世界中实现利润最大化',
        'src1':require('@/assets/image/v3/home_8_bg.png'),
        'but1':{
          'txt':'创建机器人',
          'click':'toAddBot'
        },
        'menus':[
          {
            'title':'交易机器人',
            'labels':['借助全自动交易机器人，24/7 从每个市场变动中获利。', '在几分钟内设置 GRID 和 DCA 策略。'],
            'url':[
              require("../assets/image/v2/home_8_1_1.svg"),
              require("../assets/image/v2/home_8_1_2.svg"),
              require("../assets/image/v2/home_8_1_3.svg")
            ],
          },
          {
            'title':'智能订单',
            'labels':['通过强大的订单功能执行您的交易策略。', '通过不同的功能控制您的风险或增加您的利润，如止损、止盈、追踪止盈、单轨等。'],
            'url':require('../assets/image/v2/home_8_2.svg')
          },
          {
            'title':'信号终端',
            'labels':['信号终端可以更智能地运行交易机器人。', '无论是复制的策略还是自己创建的外部信号通过 TradingView ，机器人可以自动执行。'],
            'url':require('../assets/image/v2/home_8_3.svg')
          }
        ],
      },

      five: {
        'span1':'随时随地进行交易',
        'span2':'扫描下载',
        'span3': 'iOS & Android',
        'src1': require('@/assets/image/v3/home_5_iphone.png'),
        'src2': require('@/assets/image/v3/home_5_iphone_en.png'),
        'src3': require('@/assets/image/v3/vector.png'),
        'url1': 'https://wap.vtrading.com/H5/pages/download/download.html?time='
      },

      nine: {
        'src1': require('@/assets/image/v3/jiantoushang_hui.png'),
        'src2': require('@/assets/image/v3/jiantouxia_hui.png'),
        'faqs':[
          {'title':'设置机器人需要多长时间？',
          'label':'只需几分钟即可开始交易，只需通过API密钥连接到您的交易所，选择所需的交易货币并设置参数即可。',
          'show':false},
          {'title':'启动交易机器人需要多少钱？',
          'label':'没有最低交易限制，但为了使策略良好运行，建议单个机器人投资超过 200 美元。',
          'show':false},
          {'title':'Vtrading可以在哪些类型的市场进行交易？',
          'label':'Vtrading 有现货和合约策略，以及网格机器人、DCA 机器人和其他类型的策略。 无论是牛市、熊市还是波动市场，您都可以选择不同的策略来执行交易。',
          'show':false},
          {'title':'使用 vtrading 机器人进行交易安全吗？',
          'label':'Vtrading不存储您的资金，您的账户只能通过API密钥访问进行交易，并且API经过多重算法加密，绝对安全。 您可以完全控制您的资金。',
          'show':false},
          {'title':'我可以随时取款吗？',
          'label':'Vtrading不存储您的资金，因此您可以随时终止您的策略并转移您的资金。',
          'show':false},
        ]
      },

      six: {
        'span1':'复制其他交易者的策略',
        'span2':'订阅其他使用者的策略信号',
        'span3':'订阅其他使用者的策略信号',
        'span4':'无须配置参数',
        'span5':'小白也能快速启动策略',
        'span6':'自己建立策略信号发起跟随',
        'span7':'其他使用者可通过付费跟随您的策略',
        'src1': require('@/assets/image/v3/home_6_1.png'),
        'src2': require('@/assets/image/v3/home_6_2.png'),
        'src3': require('@/assets/image/v3/home_6_3.png')
      },

      eleven: {
        'span1': 'Vtrading数字资产',
        'span2': 'Al量化',
        'span3': '服务平台介绍',
        'span4': 'Vtrading成⽴于2017年12⽉，旨在为不同层次的客户提供差异化的量化交易策略服务。',
        'span5': 'Vtrading数字资产AI量化服务平台提供可视化图元平台编辑策略、Hbase系统快速调试回测、自定义发布商城策略、资管系统、智能跟单系统、独⽴返佣系统、⼤客户私有定制部署、第三⽅嵌⼊式系统等功能及服务。',
        'span6': '用户可通过Vtrading提供的多种服务来实现不同的量化需求，让普通用户无需懂得编写代码也能运行量化策略，让量化交易变得更简单。',
        'but1': {
          'txt':'试试看',
          'click':'toExchange'
        },
        'src1': require('@/assets/image/v3/home_8_bg.png'),
        'src2': require('@/assets/image/v3/home_11_ai.png'),
      }
    };
  },

  created() {

    this.httpGetStrategyRank();
    this.initView();
  },

  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  methods: {
    
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
    },

    //滚动到指定byId
    scrollToId(byId) {
      document.getElementById(byId).scrollIntoView({behavior: "smooth",block: "center"});
    },

    //初始化排行榜数据
    initRanking(datas) {
      
      var rankings = [];
      var rankingMenus = [];
      for (let i = 0; i < datas.length; i++) {

        rankingMenus.push({'value':i, 'label':'TOP '+(i+1)});
        
        var data = datas[i];
        var investCoinnNum = '0';
        try {investCoinnNum = data.investCoinnNum;
        investCoinnNum = this.Public.toMinNum(investCoinnNum, 4)} catch (error) {}


        //仓位阈值
        var openThreshold = '0';
        try {openThreshold = data.openThreshold * 100;
        openThreshold = this.Public.toMinNum(openThreshold, 2);} catch (error) {}

        //总收益
        var realEarnings = '0';
        try {realEarnings = data.realEarnings;
        realEarnings = this.Public.toMinNum(realEarnings, 4);
        realEarnings = realEarnings > 0 ? '+'+realEarnings : realEarnings} catch (error) {}
        //总收益率
        var realEarningsRate = '0';
        try {realEarningsRate = data.realEarningsRate;
        realEarningsRate = this.Public.toMinNum(realEarningsRate, 2);
        realEarningsRate = realEarningsRate > 0 ? '+'+realEarningsRate : realEarningsRate} catch (error) {}

        //账户权益
        var marginBalance = '0';
        try {marginBalance = data.position.marginBalance;
        marginBalance = this.Public.toMinNum(marginBalance, 4)} catch (error) {}
        //持仓盈亏
        var profitUnreal = '0';
        try {profitUnreal = data.position.profitUnreal;
        profitUnreal = this.Public.toMinNum(profitUnreal, 4);
        profitUnreal = profitUnreal > 0 ? '+'+profitUnreal : profitUnreal} catch (error) {}
        //总年化
        var realYearIncome = '0';
        try {realYearIncome = data.realYearIncome;
        realYearIncome = this.Public.toMinNum(realYearIncome, 2);
        realYearIncome = realYearIncome > 0 ? '+'+realYearIncome : realYearIncome} catch (error) {}

        //交易笔数
        var orderNumber = '0';
        try {orderNumber = data.tradeMoney.orderNumber;
        orderNumber = this.Public.toMinNum(orderNumber, 0)} catch (error) {}
        //交易额
        var tradeMoney = '0';
        try {tradeMoney = data.tradeMoney.tradeMoney;
        tradeMoney = this.Public.toMinNum(tradeMoney, 4)} catch (error) {}
        //最大回撤
        var maxDrawdown = '0';
        try {maxDrawdown = data.maxDrawdown;
        maxDrawdown = this.Public.toMinNum(maxDrawdown, 2)} catch (error) {}
        

        //收益走势图
        var datax = [];
        var datay = [];
        var maxlength;
        try {
          var lines = data.realEarningsLine.items;
          lines.pop();
          lines.push(data.realEarningsLine.last);
          for(var j = 0; j < lines.length ; j++) {

            var line = lines[j];
            var t = this.Public.getTime(line.t, 'y/M/d');
            datax.push(t);
            var e = this.Public.toMinNum(line.e, 2)
            datay.push(e);

            var length = (parseInt(e)+'').length;
            if (j==0 || length >maxlength) {
              maxlength = length;
            }
          }
        } catch (error) {}
        maxlength = maxlength - 2;
        maxlength = (maxlength < 0) ? 0 : maxlength;

        var lineMap = {
          // tooltip: { //提示框
          //   trigger: 'axis',
          //   position: function (pt) {
          //     return [pt[0], '10%'];
          //   },
          // },
          xAxis: { //直角坐标系 grid 中的 x 轴
            type: 'category', //value数值轴，适用于连续数据。
            boundaryGap: ['20%', '20%'], //坐标轴两边留白策略,
            data: datax
          },
          yAxis: { //直角坐标系 grid 中的 y 轴
            type: 'value',
            boundaryGap: ['20%', '20%'], //坐标轴两边留白策略,
            
            axisLabel: {
              formatter: function (value, index) {
                return value + '%';
              }
            }
          },

          grid: {
              left: (10+maxlength+'%'),
              right: '0%',
          },
  
          series: [{
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            lip : true,
            seriesLine: {
              clip : false,
              cursor : '',
            },
            
            itemStyle: { 
              color: '#1677FE'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: '#fff'
                },
                {
                  offset: 0,
                  color: '#1677FE'
                }
              ])
            },
            data: datay
          }]
        }

        rankings.push({lineMap, openThreshold,
          investCoinnNum,realEarnings, realEarningsRate,
          marginBalance,profitUnreal,realYearIncome,
          orderNumber,tradeMoney,maxDrawdown
        })
      }
      this.rankingMenus = rankingMenus;
      this.rankings = rankings;
    },
    
    /******************************************** https */
    //https排行榜
    httpGetStrategyRank() {
      
      getStrategyRank().then((res) => {

        if (res.status == 200) {
          this.initRanking(res.data);
        } else {
          // this.$message.error(res.msg);
        }
      });
    },

    /******************************************** 点击 */
    //点击事件(PC、H5)
    tapClick(name, param) {
      this[name](param);
    },

    //交易策略-首页
    toIndex() {
      if (isLogin(this)) return;
      this.$router.push({ path: "/transac" });
    },

    //创建机器人
    toAddBot() {
      if (isLogin(this)) return;
      this.$router.push({ path: "/robotList/createStrategy" });
    },

    //交易策略-我的交易所
    toExchange() {
      if (isLogin(this)) return;
      this.$router.push({ path: "/myExchange" });
    },

    //量化托管平台
    toVtrading() {
      window.open("https://project.vtrading.com");
    },
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.homeMain {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cbai60 {color: rgba($color: #ffffff, $alpha: 0.6);}
.but {cursor: pointer;}
</style>
