import { get, post } from './https'

// 注册
export const register = (params, loading=true) => post('/user/register', params, loading);

// 登录
export const login = (params, loading=true) => post('/user/login', params, loading);

// 判断是否绑定GA
export const getUserGAIsBind = (params, loading=true) => post('/system/getUserGAIsBind', params, loading);

// 获取邮箱验证码
export const sendEmail = (params, loading=true) => post('/user/sendEmail', params, loading);

// 找回密码
export const updatePassword = (params, loading=true) => post('/user/updatePassword', params, loading);

// 获取邀请信息
export const inviteDate = (params, loading=true) => post('/inviteUser/inviteDate', params, loading);

// 邀请链接访问记录 
export const inviteRequest = (params, loading=true) => post('/inviteUser/inviteRequest', params, loading);

// 修改用户信息
export const updateUser = (params, loading=true) => post('/user/updateUser', params, loading);

// 获取登录设备
export const loginEquipmentList = (params, loading=true) => post('/user/loginEquipmentList', params, loading);

// 退出登录
export const logout = (params, loading=true) => post('/user/logout', params, loading);

// 获取临时令牌 
export const getTemporaryToken = (params, loading=true) => post('/user/getTemporaryToken', params, loading);

// vtrading 登录 
export const scanCodeLogin = (params, loading=false) => post('/user/scanCodeLogin', params, loading);

// 获取Api公钥
export const getPublicKey = (params, loading=true) => post('/assets/getPublicKey', params, loading);

// 录入Api
export const addUserApi = (params, loading=true) => post('/assets/addUserApi', params, loading);

// 获取交易所列表
export const getExchangeList = (params, loading=true) => post('/system/getExchangeList', params, loading);

// 获取用户Api账户编号
export const getApiAccountId = (params, loading=true) => post('/assets/getApiAccountId', params, loading);

// 划转
export const accountTransfer = (params, loading=true) => post('/assets/accountTransfer', params, loading);

// 同步交易所单个Api
export const synchronizationSingelApi = (params, loading=true) => post('/assets/synchronizationSingelApi', params, loading);

// 同步币币单个币种 
export const synchronizationSingelCoinApi = (params, loading=true) => post('/assets/synchronizationSingelCoinApi', params, loading);

// 同步合约单个币种 
export const synchronizationSingelContractApi = (params, loading=true) => post('/assets/synchronizationSingelContractApi', params, loading);

// 获取聚合账户数据
export const querySumApiData = (params, loading=true) => post('/myExchange/querySumApiData', params, loading);

// 获取Api列表
export const queryApiListIconData = (params, loading=true) => post('/myExchange/queryApiListIconData', params, loading);

// 获取Api头部数据
export const queryApiDetailsTopData = (params, loading=true) => post('/myExchange/queryApiDetailsTopData', params, loading);

// 聚合账户顶部数据
export const querySumApiDetailsTopData = (params, loading=true) => post('/myExchange/querySumApiDetailsTopData', params, loading);

// 获取Api图表数据 
export const queryApiDetailsIconData = (params, loading=true) => post('/myExchange/queryApiDetailsIconData', params, loading);

// 聚合账户图表
export const querySumApiDetailsIconData = (params, loading=true) => post('/myExchange/querySumApiDetailsIconData', params, loading);

// 获取币币合约列表
export const synchronSingelCloudApi = (params, loading=true) => post('/assets/synchronSingelCloudApi', params, loading);

// 上传头像
export const uploadAvatar = (params, loading=true) => post('/user/uploadAvatar', params, loading);

// 查询用户详情
export const userDetail = (params, loading=true) => post('/user/userDetail', params, loading);

// 获取GA 秘钥/二维码
export const getGASecret = (params, loading=true) => post('/system/getGASecret', params, loading);

// 校验GA
export const checkGACode = (params, loading=true) => post('/system/checkGACode', params, loading);

// 绑定GA
export const bindGA = (params, loading=true) => post('/system/bindGA', params, loading);

// 机器人列表
export const queryRobotList = (params, loading=true) => post('/robot/queryRobotList', params, loading);

//获取用户api列表
export const getUserApiList = (params, loading=true) => post('/system/getUserApiList', params, loading);

// 获取币币交易对
export const getSpotPairList = (params, loading=true) => post('/system/getSpotPairList', params, loading);

// 获取合约交易对
export const getContractPairList = (params, loading=false) => post('/system/getContractPairList', params, loading);

// 获取DCA策略参数
export const queryStratgyParam = (params, loading=true) => post('/robot/queryStratgyParam', params, loading);

// 查询机器人详情
export const queryRobotDetails = (params, loading=true) => post('/robot/queryRobotDetails', params, loading);

// 机器人详情右侧数据
export const qureyRobotStrategyData = (params, loading=true) => post('/strategy/qureyRobotStrategyData', params, loading);

// 机器人详情日志
export const qureyStrategyTradeLogList = (params, loading=true) => post('/strategy/qureyStrategyTradeLogList', params, loading);

// 机器人详情成交记录
export const qureyStrategyOrderList = (params, loading=true) => post('/strategy/qureyStrategyOrderList', params, loading);

// 创建机器人
export const addRobot = (params, loading=true) => post('/robot/addRobot', params, loading);

// 获取币种涨跌幅
export const querylastIncrease = (params, loading=true) => post('/robot/querylastIncrease', params, loading);

// 获取交易所杠杆
export const getLeverMultiple = (params, loading=true) => post('/system/getLeverMultiple', params, loading);

// 删除机器人
export const deleteRobot = (params, loading=true) => post('/robot/deleteRobot', params, loading);

// 启动机器人实例
export const startRobot = (params, loading=true) => post('/robot/startRobot', params, loading);

// 暂停机器人
export const suspendRobot = (params, loading=true) => post('/robot/suspendRobot', params, loading);

// 重启机器人
export const restartRobot = (params, loading=true) => post('/robot/restartRobot', params, loading);

// 终止机器人
export const robotEnd = (params, loading=true) => post('/robot/robotEnd', params, loading);

// 修改机器人
export const updataRobot = (params, loading=falstruee) => post('/robot/updataRobot', params, loading);

// 活跃实例列表
export const qureyExecuteStrategyList = (params, loading=true) => post('/strategy/qureyExecuteStrategyList', params, loading);

// 查询历史策略
export const qureyHistoryStrategyList = (params, loading=true) => post('/strategy/qureyHistoryStrategyList', params, loading);

// 查询委托
export const getEntrustOrders = (params, loading=true) => post('/strategy/getEntrustOrders', params, loading);

// 撤销委托 
export const revokeEntrust = (params, loading=true) => post('/strategy/revokeEntrust', params, loading);

// 查询持仓
export const qureyStrategyPosition = (params, loading=true) => post('/strategy/qureyStrategyPosition', params, loading);

// 刷新单条实例
export const qureyExecuteStrategyById = (params, loading=true) => post('/strategy/qureyExecuteStrategyById', params, loading);

// 删除历史实例
export const deleteHistoryStrategy = (params, loading=true) => post('/strategy/deleteHistoryStrategy', params, loading);

// 加仓 
export const addPosition = (params, loading=true) => post('/strategy/addPosition', params, loading);

// 市价平仓 
export const closePosition = (params, loading=true) => post('/strategy/closePosition', params, loading);

// 验证/绑定邮箱
export const isEmailCheck = (params, loading=true) => post('/user/isEmailCheck', params, loading);

// 校验邮箱验证码
export const checkCode = (params, loading=true) => post('/user/checkCode', params, loading);

// 交易所连接注册 
export const queryExchangeOpenList = (params, loading=true) => post('/assets/queryExchangeOpenList', params, loading);

// 解除绑定Vtrading账号
export const unboundVt = (params, loading=true) => post('/system/unboundVt', params, loading);

// 解绑GA
export const userUnbindGA = (params, loading=true) => post('/system/userUnbindGA', params, loading);

// 绑定Vtrading账号
export const unbindVt = (params, loading=true) => post('/system/unbindVt', params, loading);

// 清除登录设备
export const deleteLogin = (params, loading=true) => post('/user/deleteLogin', params, loading);

// 删除Api 
export const deleteApi = (params, loading=true) => post('/assets/deleteApi', params, loading);

// api续期
export const apiRenewal = (params, loading=true) => post('/assets/apiRenewal', params, loading);

// 刷新单个api列表数据
export const refreshApiIconData = (params, loading=true) => post('/myExchange/refreshApiIconData', params, loading);

// 同步所有api
export const synchronizationApi = (params, loading=true) => post('/assets/synchronizationApi', params, loading);

// 绑定换绑邮箱 
export const bindingEmail = (params, loading=true) => post('/user/bindingEmail', params, loading);

// 文章列表
export const getArticleList = (params, loading=true) => post('/helpCenter/getArticleList', params, loading);

// 查询文章详情
export const getArticleDetails = (params, loading=true) => post('/helpCenter/getArticleDetails', params, loading);

// 上传文章
export const addArticle = (params, loading=true) => post('/helpCenter/addArticle', params, loading);

// 首页机器人列表
export const queryRobotCardData = (params, loading=true) => post('/robot/queryRobotCardData', params, loading);

// 查询网格数据
export const qureyCellsData = (params, loading=true) => post('/strategy/qureyCellsData', params, loading);

// 修改网格
export const updataGrid = (params, loading=true) => post('/strategy/updataGrid', params, loading);

// K线数据
export const queryCandlesticks = (params, loading=true) => post('/myExchange/queryCandlesticks', params, loading);

// 获取合约汇率
export const getContractSize = (params, loading=true) => post('/system/getContractSize', params, loading);

// 获取币种精度
export const queryContractPrecision = (params, loading=true) => post('/myExchange/queryContractPrecision', params, loading);

// 批量启动
export const batchStartRobot = (params, loading=true) => post('/robot/batchStartRobot', params, loading);

// 批量终止机器人
export const batchRobotEnd = (params, loading=true) => post('/robot/batchRobotEnd', params, loading);

// 批量删除机器人
export const batchDeleteRobot = (params, loading=true) => post('/robot/batchDeleteRobot', params, loading);

// 批量暂停机器人
export const batchSuspendRobot = (params, loading=true) => post('/robot/batchSuspendRobot', params, loading);

// 批量重启机器人
export const batchRestartRobot = (params, loading=true) => post('/robot/batchRestartRobot', params, loading);

//APP排行榜第一数据
export const getStrategyRank = (params, loading=false) => post('/strategy/getStrategyRank', params, loading);

//文章类型列表
export const getArticleType = (params, loading=true) => post('/article/getArticleType', params, loading);

//文章列表
export const articleList = (params, loading=true) => post('/article/articleList', params, loading);

//文章详情
export const articleDetails = (params, loading=true) => post('/article/articleDetails', params, loading);

//推荐文章
export const recommendedArticle = (params, loading=false) => post('/article/recommendedArticle', params, loading);

