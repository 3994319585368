import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import i18n from '@/i18n/i18n'   //国际化
export default new Vuex.Store({
  state: {
    isLoading: false,
    isSkeleton: false,
    timeNow: null,
    theme: localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light',

    apiData: localStorage.getItem('apiData'),
    isHome: false,
    noviceShow: false,
    show: false,
    
    // 页面传参使用（确保刷新页面也能继续有参数使用）
    pageParam: localStorage.getItem('pageParam') ? JSON.parse(localStorage.getItem('pageParam')) : {}, 

    lang: i18n.locale, //语言
    pc: true,
  },

  //计算属性 类似于 computed
  getters: {

    //语言Id
    langId(state){
      const langd = {'ch':1, 'en':3, 'ko':4, 'ja':5};
      return langd[state.lang]
    },
  },
  
  //同步方法  类似于methods
  mutations: {

    setIsLoading(state, payload) {
      setTimeout(() => {
        state.isLoading = payload;
      }, 500)
    },

    setIsSkeleton(state, payload) {
      state.isSkeleton = payload;
    },

    setIsHome(state, payload) {
      state.isHome = payload;
    },

    timestampToTime(state, timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      state.timeNow = Y + M + D + h + m + s
      // return Y + M + D + h + m + s;
    },

    //时分秒换算
    date_format(state, timestamp) {
      var second = Math.floor(timestamp / 1000);
      var day = Math.floor(second / 3600 / 24);
      var hr = Math.floor((second / 3600) % 24);
      var min = Math.floor((second / 60) % 60);
      state.timeNow = day + "d " + hr + "h " + min + "min";
    },

    //退出登录
    clearSession(state) {
      let guidanceLogin = localStorage.getItem('guidanceLogin')
      localStorage.clear(); //清理本地所有数据
      if (guidanceLogin) {
        localStorage.setItem('guidanceLogin', true);
      }

      //恢复本地语言配置
      localStorage.setItem('lang', state.lang);
    },

    //设置语言
    setLang(state, lang) {
      i18n.locale = lang;
      localStorage.setItem("lang", lang);
      state.lang = lang;
    },
    
    //设置页面参数
    setPageParam(state, pageParam={}) {
      localStorage.setItem("pageParam", JSON.stringify(pageParam));
      state.pageParam = pageParam;
    }
  },

  actions: {

  },
  
  modules: {
  }
})

// 代码中引入 参数 和 方法
// import {mapGetters, mapMutations} from 'vuex'
// computed: {  //computed是不能传参数的
//   ...mapGetters(['参数'])
// }
 
// methods:{
//   ...mapMutations(['方法'])
// }