<template>
  <div class="home-h5">
    <div class="warp-h5">

      <!--0 通过 自动加密货币交易机器人 -->
      <div class="head-h5" v-if="head">
          
        <span class="font30 fontx ch0">{{$t('m.'+head.span1)}}</span>
        <div class="mb10">
          <span class="font30 fontx ch0">{{$t('m.'+head.span2)}}</span>
          <span class="font30 fontx clan">{{$t('m.'+head.span3)}}</span>
        </div>

        <span class="font16 ch3 mb48">{{$t('m.'+head.span4)}}</span>

        <div class="row jcenter">
          <img class="img-robot-h5" :src="head.src1">
        </div>
      </div>
      
      <!--1 通过 Vtrading 机器人最大化您的利润 -->
      <div class="one-h5" v-if="one">

          
          <span class="font24 fontx ch0 mb12">{{$t('m.'+one.span1)}}</span>
          <span class="font14 ch3 mb16">{{$t('m.'+one.span2)}}</span>
        
          <div class="scroll-h5">
            
            <div class="warp-h5">
              <div class="cell-h5" v-for="(item, index) in one.imgs" :key="'one'+index">
                <div class="column">
                  <span class="font20 fontc ch1 mb12">{{$t('m.'+item.title)}}</span>
                  <span class="font14 ch3" 
                  v-for="(label, i) in item.labels " :key="'oneLabel'+i">{{$t('m.'+label)}}</span>
                </div>

                <div class="cell-img-h5">
                  <img class="img-icq-h5" :src="item.url">
                </div>
              </div>
            </div>
          </div>

          <div class="but-hei-h5" @click="tapClick(one.but1.click)">
            <span>{{$t('m.'+one.but1.txt)}}</span>
          </div>
      </div>

      <!--2 在顶级加密货币交易所运行交易机器人-->
      <div class="tow-h5" v-if="two">
        
        <span class="font24 fontx ch0 mb12">{{$t('m.'+two.span1)}}</span>
        <span class="font14 ch3 mb 16">{{$t('m.'+two.span2)}}</span>

        <div class="row jcenter">
          <img class="img-icq-h5" :src="two.src1">
        </div>

        <div class="tow-cont-h5">
          
          <div class="row jbetween mb20">
            <img class="img-logo-h5" :src="two.src4">
            <img class="img-logo-h5" :src="two.src2">
          </div>
          <div class="row jbetween mb20">
            <img class="img-logo-h5" :src="two.src3">
            <img class="img-logo-h5" :src="two.src5">
          </div>
          
          <div class="line-h5"></div>
          <div class="row jbetween mb20">
            <img class="img-logo-h5" :src="two.src6">
            <img class="img-logo-h5" :src="two.src10">
          </div>
          <div class="row jbetween mb20">
            <img class="img-logo-h5" :src="two.src7">
            <img class="img-logo-h5" :src="two.src11">
          </div>
          <div class="row jbetween mb20">
            <img class="img-logo-h5" :src="two.src8">
            <img class="img-logo-h5" :src="two.src12">
          </div>
          <div class="row jbetween">
            <img class="img-logo-h5" :src="two.src13">
            <img class="img-logo-h5" :src="two.src9">
          </div>
        </div>
        
        <div class="but-hei-h5" @click="tapClick(two.but1.click)">
          <span>{{$t('m.'+two.but1.txt)}}</span>
        </div>
      </div>

      <!-- 3 复制顶级交易机器人策略-->
      <div class="three-h5" v-if="three">
        
        <span class="font24 fontx ch0 mb12">{{$t('m.'+three.span1)}}</span>
        <span class="font14 ch3 mb16">{{$t('m.'+three.span2)}}</span>
        
        <div class="three-scroll-h5">
          
          <div class="three-warp-h5">
              <img class="img-size-h5" 
              :class="index < three.imgs.length-1 ? ['mr12'] : []"
              v-for="(item, index) in three.imgs" :key="'copy'+index"
              :src="item" />
          </div>
        </div>

        <div class="but-hei-h5" @click="tapClick(three.but1.click, 'five-h5')">
          <span>{{$t('m.'+three.but1.txt)}}</span>
        </div>
      </div>
      <!-- 3- 策略收益排行榜-->
      <div class="three-h5-" v-if="ranking">
        
        <span class="font24 fontx ch0 mb12">{{$t('m.'+threex.span1)}}</span>
        <span class="font14 ch3 mb16">{{$t('m.'+threex.span2)}}</span>
      
        <div class="line-size-h5">
          
          <div class="row acenter jbetween" style="z-index:22">

            <div class="select-h5">
              <el-select v-model="rankingMenu" @change="selectRanking">
                <el-option
                  v-for="item in rankingMenus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            
            <div class="row acenter">
              <span class="font10 ch3 mr4">{{$t('m.'+threex.span3)}}</span>
              <span class="font14 fontc ch1">{{ranking.openThreshold + '%'}}</span>
            </div>
          </div>
          
          <div class="exa-h5" id="exa"></div>
        </div>

        <div class="data-size-h5">

          <div class="row jbetween">
            <div class="column">
              <span class="font12 ch3 mb8">
                {{$t('m.'+threex.span4) + '(USDT)'}}
              </span>
              <span class="font14 fontc ch1">{{ranking.investCoinnNum}}</span>
            </div>

            <div class="column aend">
              <span class="font12 ch3 mb8">
                {{$t('m.'+threex.span5) + '(USDT)'}}
              </span>
              <span class="font14 fontc ch1"
              :class="ranking.realEarnings>=0 ? ['downGreen'] : ['red']">
                {{ranking.realEarnings+'('+ranking.realEarningsRate+'%)'}}
              </span>
            </div>
          </div>

          <div class="line-h5"></div>

          <div class="row jbetween">
            <div class="column">
              <span class="font12 ch3 mb8">{{$t('m.'+threex.span6)}}</span>
              <span class="font14 fontc ch1 mb20">{{ranking.marginBalance}}</span>

              <span class="font12 ch3 mb8">{{$t('m.'+threex.span7)}}</span>
              <span class="font14 fontc ch1">{{ranking.orderNumber}}</span>
            </div>

            <div class="column">
              <span class="font12 ch3 mb8">
                {{$t('m.'+threex.span8)}}
              </span>
              <span class="font14 fontc ch1 mb20"
              :class="ranking.profitUnreal>=0 ? ['downGreen'] : ['red']">
                {{ranking.profitUnreal}}
              </span>

              <span class="font12 ch3 mb8">
                {{$t('m.'+threex.span9)}}
              </span>
              <span class="font14 fontc ch1">{{ranking.tradeMoney}}</span>
            </div>

            <div class="column aend">
              <span class="font12 ch3 mb8">
                {{$t('m.'+threex.span10)}}
              </span>
              <span class="font14 fontc ch1 mb20">{{ranking.realYearIncome+'%'}}</span>

              <span class="font12 ch3 mb8">
                {{$t('m.'+threex.span11)}}
              </span>
              <span class="font14 fontc ch1">{{ranking.maxDrawdown+'%'}}</span>
            </div>
          </div>
        </div>

        <div class="but-hei-h5" @click="tapClick(threex.but1.click, 'five-h5')">
          <span>{{$t('m.'+threex.but1.txt)}}</span>
        </div>
      </div>

      <!-- 7 Vtrading和大家一起经历了熊市和牛市，从2017年开始-->
      <div class="seven-h5" v-if="seven">
        
        <span class="font22 fontx tcenter cbai mb12">{{$t('m.'+seven.span1)}}</span>
        <span class="fontc tcenter cbai mb32" :class="$t('m.langs')=='Lang' ? ['font16'] : ['font16']">
          {{$t('m.'+seven.span2)}}
        </span>
        
        <div class="size-h5 mb8">
          <img class="img-icq-h5" :src="seven.src1">
          <div class="column">
            <span class="font16 cbai">{{$t('m.'+seven.span3)}}</span>
            <span class="font28 fontc cbai">{{seven.span4}}</span>
          </div>
        </div>

        <div class="size-h5 mb8">
          <img class="img-icq-h5" :src="seven.src2">
          <div class="column">
            <span class="font16 cbai">{{$t('m.'+seven.span5)}}</span>
            <span class="font28 fontc cbai">{{seven.span6}}</span>
          </div>
        </div>

        <div class="size-h5">
          <img class="img-icq-h5" :src="seven.src3">
          <div class="column">
            <span class="font16 cbai">{{$t('m.'+seven.span7)}}</span>
            <span class="font28 fontc cbai">{{seven.span8}}</span>
          </div>
        </div>
      </div>

      <!-- 4 Vtrading是怎么运行的-->
      <div class="four-h5" v-if="four">
        
        <span class="font24 fontx ch0 mb12">{{$t('m.'+four.span1)}}</span>
        <span class="font12 ch3 mb6">{{'· '+$t('m.'+four.span2)}}</span>
        <span class="font12 ch3 mb6">{{'· '+$t('m.'+four.span3)}}</span>
        <span class="font12 ch3 mb6">{{'· '+$t('m.'+four.span4)}}</span>
        <span class="font12 ch3 mb24">{{'· '+$t('m.'+four.span5)}}</span>

        <div class="row jcenter">
          <img class="img-icq-h5" :src="four.src1">
        </div>
        
        <div class="but-hei-h5" @click="tapClick(four.but1.click)">
          <span>{{$t('m.'+four.but1.txt)}}</span>
        </div>
      </div>

      <!-- 8 关注您的利润增长-->
      <div class="eight-h5" v-if="eight">

        <span class="font24 fontx ch0 mb12">{{$t('m.'+eight.span1)}}</span>
        <span class="font14 ch3 mb18">{{$t('m.'+eight.span2)}}</span>
        
        <div class="cont-h5">

          <div class="bg-h5">
            <img class="img-bg-h5" :src="eight.src1">
          </div>

          <div class="size-h5">

            <div class="menus-h5">
              <div class="menu-h5" 
              v-for="(item, index) in eight.menus" 
              :key="'menu'+index" 
              @click="eightMenu=index">

                <span v-if="$t('m.langs')=='Lang'"
                class="ch1 tcenter"
                :class="eightMenu==index ? ['font16', 'fontc'] : ['font14']"
                >{{$t('m.'+item.title)}}</span>

                <span v-else
                class="ch1 tcenter"
                :class="eightMenu==index ? ['font12', 'fontc'] : ['font10']"
                >{{$t('m.'+item.title)}}</span>

                <div class="line-h5" :class="eightMenu==index ? '' : 'line-hidden-h5'"></div>
              </div>
            </div>

            <template v-for="(item, index) in eight.menus">

              <div class="column all" :key="'cout'+index" v-if="eightMenu == index">

                <span class="font14 ch3" 
                v-for="(label, j) in item.labels" 
                :key="'label'+j">{{$t('m.'+label)}}</span>
                
                <div class="size-img-h5">
                  <div class="column img-0-h5" v-if="index==0">
                    <img class="img-size-h5 img-0-1-h5" :src="item.url[0]">
                    <img class="img-size-h5 img-0-2-h5" :src="item.url[1]">
                    <img class="img-size-h5 img-0-3-h5" :src="item.url[2]">
                  </div>
                  <img v-else :class="['img-'+index+'-h5']" :src="item.url" alt="">
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="but-hei-h5" @click="tapClick(eight.but1.click)">
          <span>{{$t('m.'+eight.but1.txt)}}</span>
        </div>
      </div>
      
      <!-- 5 随时随地进行交易-->
      <div class="five-h5" id="five-h5" v-if="five">
        
        <span class="font24 fontx ch0 mb16">{{$t('m.'+five.span1)}}</span>
        
        <div class="row jcenter">
          <div class="box-size-h5">
            
            <qrcode-vue class="mt4 mr16"
              :value="five.url1 + new Date().valueOf()"
              :size="qrcodeSize"
              :margin="0"
              background="#fff"
              foreground="#000"
              level="H"
            />

            <div class="row">
              <div class="column">
                <span class="font16 ch3">{{$t('m.'+five.span2)}}</span>
                <span class="font22 fontx ch0">{{five.span3}}</span>
              </div>

              <img class="img-download-h5"  :src="five.src3">
            </div>
          </div>
        </div>
        
        <div class="row jcenter">
          <img v-if="$t('m.langs')=='Lang'" class="img-iphone-h5" :src="five.src1">
          <img v-else class="img-iphone-h5" :src="five.src2">
        </div>
      </div>

      <!-- 9 FAQ-->
      <div class="nine-h5" v-if="nine">
        
        <div class="row jbetween">
          <img class="img-faq-h5" src="../assets/image/v3/home_9_faq_h5.png">
          <img class="img-icq-h5" src="../assets/image/v3/home_9_icq_h5.png">
        </div>

        <div class="home-line-h5"></div>

        <div class="column">
          <div class="cell-h5"  
          @click="item.show=!item.show" 
          v-for="(item, index) in nine.faqs" :key="index">

            <div class="column all">
              <h3 class="font16 fontc ch1">{{$t('m.'+item.title)}}</h3>
      
              <span v-if="item.show" 
              class="text-h5 font14 ch5">{{$t('m.'+item.label)}}</span>
            </div>
            
            <img class="img-arrow-h5" v-if="item.show" :src="nine.src1" alt="">
            <img class="img-arrow-h5" v-else :src="nine.src2" alt="">
          </div>
        </div>
        
      </div>

      <!-- 6 复制其他交易者的策略-->
      <div class="six-h5" v-if="six">
        <span class="font24 fontx ch0 mb16">{{$t('m.'+six.span1)}}</span>

        <div class="cell-h5">
          <img class="img-icq-h5" :src="six.src1"/>
          <div class="column acenter">
            <span class="font20 fontc ch1">{{$t('m.'+six.span2)}}</span>
            <span class="font14 ch3">{{$t('m.'+six.span3)}}</span>
          </div>
        </div>

        <div class="cell-h5">
          <img class="img-icq-h5" :src="six.src2"/>
          <div class="column acenter">
            <span class="font20 fontc ch1">{{$t('m.'+six.span4)}}</span>
            <span class="font14 ch3">{{$t('m.'+six.span5)}}</span>
          </div>
        </div>

        <div class="cell-h5">
          <img class="img-icq-h5" :src="six.src3"/>
          <div class="column acenter">
            <span class="font20 fontc ch1">{{$t('m.'+six.span6)}}</span>
            <span class="font14 ch3">{{$t('m.'+six.span7)}}</span>
          </div>
        </div>
      </div>

      <!-- 10 公司文化介绍-->
      <!-- <div class="ten-h5">
        
        <span class="font24 fontx ch0 mb12">{{$t('m.公司主题曲')}}</span>
        <span class="font14 ch3 mb16">{{$t('m.我的人生要自己把握，Go with Vtrading to Victory。')}}</span>

          <div class="size-h5">

            <video ref="video"
            class="video-h5"
            :controls="!showPlay"
            :autoplay="false"
            :loop="false"
            :muted="false"
            src="../assets/image/v3/1652546198831407.mp4" />

            <img v-if="showPlay" class="video-h5 video-po-h5" @click="playVideo" 
            src="../assets/image/v3/home_10_show.png">
          </div>
      </div> -->

      <!-- 11 Vtrading数字资产Al量化服务平台介绍-->
      <div class="eleven-h5" v-if="eleven">
        
        <div class="mb12">
          <span class="font24 fontx ch0">{{$t('m.'+eleven.span1)}}</span>
          <span class="font24 fontx clan">{{$t('m.'+eleven.span2)}}</span>
          <span class="font24 fontx ch0">{{$t('m.'+eleven.span3)}}</span>
        </div>
        <span class="font14 ch3 mb18">
          {{$t('m.'+eleven.span4)}}
        </span>

        <div class="cont-h5">

          <div class="bg-h5">
            <img class="img-bg-h5" :src="eleven.src1">
          </div>

          <div class="size-h5">
            
            <span class="font12 ch1 mb10">
              {{$t('m.'+eleven.span5)}}
            </span>
            <span class="font12 ch1">
              {{$t('m.'+eleven.span6)}}
            </span>
    
            <img class="img-icq-h5" :src="eleven.src2">
          </div>
        </div>

        <div class="but-hei-h5" @click="tapClick(eleven.but.click)">
          <span>{{$t('m.'+eleven.but1.txt)}}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {getFitterValue} from "@/utils/function.js"

import TopNavigation from "@/components/TopNavigation";
import QrcodeVue from "qrcode.vue";
import RichBut from "@/components/RichBut";
import * as echarts from "echarts";
export default {
  name: "Home",

  components: {
    TopNavigation,
    QrcodeVue,
    RichBut
  },

  props: {
    head: Object,
    one: Object,
    two: Object,

    three: Object,
    threex: Object,
    rankingMenus: Array,
    rankings: Array,

    seven: Object,
    four: Object,
    eight: Object,
    five: Object,
    nine: Object,
    six: Object,
    eleven: Object,
    bottom: Object
  },
  
  watch: {

    rankings(newValue, oblValue) {
      this.selectRanking();
    }
  },

  data() {
    return {
      qrcodeSize:100, //二维码大小
      eightMenu:0, //模块8菜单标记

      rankingMenu:0, //当前排行榜菜单
      ranking:undefined, //当前排行榜
    };
  },

  created() {

    this.handleResize();
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {

    // 当窗口大小变化时，这个函数会被调用
    handleResize() {
      //适配二维码大小
      this.qrcodeSize = getFitterValue(100);
    },

    //绘制收益走势图
    drawLine() {
      this.$nextTick(()=>{
        var chartDom = document.getElementById('exa');
        var myChart = echarts.init(chartDom);
        myChart.setOption(this.ranking.lineMap);
      });
    },

    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      if(!name) return;
      this.$emit('tapClick', name, param);
    },
    
    //选择排行榜
    selectRanking() {
      this.ranking = this.rankings[this.rankingMenu];
      this.drawLine();
    },
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
#app {
  min-width: 375px;
}
.home-h5 {
  display: flex;
  flex-direction: column;
  .warp-h5 {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 16px; //375-32=343
    .home-line-h5 {
      height: 1px;
      background: #E4E6EF;
      border-radius: 0px;
    }
  }

  /**** 0 通过 自动加密货币交易机器 */
  .head-h5 {
    display: flex;
    flex-direction: column;
    margin: 32px 0;
    .img-robot-h5 {
      width: 295px;
      height: 295px;
      margin-bottom: 24px;
    }
  }

  /**** 1 通过 Vtrading 机器人最大化您的利润*/
  .one-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .scroll-h5 {
      display: flex;
      flex-direction: row;
      overflow-x:scroll;
      background: linear-gradient( 180deg, #F5F8FA 0%, rgba(245,248,250,0) 100%);
      margin-bottom: 32px;
      .warp-h5 {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding: 16px 16px 4px 16px;

        .cell-h5 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 224px;
            padding: 14px 14px 12px 24px;
            border-radius: 6px;
            .cell-img-h5 {
              display: flex;
              flex-direction: row;
              justify-content: end;
              margin-right: -2px;
              margin-bottom: -2px;
              .img-icq-h5 {
                width: 160px;
                height: 160px;
              }
            }
          }
          .cell-h5:hover {
            background: #fff;
          }
        }
    }
  }
  
  /**** 2 在顶级加密货币交易所运行交易机器人*/
  .tow-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    
    .img-icq-h5 {
      width: 240px;
      height: 240px;
      margin-bottom: 18px;
    }
    .tow-cont-h5 {
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      border: 1px solid #E4E6EF;
      padding: 20px;
      margin-bottom: 32px;
      .img-logo-h5 {
        width: 133px;
        height: 24px;
      }
      .line-h5 {
        height: 0px;
        border: 1px solid #F0F2F6;
        margin-bottom: 20px;
      }
    }
  }

  /**** 3 复制顶级交易机器人策略*/
  .three-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .three-scroll-h5 {
      display: flex;
      flex-direction: row;
      overflow-x:scroll;
      margin-bottom: 20px;
      .three-warp-h5 {
        display: flex;
        flex-direction: row;
        height: 100%;

        .img-size-h5 {
          width: 224px;
          height: 296px;
        }
      }
    }
  }
  .three-h5- {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    
    .data-size-h5 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #FFFFFF;
      margin-top: -20px;  
      margin-bottom: 20px;
      .line-h5 {
        height: 1px;
        border: 1px solid #F0F2F6;
        margin: 20px 0;
      }
    }

    .line-size-h5 {
      display: flex;
      flex-direction: column;
      height: 370px;
      background: #FFFFFF;
      .select-h5 {
        width: 100px;
      }
      .exa-h5 {
        width: 100%;
        height: 100%;
        margin-top: -20px;  
      }
    }
  }

  /**** 4 Vtrading是怎么运行的*/
  .four-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    .img-icq-h5 {
      width: 240px;
      height: 240px;
      margin-bottom: 32px;
    }
  }

  /**** 5 随时随地进行交易*/
  .five-h5 {
    display: flex;
    flex-direction: column;
    // margin-bottom: 48px;

    .box-size-h5 {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      width: 311px;
      background: rgba(255,255,255,0.88);
      border-radius: 6px;
      border: 1px solid #E4E6EF;
      padding: 10px 14px;
      margin-bottom: 33px;
      .mt4 {
        margin-top: 4px;
      }
      .img-download-h5 {
        position: absolute;
        right: 10px;
        top: 16px;
        width: 29px;
        height: 40px;
      }
    }
    .img-iphone-h5 {
      width: 374px;
      height: 556px;
      margin-left: -40px;
    }
  }
  
  /**** 6 复制其他交易者的策略*/
  .six-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    .cell-h5 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #F5F8FA;
      border-radius: 6px;
      padding: 24px;
      margin-bottom: 8px;
      .img-icq-h5 {
        width: 200px;
        height: 200px;
        border-radius: 8px;
        margin-bottom: 4px;
      }
    }
  }
  
  /**** 7 Vtrading和大家一起经历了熊市和牛市，从2017年开始*/
  .seven-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    background: #2961D9;
    border-radius: 6px;
    padding: 48px 16px 16px 16px;

    .size-h5 {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 108px;
      border-radius: 6px;
      background: rgba(255,255,255,0.08);
      padding: 0 24px;
      .img-icq-h5 {
        width: 100px;
        height: 100px;
        margin-right: 32px;
      }
    }
  }

  /***** 8 关注您的利润增长*/
  .eight-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    
    .cont-h5 {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      .bg-h5 {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .img-bg-h5 {
          width: 100%;
          height: 100%;
        }
      }

      .size-h5 {
        z-index: 2;
        display: flex;
        flex-direction: column;
        margin: 22px 0;
        padding: 24px 30px;
        
        .menus-h5 {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid #fff;
          margin-bottom: 25px;
          .menu-h5 {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
          }
          .line-h5 {
            width: 36px;
            height: 3px;
            background: #181C32;
            border-radius: 1px;
            margin-top: 8px;
          }
          .line-hidden-h5 {
            opacity: 0;
          }
        }

        .size-img-h5 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          height: 140px;
          margin-top: 24px;

          .img-0-h5 {
            position: relative;
            width: 295px;
            height: 140px;
            .img-size-h5 {
              width:158px;
              height:46px;
              box-shadow:0 5px 10px 0 rgba($color: #000000, $alpha: 0.05);
            }
            .img-0-1-h5 {
              z-index:2;
              position: absolute;
              right: 21px;
            }
            .img-0-2-h5 {
              position: absolute;
              top: 36px;
              left: 0;
            }
            .img-0-3-h5 {
              position: absolute;
              right: 0;
              top: 90px;
            }  
          }
          .img-1-h5 {
            width: 295px;
            height: 130px;
          }
          .img-2-h5 {
            width: 295px;
            height: 140px;
          }
        }
      }
    }
  }

  /**** 9 FAQ*/
  .nine-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    
    .img-faq-h5 {
      width: 63px;
      height: 36px;
      margin-top: 40px;
    }
    .img-icq-h5 {
      width: 165px;
      height: 140px;
    }
    .cell-h5 {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid #E4E6EF;
      .text-h5 {
        margin-top:8px;
      }
      .img-arrow-h5 {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
  }

  /**** 10 公司文化介绍 */
  .ten-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    
    .size-h5 {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 343px;
      height: 193px;
      border-radius: 4px;
      overflow: hidden;

      .video-h5 {
        width: 100%;
        height: 100%;
      }
      .video-po-h5 {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  /**** 11 Vtrading数字资产Al量化服务平台介绍 */
  .eleven-h5 {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    .cont-h5 {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      .bg-h5 {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .img-bg-h5 {
          width: 100%;
          height: 100%;
        }
      }

      .size-h5 {
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 22px 0;
        padding: 30px 30px 20px 30px;

        .img-icq-h5 {
          width: 240px;
          height: 240px;
        }
      }
    }
  }

}

.but-hei-h5 {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 44px;
  background: #0E111E;
  border-radius: 6px;
  border: 2px solid #0E111E;


  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 22px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
</style>
