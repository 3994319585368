import Vue from 'vue'
import router from "../router/index";
import { Message } from 'element-ui'
// import store from '../../store'
// import i18n from '../../i18n/i18n'



const publicMethod = {

    //主题切换0
    colorSwitching(val) {
        var app = document.getElementById("app");
        if (val == "dark") {
            app.setAttribute("data-theme", "dark");
            localStorage.setItem('theme', 'dark')
        } else if (val == "light") {
            app.setAttribute("data-theme", "light");
            localStorage.setItem('theme', 'light')
        }
    },

    //返回上一页
    onClickLeft() {
        router.back(-1);
    },
    // 跳落地页
    toHome() {
        router.push({ path: '/' })
    },
    // 跳登录
    toLogin() {
        router.push({ path: 'login' })
    },
    // 跳注册
    toRegister() {
        router.push({ path: 'signup' })
    },
    // 跳密码重置
    toResetPass() {
        router.push({ path: 'resetPass' })
    },

    // 跳交易首页
    toTransaction() {
        router.push({ path: '/transac' })
    },

    // 跳新手学院
    toNews() {
        router.push({ path: '/newsHome' })
    },

    // 跳帮助中心
    toHelpCenter() {
        router.push({ path: '/helpCenter' })
    },

    // 保留四位小数
    toMathNum(num, v) {
        if (v) {
            num = Math.floor(Number(num) * v * 100) / 100
        }
        return Math.floor(Number(num) * 10000) / 10000
    },
    
    // 识别中英文字节长度
    countCharacters(str) {
        var totalCount = 0;

        for (var i = 0; i < str.length; i++) {
            var c = str.charCodeAt(i);
            if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
                totalCount++;
            } else {
                totalCount += 2;
            }
        }
        return totalCount;


        // 中文 = 2  ，英文字母  =1 
    },
    
    // 获取合约精度保留小数位
    contractSizeNumber(size) {
        let contractSizeIndex = null;
        if (size.toString().indexOf(".") > -1) {
            contractSizeIndex = size.toString().length - 2;
        } else {
            contractSizeIndex = 1;
        }
        return contractSizeIndex;
    },


    msgEvent(num) {
        let value = null
        let msgList = [
            { name: '1000', value: 'Email Doesnt Exist' },
            { name: '1001', value: 'Invalid Temporary Token' },
            { name: '1002', value: 'Invalid  Email Format' },
            { name: '1003', value: 'Verificaiton Code Expired' },
            { name: '1004', value: 'Verification Code Error' },
            { name: '1005', value: 'Token Does Not Exist' },
            { name: '1006', value: 'Token Error' },
            { name: '1007', value: 'User Does Not Exist' },
            { name: '1008', value: 'Exchange Does Not Exist' },
            { name: '1009', value: 'API Does Not Exist' },
            { name: '1010', value: 'RSA Encrption Error ' },
            { name: '1011', value: 'API Synchronization Failed' },
            { name: '1012', value: 'API In Use' },
            { name: '1013', value: "GA Verification Code Can't be Empty" },
            { name: '1014', value: 'GA Verification Code Error' },
            { name: '1015', value: 'Transfer Failed' },
            { name: '1016', value: "Can't delete bot if there are running instances" },
            { name: '1017', value: 'Bot Does not Exist' },
            { name: '1018', value: "Bot is running, can't be stopped, please try again later" },
            { name: '1019', value: "Bot is stopping, can't stop again, please try again later" },
            { name: '1020', value: "Can't pause bot if it is not executing" },
            { name: '1021', value: "Can't restart bot if it is not paused" },
            { name: '1022', value: "Can't restart bot again if there are running instances" },
            { name: '1023', value: 'Only one instance of the same contract can be started' },
            { name: '1024', value: 'IP Address Does Not Exist' },
            { name: '1025', value: 'Server Busy' },
            { name: '1026', value: 'Execution Instance Does Not Exist' },
            { name: '1027', value: "Can't stop the settlement period of the exchange, please try again later" },
            { name: '1028', value: 'Strategy Startup Failed' },
            { name: '1029', value: 'Parameters Error' },
            { name: '1030', value: "Limit price mode can't be empty" },
            { name: '1031', value: "Can't Link to GA Again" },
            { name: '1032', value: 'Does Not Bind to GA' },
            { name: '1033', value: 'Permission Verification Code Error' },
            { name: '1034', value: 'The vtraing account used has been bound to other accounts. Please unbind it first!' },
            { name: '1035', value: 'Email Already Registered' },
            { name: '1036', value: 'Invitation Code Error' },
            { name: '1037', value: 'Please Enter Right Account' },
            { name: '1038', value: 'Account Has Been Frozen' },
            { name: '1039', value: 'Account or Password Error' },
            { name: '1040', value: 'The api is invalid or wrong ,try again' },
            { name: '6074', value: 'insufficient balance' },
            //请勿重复提交
            { name: '-889', value: ' Resubmit' }

        ]
        msgList.forEach(e => {
            if (e.name == num) {
                value = e.value
            }
        })
        return value
    },



    
    // 保留小数
    toMinNum(num, v=2) {
        var value = 1;
        for (var i = 0; i < v; i++) {
            value = value * 10;
        }
        return Math.floor(Number(num) * value) / value
    },
    
    //获取时间
    getTime(timestamp, style) {

		timestamp = timestamp ? Number(timestamp) : new Date().getTime();
		var time = new Date(timestamp);
		var y = time.getFullYear();
		
		var M = time.getMonth() + 1;
		M = M < 10 ? ('0' + M) : M;
		
		var d = time.getDate();
		d = d < 10 ? ('0' + d) : d;
		
		var H = time.getHours();
		H = H < 10 ? ('0' + H) : H;
		
		var m = time.getMinutes();
		m = m < 10 ? ('0' + m) : m;
		
		var s = time.getSeconds();
		s = s < 10 ? ('0' + s) : s;

		style = (style && style.length) ? style : 'y/M/d H:m';
		style = style.replace('y', y);
		style = style.replace('M', M);
		style = style.replace('d', d);
		style = style.replace('H', H);
		style = style.replace('m', m);
		style = style.replace('s', s);
		return style;
	},

    //获取时间-英文
    getTimeEn(timestamp) {

        var y = this.getTime(timestamp, 'y');
        var m = this.getTime(timestamp, 'M');
        var d = this.getTime(timestamp, 'd');
        const ds = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return ds[Number(m)] + ' ' + d + ', ' + y;;
    },
    
    //获取本地缓存
    getItem(key) {
        var str = localStorage.getItem(key)
        try {
            return JSON.parse(str);
        } catch (error) {
            return str;
        }
    },

    //设置本地缓存
    setItem(key, item) {
        try {
            var value = JSON.stringify(item);
            localStorage.setItem(key, value);
        } catch (error) {
            localStorage.setItem(key, item);
        }
    },
    
      
}

export default publicMethod