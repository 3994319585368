<template>
  <div class="bottompc">

    <div class="column jbetween ">
      
      <div class="column" :class="bottom.but1.click ? 'but' : ''" 
      @click="tapClick(bottom.but1.click)">
        <img  class="logopc mb6" :src="bottom.but1.src">
        <span class="font14 ch7">{{bottom.but1.txt}}</span>
      </div>

      <div class="row acenter mb34">

        <img v-for="(but, index) in bottom.buts" :key="'but'+index"
        class="img-icqpc" :src="but.src" 
        @click="tapClick(but.click, but)"/>
      </div>
    </div>

    <div class="linepc"></div>

    <div class="row">

      <div class="column mr100">
        <span class="font14 fontc ch7 mb20" @click="tapClick(bottom.but4.click)"
        :class="bottom.but4.click ? 'but' : ''">{{$t('m.'+bottom.but4.txt)}}</span>

        <span class="font14 fontc cbai mb20" @click="tapClick(bottom.but5.click)"
        :class="bottom.but5.click ? 'but' : ''">{{$t('m.'+bottom.but5.txt)}}</span>

        <span class="font14 fontc cbai mb20" @click="tapClick(bottom.but6.click)"
        :class="bottom.but6.click ? 'but' : ''">{{$t('m.'+bottom.but6.txt)}}</span>

        <span class="font14 fontc cbai mb20" @click="tapClick(bottom.but7.click)"
        :class="bottom.but7.click ? 'but' : ''">{{$t('m.'+bottom.but7.txt)}}</span>

        <span class="font14 fontc cbai mb20" @click="tapClick(bottom.but8.click)"
        :class="bottom.but8.click ? 'but' : ''">{{$t('m.'+bottom.but8.txt)}}</span>
        
        <span class="font14 fontc cbai" @click="tapClick(bottom.but9.click)"
        :class="bottom.but9.click ? 'but' : ''">{{$t('m.'+bottom.but9.txt)}}</span>
      </div>


      <div class="column mr100">
        <span class="font14 fontc ch7 mb20" @click="tapClick(bottom.but10.click)"
        :class="bottom.but10.click ? 'but' : ''" >{{$t('m.'+bottom.but10.txt)}}</span>

        <span class="font14 fontc cbai mb20" @click="tapClick(bottom.but11.click)"
        :class="bottom.but11.click ? 'but' : ''">{{$t('m.'+bottom.but11.txt)}}</span>

        <span class="font14 fontc cbai" @click="tapClick(bottom.but12.click)"
        :class="bottom.but12.click ? 'but' : ''">{{$t('m.'+bottom.but12.txt)}}</span>
      </div>

      <div class="column">
        <span class="font14 fontc ch7 mb20">{{$t('m.'+bottom.but13.txt)}}</span>

        <span @click="tapClick(bottom.but14.click, bottom.but14)" 
        class="font14 fontc cbai mb20" style="cursor: pointer;">{{bottom.but14.txt}}</span>

        <span @click="tapClick(bottom.but15.click, bottom.but15)" 
        class="font14 fontc cbai mb20" style="cursor: pointer;">{{bottom.but15.txt}}</span>

        <span @click="tapClick(bottom.but16.click, bottom.but16)" 
        class="font14 fontc cbai" style="cursor: pointer;">{{bottom.but16.txt}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  components: {
  },


  props: {
    bottom: Object
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {

    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      if(!name) return;
      this.$emit('tapClick', name, param);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.bottompc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1200px;
  padding: 60px 0;
  .logopc {
    width: 164px;
    height: 40px;
  }
  .img-icqpc {
    cursor: pointer;
    width: 36px;
    height: 36px;
    margin-right: 8px;
    background-size: cover;
  }
  .linepc {
    width: 1px;
    height: 232px;
    background: linear-gradient( 180deg, rgba(126,130,153,0) 0%, #7E8299 50%, rgba(126,130,153,0) 100%);
    border-radius: 6px 6px 6px 6px;
  }
  .mr100 {
    margin-right: 100px;
  }
}
.but:hover {
  color: #7E8299 !important;
}
</style>
