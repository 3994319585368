// rem等比适配配置文件
// 基准大小
const baseSize = 16;

// 设置 rem 函数
function setRem() { 

  var bol = /mobile/i.test(navigator.userAgent); //手机、平板
  var clientWidth = document.documentElement.clientWidth; //当前窗口宽度

  var width = 1920;
  var hash = document.location.hash; //当前路径
	//下面判断需要同步修改function.js文件内容
  if (hash == '' || hash == '/login'|| 
      hash == '/signup'|| hash == '/resetPass' ||
			hash == '/newsHome' || hash == '/newsInfo' || hash == '/newsSearch' ||
      hash == '/grid' || hash == '/gridFutures' || hash == '/download' || hash == '/dcaGridFutures') {
    width = (bol || clientWidth < 600) ? 375 : 1920;
  }

  // 当前页面屏幕分辨率相对于 1920宽的缩放比例，可根据自己需要修改
  var scale =  clientWidth / width;
  scale = scale > 1 ? 1 : scale;
  document.documentElement.style.scaling = scale; 

  // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + "px";
}

// 初始化
setRem();

// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
}