<template>
  <div class="home">
    <div class="warp">
      
      <!--0 通过 自动加密货币交易机器人 -->
      <div class="content head" v-if="head">
          
        <div class="column">
          <span class="font64 fontx ch0">{{$t('m.'+head.span1)}}</span>
          <div class="mb20 mt-10">
            <span class="font64 fontx ch0">{{$t('m.'+head.span2)}}</span>
            <span class="font64 fontx clan">{{$t('m.'+head.span3)}}</span>
          </div>
          

          <span class="font26 ch1 mb60">{{$t('m.'+head.span4)}}</span>
          <div class="row">
            <RichBut class="butpc-max" styleType="blan" @click="tapClick(head.but1.click)">
              <span class="font16 fontx">{{$t('m.'+head.but1.txt)}}</span>
            </RichBut>
          </div>
        </div>

        <img class="img-robot" :src="head.src1">
      </div>

      <!--1 通过 Vtrading 机器人最大化您的利润 -->
      <div class="content one" v-if="one">

        <div class="row acenter jbetween mb60">
          <div class="column">
            <span class="font48 fontx ch0 mb8">{{$t('m.'+one.span1)}}</span>
            <span class="font18 ch3">{{$t('m.'+one.span2)}}</span>
          </div>
          
          <RichBut class="butpc" styleType="bhei" @click="tapClick(one.but1.click)">
            <span class="font16 fontx">{{$t('m.'+one.but1.txt)}}</span>
          </RichBut>
        </div>

        <div class="size">
          <div class="cell" v-for="(item, index) in one.imgs" :key="'one'+index">

            <div class="column" v-if="$t('m.langs')=='Lang'">
              <span class="font24 fontc ch1 mb4">{{$t('m.'+item.title)}}</span>
              <span class="font14 ch3" 
              v-for="(label, i) in item.labels " :key="'oneLabel'+i">{{$t('m.'+label)}}</span>
            </div>
            <div class="column" v-else>
              <span class="font20 fontc ch1 mb4">{{$t('m.'+item.title)}}</span>
              <span class="font12 ch3" 
              v-for="(label, i) in item.labels " :key="'oneLabel'+i">{{$t('m.'+label)}}</span>
            </div>

            <div class="cell-img">
              <img class="img-icq" :src="item.url">
            </div>
          </div>
        </div>
      </div>

      <!--2 在顶级加密货币交易所运行交易机器人-->
      <div class="content two" v-if="two">
        
        <span class="font48 fontx ch0 mb8">{{$t('m.'+two.span1)}}</span>
        <span class="font18 ch3 mb60">{{$t('m.'+two.span2)}}</span>

        <div class="cont">

          <div class="column acenter jbetween">
            <img class="img-icq" :src="two.src1">


            <RichBut class="butpc" styleType="bhei" @click="tapClick(two.but1.click)">
              <span class="font16 fontx">{{$t('m.'+two.but1.txt)}}</span>
            </RichBut>
          </div>

          <div class="line"></div>

          <div class="column jbetween">
            <img class="img-logo" :src="two.src2">
            <img class="img-logo" :src="two.src3">
            <img class="img-logo" :src="two.src4">
            <img class="img-logo" :src="two.src5">
          </div>

          <div class="line"></div>

          <div class="column jbetween">
            <img class="img-logo" :src="two.src6">
            <img class="img-logo" :src="two.src7">
            <img class="img-logo" :src="two.src8">
            <img class="img-logo" :src="two.src9">
          </div>

          <div class="column jbetween mrf_50">
            <img class="img-logo" :src="two.src10">
            <img class="img-logo" :src="two.src11">
            <img class="img-logo" :src="two.src12">
            <img class="img-logo" :src="two.src13">
          </div>
          
        </div>
      </div>

      <!-- 3 复制顶级交易机器人策略-->
      <div class="content three" v-if="three">
        
        <div class="row jcenter jbetween mb60">

          <div class="column all mr20">
            <span class="font48 fontx ch0 mb8">{{$t('m.'+three.span1)}}</span>
            <span class="font18 ch3">{{$t('m.'+three.span2)}}</span>
          </div>

          <div class="row">
            <RichBut class="butpc" styleType="bhei" @click="tapClick(three.but1.click, 'five')">
              <span class="font16 fontx">{{$t('m.'+three.but1.txt)}}</span>
            </RichBut>
          </div>
        </div>
        
        <div class="cont">

          <div class="but-arrow" @click="threeScroll(threeNum-1)"></div>

          <div class="sizes" ref="scrollCopy">

            <img class="img-size mr20" 
            :class="index < three.imgs.length-1 ? ['mr20'] : []"
            v-for="(item, index) in three.imgs" :key="'copy'+index"
            ref="imgCopy" :src="item" />
          </div>

          <div class="but-arrow" @click="threeScroll(threeNum+1)"></div>
        </div>
      </div>
      <!-- 3- 策略收益排行榜-->
      <div class="content three-" v-if="ranking">
        
        <div class="row jcenter jbetween mb60">
          <div class="column">
            <span class="font48 fontx ch0 mb8">{{$t('m.'+threex.span1)}}</span>
            <span class="font18 ch3">{{$t('m.'+threex.span2)}}</span>
          </div>

          <div class="row">
            
            <RichBut class="butpc" styleType="bhei" @click="tapClick(threex.but1.click, 'five')">
              <span class="font16 fontx">{{$t('m.'+threex.but1.txt)}}</span>
            </RichBut>
          </div>
        </div> 
        
        <div class="cont-">

          <div class="line-size">
            
            <div class="row acenter jbetween">

              <div class="select">
                <el-select v-model="rankingMenu" @change="selectRanking">
                  <el-option
                    v-for="item in rankingMenus"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              
              <div class="row acenter">
                <span class="font14 ch3 mr4">{{$t('m.'+threex.span3)}}</span>
                <span class="font18 fontc ch1">{{ranking.openThreshold + '%'}}</span>
              </div>
            </div>

            <div class="exa-size">
              <div class="exa" id="exa">
              </div>
              <div class="exa-but"></div>
            </div>
          </div>

          <div class="data-size">

            <div class="row jbetween">
              <div class="column">
                <span class="font14 ch3 mb8">
                  {{$t('m.'+threex.span4) + '(USDT)'}}
                </span>
                <span class="font18 fontc ch1">{{ranking.investCoinnNum}}</span>
              </div>

              <div class="column aend">
                <span class="font14 ch3 mb8">
                  {{$t('m.'+threex.span5) + '(USDT)'}}
                </span>
                <span class="font18 fontc ch1"
                :class="ranking.realEarnings>=0 ? ['downGreen'] : ['red']">
                  {{ranking.realEarnings+'('+ranking.realEarningsRate+'%)'}}
                </span>
              </div>
            </div>

            <div class="line"></div>

             <div class="row jbetween">
              <div class="column">
                <span class="font14 ch3 mb8">{{$t('m.'+threex.span6)}}</span>
                <span class="font18 fontc ch1 mb20">{{ranking.marginBalance}}</span>

                <span class="font14 ch3 mb8">{{$t('m.'+threex.span7)}}</span>
                <span class="font18 fontc ch1">{{ranking.orderNumber}}</span>
              </div>

              <div class="column">
                <span class="font14 ch3 mb8">
                  {{$t('m.'+threex.span8)}}
                </span>
                <span class="font18 fontc ch1 mb20"
                :class="ranking.profitUnreal>=0 ? ['downGreen'] : ['red']">
                  {{ranking.profitUnreal}}
                </span>

                <span class="font14 ch3 mb8">
                  {{$t('m.'+threex.span9)}}
                </span>
                <span class="font18 fontc ch1">{{ranking.tradeMoney}}</span>
              </div>

              <div class="column aend">
                <span class="font14 ch3 mb8">
                  {{$t('m.'+threex.span10)}}
                </span>
                <span class="font18 fontc ch1 mb20">{{ranking.realYearIncome+'%'}}</span>

                <span class="font14 ch3 mb8">
                  {{$t('m.'+threex.span11)}}
                </span>
                <span class="font18 fontc ch1">{{ranking.maxDrawdown+'%'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 7 Vtrading和大家一起经历了熊市和牛市，从2017年开始-->
      <div class="content seven" v-if="seven">
        
        <div class="column acenter">
          <img class="img-bg" src="../assets/image/v3/home_7_bg.png">
          <span class="font48 fontx cbai">{{$t('m.'+seven.span1)}}</span>
          <span class="fontc cbai mb60" :class="$t('m.langs')=='Lang' ? ['font44'] : ['font32']">
            {{$t('m.'+seven.span2)}}
          </span>
        </div>

        <div class="row acenter jbetween">

          <div class="row acenter">
            <img class="img-icq" :src="seven.src1">"
            <div class="column">
              <span class="font20 cbai">{{$t('m.'+seven.span3)}}</span>
              <span class="font44 fontc cbai">{{seven.span4}}</span>
            </div>
          </div>

          <div class="row acenter">
            <img class="img-icq" :src="seven.src2">
            <div class="column">
              <span class="font20 cbai">{{$t('m.'+seven.span5)}}</span>
              <span class="font44 fontc cbai ">{{seven.span6}}</span>
            </div>
          </div>

          <div class="row acenter">
            <img class="img-icq" :src="seven.src3">
            <div class="column">
              <span class="font20 cbai">{{$t('m.'+seven.span7)}}</span>
              <span class="font44 font cbai">{{seven.span8}}</span>
            </div>
          </div>
        </div>
      </div>
      
      <!-- 4 Vtrading是怎么运行的-->
      <div class="content four" v-if="four">
        
        <div class="cont">
          <div class="column">
            <span class="font48 fontx ch0 mb22">{{$t('m.'+four.span1)}}</span>
            <span class="font20 ch3 mb14">{{'· '+$t('m.'+four.span2)}}</span>
            <span class="font20 ch3 mb14">{{'· '+$t('m.'+four.span3)}}</span>
            <span class="font20 ch3 mb14">{{'· '+$t('m.'+four.span4)}}</span>
            <span class="font20 ch3">{{'· '+$t('m.'+four.span5)}}</span>
          </div>

          <div class="row mb48">
            <RichBut class="butpc" styleType="bhei" @click="tapClick(four.but1.click)">
              <span class="font16 fontx">{{$t('m.'+four.but1.txt)}}</span>
            </RichBut>
          </div>
        </div>
        
        <img class="img-icq" :src="four.src1">
      </div>

      <!-- 8 关注您的利润增长-->
      <div class="line-fenge"></div>
      <div class="content eight" v-if="eight">

        <span class="font48 fontx ch0 mb8">{{$t('m.'+eight.span1)}}</span>
        <span class="font18 ch3 mb60">
          {{$t('m.'+eight.span2)}}
        </span>
        
        <div class="cont">
          <img class="img-bg" :src="eight.src1">

          <div class="size">

            <div class="menus">
              <div class="menu" 
              v-for="(item, index) in eight.menus" 
              :key="'menu'+index" 
              @click="eightMenu=index">
                <span class="ch1 tright"
                :class="eightMenu==index ? ['font24', 'fontc'] : ['font20']"
                >{{$t('m.'+item.title)}}</span>

                <div class="line" :class="eightMenu==index ? '' : 'line-hidden'"></div>
              </div>
            </div>

            <template v-for="(item, index) in eight.menus">

              <div class="column all" :key="'cout'+index" v-if="eightMenu == index">

                <div class="row jbetween mb40 mt17">
                  
                  <div class="column">
                    <span class="max-label font18 ch5" 
                    v-for="(label, j) in item.labels" 
                    :key="'label'+j">{{$t('m.'+label)}}</span>
                  </div>
                  
                  <RichBut class="butpc" styleType="bhei" @click="tapClick(eight.but1.click)">
                    <span class="font16 fontx">{{$t('m.'+eight.but1.txt)}}</span>
                  </RichBut>
                </div>

                <div class="size-img">
                  <div class="column img-0" v-if="index==0">
                    <img class="img-size img-0-1" :src="item.url[0]">
                    <img class="img-size img-0-2" :src="item.url[1]">
                    <img class="img-size img-0-3" :src="item.url[2]">
                  </div>
                  <img v-else :class="['img-'+index]" :src="item.url" alt="">
                  
                  <RichBut class="butpc" styleType="bhei" style="opacity: 0;">
                    <span class="font16 fontx">{{$t('m.'+eight.but1.txt)}}</span>
                  </RichBut>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      
      <!-- 5 随时随地进行交易-->
      <div class="content five" id="five" v-if="five">
        
        <img v-if="$t('m.langs')=='Lang'" class="img-iphone" :src="five.src1">
        <img v-else class="img-iphone" :src="five.src2">

        <div class="column">
        
          <span class="font48 fontx ch0 mb48 maxLabel">{{$t('m.'+five.span1)}}</span>

          <div class="row">

            <div class="box-size">

              <div class="row acenter">

                <qrcode-vue class="mr16"
                  :value="five.url1 + new Date().valueOf()"
                  :size="qrcodeSize"
                  :margin="2"
                  background="#fff"
                  foreground="#000"
                  level="H"
                />

                <div class="column">
                  <span class="font24 ch5 mb4">{{$t('m.'+five.span2)}}</span>
                  <span class="font32 fontx ch1">iOS & Android</span>
                </div>
              </div>

              <img class="img-download"  :src="five.src3">
            </div>
          </div>
        </div>
      </div>

      <!-- 9 FAQ-->
      <div class="content nine" v-if="nine">
        
        <img class="img-faq" src="../assets/image/v3/home_9_faq.png">

        <div class="column">
          <div class="cell"  
          @click="item.show=!item.show" 
          v-for="(item, index) in nine.faqs" :key="index">
            <div class="row jbetween">
              <h3 class="font24 fontc ch1">{{$t('m.'+item.title)}}</h3>
              <img class="img-arrow" v-if="item.show" :src="nine.src1">
              <img class="img-arrow" v-else :src="nine.src2">
            </div>
            <span v-if="item.show" 
            class="text font18 ch5">{{$t('m.'+item.label)}}</span>
          </div>
        </div>
      </div>

      <!-- 6 复制其他交易者的策略-->
      <div class="content six" v-if="six">

        <div class="column">

          <span class="font48 fontx ch0 tcenter mb60">{{$t('m.'+six.span1)}}</span>

          <div class="row jbetween">

            <div class="cell">
              <img class="img-icq" :src="six.src1"/>
              <div class="column acenter">
                <span class="font24 fontc ch1">{{$t('m.'+six.span2)}}</span>
                <span class="font18 ch5">{{$t('m.'+six.span3)}}</span>
              </div>
            </div>

            <div class="cell">
              <img class="img-icq" :src="six.src2"/>
              <div class="column acenter">
                <span class="font24 fontc ch1">{{$t('m.'+six.span4)}}</span>
                <span class="font18 ch5">{{$t('m.'+six.span5)}}</span>
              </div>
            </div>

            <div class="cell">
              <img class="img-icq" :src="six.src3"/>
              <div class="column acenter">
                <span class="font24 fontc ch1">{{$t('m.'+six.span6)}}</span>
                <span class="font18 ch5">{{$t('m.'+six.span7)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 10 公司文化介绍-->
      <!-- <div class="content ten">
        
        <span class="font48 fontx ch0 mb8">{{$t('m.公司主题曲')}}</span>
        <span class="font18 ch3 mb60">{{$t('m.我的人生要自己把握，Go with Vtrading to Victory。')}}</span>

          <div class="size">

            <video ref="video"
            class="video"
            :controls="true"
            :autoplay="false"
            :loop="false"
            :muted="false"
            src="../assets/image/v3/1652546198831407.mp4" />

            <img v-if="showPlay" class="video video-po" @click="playVideo" 
            src="../assets/image/v3/home_10_show.png">
          </div>
      </div> -->

      <!-- 11 Vtrading数字资产Al量化服务平台介绍-->
      <div class="content eleven" v-if="eleven">
        
        <div class="row acenter jbetween mb60">
          <div class="column">
            <div>
              <span class="font48 fontx ch0 mb8">{{$t('m.'+eleven.span1)}}</span>
              <span class="font48 fontx clan mb8">{{$t('m.'+eleven.span2)}}</span>
              <span class="font48 fontx ch0 mb8">{{$t('m.'+eleven.span3)}}</span>
            </div>
            <span class="font18 ch3">{{$t('m.'+eleven.span4)}}</span>
          </div>

          <div class="row">
            <RichBut class="butpc" styleType="bhei" @click="tapClick(eleven.but1.click)">
              <span class="font16 fontx">{{$t('m.'+eleven.but1.txt)}}</span>
            </RichBut>
          </div>
        </div>
        
        <div class="cont">
          <img class="img-bg" :src="eleven.src1">

          <div class="size">

            <div class="texts">
              <span class="ch1 mb40" :class="$t('m.langs')=='Lang' ? ['font20'] : ['font16']">
                {{$t('m.'+eleven.span5)}}
              </span>
              <span class="ch1" :class="$t('m.langs')=='Lang' ? ['font20'] : ['font16']">
                {{$t('m.'+eleven.span6)}}
              </span>
            </div>

            <img class="img-icq" :src="eleven.src2">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFitterValue} from "@/utils/function.js"

import TopNavigation from "@/components/TopNavigation";
import QrcodeVue from "qrcode.vue";
import RichBut from "@/components/RichBut";
import * as echarts from "echarts";
export default {
  name: "Home",

  components: {
    TopNavigation,
    QrcodeVue,
    RichBut
  },

  props: {
    head: Object,
    one: Object,
    two: Object,

    three: Object, 
    threex: Object,
    rankingMenus: Array,
    rankings: Array,

    seven: Object,
    four: Object,
    eight: Object,
    five: Object,
    nine: Object,
    six: Object,
    eleven: Object,
  },
  
  watch: {

    rankings(newValue, oblValue) {
      this.selectRanking();
    }
  },

  data() {
    return {
      qrcodeSize:100, //二维码大小
      threeNum:0, //模块3左右切换标记
      eightMenu:0, //模块8菜单标记

      rankingMenu:0, //当前排行榜菜单
      ranking:undefined, //当前排行榜
    }
  },

  created() {
    this.handleResize();
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {

    // 当窗口大小变化时，这个函数会被调用
    handleResize() {

      //适配二维码大小
      this.qrcodeSize = getFitterValue(100);
    },

    //模块三，滚动到指定内容（复制顶级交易机器人策略）
    threeScroll(row) {
      
      if (row > this.three.imgs.length-5) return;
      if (row <= 0) return;

      this.threeNum = row;

      var left = 240 * this.threeNum * document.documentElement.style.scaling;
      const scrollContainer = this.$refs.scrollCopy;
      if (scrollContainer) {
        scrollContainer.scrollTo({'left': left, 'behavior': 'smooth'});
      }
    },

    //绘制收益走势图
    drawLine() {
      this.$nextTick(()=>{
        var chartDom = document.getElementById('exa');
        var myChart = echarts.init(chartDom);
        myChart.setOption(this.ranking.lineMap);
      });
    },

    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      if(!name) return;
      this.$emit('tapClick', name, param);
    },
    
    //选择排行榜
    selectRanking() {
      this.ranking = this.rankings[this.rankingMenu];
      this.drawLine();
    },
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
.home {
  display: flex;
  flex-direction: column;

  .warp {
    display: flex;
    flex-direction: column;
    align-items: center;
    .line-fenge {
      width: 1200px;
      height: 1px;
      background: #E4E6EF;
      margin-bottom: 120px;
    }
  }
  
  /************************************* 内容 */
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin-bottom: 120px;
  }

  /**** 0 通过 自动加密货币交易机器 */
  .head {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 80px;
    .img-robot {
      width: 440px;
      height: 440px;
    }
    .mt-10 {
      margin-top: -10px;
    }
  }
  
  /**** 1 通过 Vtrading 机器人最大化您的利润*/
  .one {
    
    .size {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 14px 14px 4px 14px;

      // background: #2670FA;
      border-radius: 10px;
      // overflow: hidden;
      width: 1200px;
      height: 348px;
      background-image: url('../assets/image/v3/home_1_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */
      
      .cell {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 224px;
        // height: 328px;
        padding: 24px;
        transition: background-color 0.3s; /* 平滑过渡效果 */
        border-radius: 6px;
        .cell-img {
          display: flex;
          flex-direction: row;
          justify-content: end;
          margin-right: -2px;
          margin-bottom: -2px;
          .img-icq {
            width: 160px;
            height: 160px;
          }
        }
      }
      .cell:hover {
        background: #fff;
      }
    }
  }
  
  /**** 2 在顶级加密货币交易所运行交易机器人*/
  .two {
    
    .cont {
      height: 432px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 60px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #E4E6EF;
      .img-icq {
        width: 240px;
        height: 240px;
      }
      .img-logo {
        width: 200px;
        height: 36px;
      }
      .line {
        width: 0px;
        border: 1px solid #F0F2F6;
      }
    }
  }

  /**** 3 复制顶级交易机器人策略*/
  .three {

    .cont {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 1320px;
      margin-left: -60px;
      .but-arrow {
        opacity: 0;
        cursor: pointer;
        width: 48px;
        height: 48px;
        background: #F0F2F6;
      }
      .sizes {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 1200px;
        height: 320px;
        // overflow-x:scroll;
        .img-size {
          width: 224px;
          height: 296px;
          border-radius: 8px;
        }
        .img-size:hover {
          width: 242px;
          height: 320px;
          
          box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.1);
          margin-right: 9px;
          margin-left: -9px;
        }
        .img-size-right {
          margin-right: 20px;
        }
      }
    }
  }
  .three- {

    .cont- {
      display: flex;
      flex-direction: row;
      padding: 16px;
      background-image: url('../assets/image/v3/home_1_bg.png'); /* 背景图片路径 */
      background-size: cover; /* 覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      background-repeat: no-repeat; /* 不重复背景图片 */

      .line-size {
        display: flex;
        flex-direction: column;
        width: 740px;
        height: 370px;
        background: #FFFFFF;
        border-radius: 6px;
        padding: 18px;
        margin-right: 8px;
        .select {
          display: flex;
          flex-direction: row;
          align-content: center;
        }
        .exa-size {
          flex: 1;
          position: relative;
          display: flex;
          flex-direction: column;
          .exa {
            width: 100%;
            height: 100%;
          }
          .exa-but {
            z-index: 3;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      
      .data-size {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 420px;
        height: 370px;
        background: #FFFFFF;
        border-radius: 6px;
        padding: 50px 30px;
        .line {
          height: 1px;
          border: 1px solid #F0F2F6;
        }
      }
    }
  }

  /**** 4 Vtrading是怎么运行的*/
  .four {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 80px;
    .cont {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .img-icq {
      width: 440px;
      height: 440px;
      border-radius: 18px 18px 18px 18px;
    }
  }
  
  /**** 5 随时随地进行交易*/
  .five {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .img-iphone {
      width: 550px;
      height: 818px;
    }


    .maxLabel {
      max-width: 460px;
    }

    .box-size {
      display: flex;
      flex-direction: row;
      padding: 16px;
      margin-right: 0;

      background: rgba(255,255,255,0.88);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #E4E6EF;


      .qrcode {
        display: flex;
        align-items: center;
        justify-content: center;
        background:#fff;
        height: 100px;
        width: 110px;
        padding-top: 4px;
        border-radius: 10px;
        margin-right: 30px;
      }

      .img-download {
        width: 58px;
        height: 80px;
        margin-top: -20px;
      }
    }
  }
  
  /**** 6 复制其他交易者的策略*/
  .six {
    .cell {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 392px;
      height: 340px;
      padding: 24px;
      border-radius: 8px 8px 8px 8px;
      background-color: #fff;
      transition: background-color 0.3s; /* 平滑过渡效果 */
      .img-icq {
        width: 200px;
        height: 200px;
        border-radius: 8px 8px 8px 8px;
      }
    }
    .cell:hover {
      background: #F5F8FA;
    }
  }

  /**** 7 从2017年开始,Vtrading和大家一起经历了熊市和牛市*/
  .seven {
    
    padding: 60px;
    background: #2961D9;
    border-radius: 8px 8px 8px 8px;
    .img-bg {
      position: absolute;
      width: 200px;
      height: 100px;
    }
    .img-icq {
      width: 160px;
      height: 160px;
      margin-right: 16px;
    }
  }
  
  /***** 8 关注您的利润增长*/
  .eight {
    
    .cont {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 1200px;
      height: 592px;

      .img-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 1200px;
        height: 592px;
      }


      .size {
        z-index: 99;
        display: flex;
        flex-direction: row;
        width: 1200px;
        height: 512px;
        padding: 60px;
        
        .menus {
          display: flex;
          flex-direction: column;
          border-right: 1px solid #fff;
          margin-right: 60px;
          .menu {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            width: 170px;
            height: 70px;

            padding: 17px 0 17px 17px;
            cursor: pointer;
          }
          .line {
            width: 3px;
            height: 36px;
            background: #181C32;
            border-radius: 1px;
            margin-left: 26px;
            margin-right: -2px;
          }
          .line-hidden {
            opacity: 0;
          }
        }


        .mt17 {
          margin-top: 17px;
        }
        .max-label {
          max-width: 460px;
        }

        .size-img {
          display: flex;
          flex-direction: row;
          justify-content: right;

          .img-0 {
            position: relative;
            width: 487px;
            height: 270px;
            .img-size {
              width:292px;
              height:84px;
              box-shadow:0 5px 10px 0 rgba($color: #000000, $alpha: 0.05);
            }
            .img-0-1 {
              z-index:2;
              position: absolute;
              right: 60px;
            }
            .img-0-2 {
              position: absolute;
              top: 74px;
              right: 180px;
            }
            .img-0-3 {
              position: absolute;
              right: 0;
              top: 168px;
            }  
          }
          .img-1 {
            width: 495px;
            height: 219px;
          }
          .img-2 {
            width: 461px;
            height: 218px;
          }
          .butpc {
            margin-left: 120px;
          }
        }
      }
    }
  }

  /**** 9 FAQ*/
  .nine {
    flex-direction: row;
    justify-content: space-between;
    
    .img-faq {
      width: 292px;
      height: 358px;
    }
    .cell {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      width: 720px;
      padding: 25px 0;
      border-bottom: 1px solid #D8D8D8;
      .text {
        margin-top:12px;
      }
      .img-arrow {
        width: 20px;
        height: 20px;
      }
    }
  }

  /**** 10 公司文化介绍 */
  .ten {
    
    .size {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .video {
        width: 1080px;
        height: 608px;
        border-radius: 8px 8px 8px 8px;
      }
      .video-po {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 60px;
      }
    }
  }

  /**** 11 Vtrading数字资产Al量化服务平台介绍 */
  .eleven {
    
    
    .cont {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 1200px;
      height: 592px;

      .img-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 1200px;
        height: 592px;
      }


      .size {
        z-index: 99;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        height: 512px;
        padding: 90px;
        
        .texts {
          display: flex;
          flex-direction: column;
          width: 520px;
        }

        .img-icq {
          width: 440px;
          height: 440px;
        }
      }
    }
  }
  
  /************************************* 全局*/
  .butpc {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    min-width: 130px;
    height: 52px;
    border-radius: 6px;
  }
  .butpc-max {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    min-width: 163px;
    height: 60px;
    border-radius: 6px;
  }
  .mrf_50 {margin-right: -50px}
  .mr100 {margin-right: 120px;}
}
</style>
