<template>

  <div id="app" :data-theme="theme" v-loading.fullscreen.lock="this.$refs.news"> <!---->
    <router-view />
  </div>

</template>

<script>

export default {
  components: {
  },

  data() {
    return {

      theme: localStorage.getItem("theme") ? localStorage.getItem("theme") : "light",
    };
  },

  methods: {
  },

  mounted() {
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/_themeify.scss";
@import "@/assets/appCss.scss";
.light {
  background: rgba(249, 250, 252, 0.3);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  min-width: 1920px;
  font-size: 14px;
  overflow: hidden; 
}
* {
  box-sizing: border-box;
}
html,
body {
  -webkit-text-size-adjust: none;
}
.el-loading-mask {
  background: rgba(0,0,0,0) !important;
}
</style>
