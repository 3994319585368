import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import store from '../store/index'
// store.commit('activeRouter')

Vue.use(VueRouter)


// 获取原型对象push函数
const originalPush = VueRouter.prototype.push
 
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace
 
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
  return originalPush.call(this , location).catch(err=>err)
}
 
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

const routes = [
  
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '*',
    name: 'Home',
    redirect: '/',
  },

  //登录***************************************************
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login-reg-mod/login.vue'),
  },
  //注册
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "about" */ '../views/login-reg-mod/signup.vue')
  },
  //重置密码
  {
    path: '/resetPass',
    name: 'ResetPass',
    component: () => import(/* webpackChunkName: "about" */ '../views/login-reg-mod/resetPass.vue')
  },

  //量化学院***************************************************
  {
    path: '/newsHome',
    name: 'newsHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/newsHome.vue')
  },
  //文章详情
  {
    path: '/newsInfo/:id',
    name: 'newsInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/newsInfo.vue')
  },
  //文章搜索
  {
    path: '/newsSearch',
    name: 'newsSearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/newsSearch.vue')
  },



  /************************* BOT */
  {
    path: '/grid',
    name: 'grid',
    component: () => import(/* webpackChunkName: "about" */ '../views/bot/grid.vue')
  },
  {
    path: '/gridFutures',
    name: 'gridFutures',
    component: () => import(/* webpackChunkName: "about" */ '../views/bot/fuGrid.vue')
  },
  {
    path: '/dcaGridFutures',
    name: 'dcaGridFutures',
    component: () => import(/* webpackChunkName: "about" */ '../views/bot/dcaFuGrid.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "about" */ '../views/bot/download.vue')
  },

  

  //其他***************************************************

  {
    path: '/download',
    name: 'Download',
    component: () => import(/* webpackChunkName: "about" */ '../views/Download.vue')
  },
  {
    path: '/CreateStrategy',
    name: 'CreateStrategy',
    component: () => import(/* webpackChunkName: "about" */ '../views/create-strategy/CreateStrategy.vue')
  },
  {
    path: '/exampleList',
    name: 'exampleList',
    component: () => import(/* webpackChunkName: "about" */ '../views/transaction/modular/exampleList.vue')
  },
  {
    path: '/historyExample',
    name: 'historyExample',
    component: () => import(/* webpackChunkName: "about" */ '../views/transaction/modular/historyExample.vue')
  },
  {
    path: '/GAVerification',
    name: 'GAVerification',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/GAVerification.vue')
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/personal.vue')
  },
  {
    path: '/inviteFriend',
    name: 'inviteFriend',
    component: () => import(/* webpackChunkName: "about" */ '../views/transaction/modular/inviteFriend.vue')
  },


  

  // 交易首页
  {
    path: '/transac',
    name: 'transac',
    component: () => import(/* webpackChunkName: "about" */ '../views/transac/index.vue')
  },
  // 我的交易所首页
  {
    path: '/myExchange',
    name: 'myExchange',
    component: () => import(/* webpackChunkName: "about" */ '../views/myExchange/index.vue')
  },


  // 聚合账户详情
  {
    path: '/myExchange/accDetails',
    name: 'accDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/transaction/modular/childModular/accDetails.vue')
  },
  // API详情
  {
    path: '/myExchange/exchangeDetail',
    name: 'exchangeDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/transaction/modular/childModular/exchangeDetail.vue')
  },

  // 机器人列表所首页
  {
    path: '/robotList',
    name: 'robotList',
    component: () => import(/* webpackChunkName: "about" */ '../views/robotList/index.vue')
  },

  // 机器人详情
  {
    path: '/robotList/strategyDatails',
    name: 'strategyDatails',
    component: () => import(/* webpackChunkName: "about" */ '../views/transaction/modular/strategyDatails.vue')
  },

  // 创建机器人
  {
    path: '/robotList/createStrategy',
    name: 'createStrategy',
    component: () => import(/* webpackChunkName: "about" */ '../views/create-strategy/CreateStrategy.vue')
  },

  // 活跃实例
  {
    path: '/robotList/exampleList',
    name: 'strategyDatails',
    component: () => import(/* webpackChunkName: "about" */ '../views/transaction/modular/exampleList.vue')
  },
  // 活跃实例
  {
    path: '/robotList/historyExample',
    name: 'strategyDatails',
    component: () => import(/* webpackChunkName: "about" */ '../views/transaction/modular/historyExample.vue')
  },
  // 网格创建
  {
    path: '/contractStrategy',
    name: 'contractStrategy',
    component: () => import(/* webpackChunkName: "about" */ '../views/create-strategy/ContractStrategy.vue')
  },

  // 帮助中心

  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/helpCenter')
  },

  // 搜索
  {
    path: '/helpCenter/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/helpCenter/search')
  },
  // 文章编辑
  {
    path: '/articleEditor',
    name: 'articleEditor',
    component: () => import(/* webpackChunkName: "about" */ '../views/articleEditor')
  },

  // 网格委托
  {
    path: '/gridDelegation',
    name: 'gridDelegation',
    component: () => import(/* webpackChunkName: "about" */ '../views/create-strategy/childModular/gridDelegation.vue')
  },
  // 网格详情 
  {
    path: '/contractStrategy/contractDetails',
    name: 'contractDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/create-strategy/childModular/contractDetails')
  },
  // 早鸟计划
  {
    path: '/earlyBird',
    name: 'earlyBird',
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/earlyBird.vue')
  },
  // {
  //   path: '/information',
  //   name: 'information',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/information/index.vue')
  // },
]

const router = new VueRouter({
  // mode: 'hash', 
  mode: 'history', 
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    store.commit('setIsHome', false)
  } else {
    store.commit('setIsHome', true)
  }
  next();
});


export default router
