/** 提供各种通用功能
 * 	
 **/

import router from "@/router/index";
/** 获取显示组件
 * 
 * @param {String} pc  PC页面
 * @param {String} h5  H5页面
 * @param {String} name 当前显示页面
 * @returns 显示组件
 */
export function getShowPage(pc, h5) {

	//手机直接返回H5页面
	var bol = /mobile/i.test(navigator.userAgent);
	if (bol) {
		return h5;
	}

	var clientWidth =  document.documentElement.clientWidth;
	// console.log(name+clientWidth)
  var hash = document.location.hash; //当前路径
	//下面判断需要同步修改rem.js文件内容
  if (hash == '' || hash == '/login'|| 
		hash == '/signup'|| hash == '/resetPass' ||
		hash == '/newsHome' || hash == '/newsInfo' || hash == '/newsSearch' ||
		hash == '/grid' || hash == '/gridFutures' || hash == '/download' || hash == '/dcaGridFutures') {
		return (clientWidth < 600 ? h5 : pc);
	} else {
		return pc;
	}
}

/** 适配值(PC拉伸、缩小浏览器)
 * 
 * @param {String || Number} value  原生值
 * @returns  适配后的值
 */
export function getFitterValue(value) {
	return value * document.documentElement.style.scaling;
}

/**	判断登录状态，登录返回 false，未登录返回 true
 * 
 * @param {object} self 当前对象
 * @param {boolean} to true跳转登录
 */
export function isLogin(self) {

	let token = localStorage.getItem("token");
	if (token) {
		return false;
	}

	if (self) {
		self.$message.warning(self.$t("m.请登录后再试"));
		self.Public.toLogin();
	}

	return true;
}
