const cn = {
  m: {
    "langs":'Lang',


    "新手必读":"新手必读",
    "区块链资讯":"区块链资讯",
    "策略视频":"策略视频",
    "暂未开放":"暂未开放",

    "个人中心":"个人中心",
    "交易策略":"交易策略",
    "合约":"合约",
    "量化学院":"量化学院",
    "定价":"定价",

    "登录": '登录',
    "注册": '注册',
    "账户": '账户',
    "请输入邮箱": '请输入邮箱',
    "请输入账户": '请输入账户',
    "密码": "密码",
    "请输入密码": '请输入密码',
    "还没有账户": '还没有账户',
    "忘记密码": '忘记密码',
    "其他登录方式": '其他登录方式',

    "邮箱注册": '邮箱注册',
    "账号": '账号',
    "请输入密码,字母加数字组合6-32位": '请输入密码,字母加数字组合6-32位',
    "请输入邀请码": '请输入邀请码',
    "已有账号，立即登录": '已有账号，立即登录',

    "重置密码": '重置密码',
    "返回登录": '返回登录',
    "邮箱": "邮箱",
    "请输入邮箱地址": '请输入邮箱地址',
    "下一步": '下一步',
    "安全验证": '安全验证',
    "上一步": '上一步',
    "请输入邮箱验证码": '请输入邮箱验证码',
    "已发送": '已发送',
    "获取验证码": '获取验证码',
    "请输入谷歌验证码": '请输入谷歌验证码',
    "提交": '提交',
    "新密码": '新密码',
    "重复新密码": '重复新密码',
    "请再次输入密码": '请再次输入密码',
    "密码必须包含字母和数字，长度6-32位": '密码必须包含字母和数字，长度6-32位',
    "两次输入密码不一致":"两次输入密码不一致",
    "邮箱格式不正确": '邮箱格式不正确',
    "验证成功": '验证成功',
    "修改成功": '修改成功',
    
    "选填": '选填',
    "邀请码": '邀请码',

    /***** 0 */
    "无需编码的":"无需编码的",
    "自动加密货币交易":"自动加密货币交易",
    "机器人":"机器人",
    "像专业人士一样，释放您的交易潜力。":"像专业人士一样，释放您的交易潜力。",
    "立即交易":"立即交易",

    /***** 1 */
    "通过 Vtrading 机器人最大化您的利润":"通过 Vtrading 机器人最大化您的利润",
    "将您的交易帐户与API密钥连接起来并立即创建您的加密机器人":"将您的交易帐户与API密钥连接起来并立即创建您的加密机器人",

    "交易机器人":"交易机器人",
    "适用于每种市场趋势看涨、持平或看跌。":"适用于每种市场趋势看涨、持平或看跌。",
    
    "终端":"终端",
    "批量执行手动订单。":"批量执行手动订单。",
    
    "移动应用程序":"移动应用程序",
    "下载应用程序以随时随地管理您的交易机器人。":"下载应用程序以随时随地管理您的交易机器人。",
    "不错过任何市场趋势。":"不错过任何市场趋势。",
    
    "交易信号":"交易信号",
    "从布林线、价格警报、交易视图信号等中进行选择，以便交易机器人在正确的时间启动和停止。":"从布林线、价格警报、交易视图信号等中进行选择，以便交易机器人在正确的时间启动和停止。",
    
    "跟单交易":"跟单交易",
    "帮助您提高机器人的利润并学习其他交易者的策略。":"帮助您提高机器人的利润并学习其他交易者的策略。",

    "试试看":"试试看",
    

    /***** 2 */
    "在顶级加密货币交易所运行交易机器人":"在顶级加密货币交易所运行交易机器人",
    "将您的交易帐户与 API 密钥连接起来并立即创建您的加密机器人。":"将您的交易帐户与 API 密钥连接起来并立即创建您的加密机器人。",
    "接入账户":"接入账户",

    /***** 3 */
    "复制顶级交易机器人策略":"复制顶级交易机器人策略",
    "发现并复制交易市场上最好的交易机器人以增加您的利润":"发现并复制交易市场上最好的交易机器人以增加您的利润",
    "下载APP":"下載APP",
    
    /***** 3- */
    "策略收益排行榜":"策略收益排行榜",
    "与优秀的策略交易员同行":"与优秀的策略交易员同行",
    "总投入":"总投入",
    "总收益率":"总收益率",
    "账户权益":"账户权益",
    "持仓盈亏":"持仓盈亏",
    "总年化":"总年化",
    "交易笔数":"交易笔数",
    "交易额":"交易额",
    "最大回撤":"最大回撤",
    "我要跟单":"我要跟单",

    /***** 4 */
    "Vtrading是怎么运行的？":"Vtrading是怎么运行的？",
    "将您的交易所与 API 密钥连接起来并创建机器人进行交易。":"将您的交易所与 API 密钥连接起来并创建机器人进行交易。",
    "您的资金绝对安全，API经过多重算法加密，安全性高！":"您的资金绝对安全，API经过多重算法加密，安全性高！",
    "您可以通过API连接多个交易所，管理不同的资金账户。":"您可以通过API连接多个交易所，管理不同的资金账户。",
    "更轻松地检查您的帐户或执行订单。":"更轻松地检查您的帐户或执行订单。",
    "连接账户":"连接账户",

    /***** 5 */
    "随时随地进行交易":"随时随地进行交易",
    "扫描下载":"扫描下载",
    
    /***** 6 */
    "复制其他交易者的策略": '复制其他交易者的策略',

    "订阅其他使用者的策略信号": '订阅其他使用者的策略信号',

    "无须配置参数": '无须配置参数',
    "小白也能快速启动策略": '小白也能快速启动策略',
    
    "自己建立策略信号发起跟随": '自己建立策略信号发起跟随',
    "其他使用者可通过付费跟随您的策略": '其他使用者可通过付费跟随您的策略',

    /***** 7 */
    "从2017年开始":"从2017年开始",
    "Vtrading和大家一起经历了熊市和牛市":"Vtrading和大家一起经历了熊市和牛市",
    "Vtrading和大家一起经历了熊市和牛市，从2017年开始":"Vtrading和大家一起经历了熊市和牛市，从2017年开始",
    "全球用户":"全球用户",
    "机器人数量":"机器人数量",
    "日交易量":"日交易量",

    /***** 8 */
    "关注您的利润增长":"关注您的利润增长",
    "从强大的交易机器人到智能订单工具，vtrading 机器人可以帮助您在加密世界中实现利润最大化":"从强大的交易机器人到智能订单工具，vtrading 机器人可以帮助您在加密世界中实现利润最大化",
    "交易机器人":"交易机器人",
    "借助全自动交易机器人，24/7 从每个市场变动中获利。":"借助全自动交易机器人，24/7 从每个市场变动中获利。",
    "在几分钟内设置 GRID 和 DCA 策略。":"在几分钟内设置 GRID 和 DCA 策略。",
    
    "智能订单":"智能订单",
    "通过强大的订单功能执行您的交易策略。":"通过强大的订单功能执行您的交易策略。",
    "通过不同的功能控制您的风险或增加您的利润，如止损、止盈、追踪止盈、单轨等。":"通过不同的功能控制您的风险或增加您的利润，如止损、止盈、追踪止盈、单轨等。",

    "信号终端":"信号终端",
    "信号终端可以更智能地运行交易机器人。":"信号终端可以更智能地运行交易机器人。",
    "无论是复制的策略还是自己创建的外部信号通过 TradingView ，机器人可以自动执行。":"无论是复制的策略还是自己创建的外部信号通过 TradingView ，机器人可以自动执行。",

    /***** 9 */
    "设置机器人需要多长时间？":"设置机器人需要多长时间？",
    "只需几分钟即可开始交易，只需通过API密钥连接到您的交易所，选择所需的交易货币并设置参数即可。":"只需几分钟即可开始交易，只需通过API密钥连接到您的交易所，选择所需的交易货币并设置参数即可。",
    "启动交易机器人需要多少钱？":"启动交易机器人需要多少钱？",
    "没有最低交易限制，但为了使策略良好运行，建议单个机器人投资超过 200 美元。":"没有最低交易限制，但为了使策略良好运行，建议单个机器人投资超过 200 美元。",
    "Vtrading可以在哪些类型的市场进行交易？":"Vtrading可以在哪些类型的市场进行交易？",
    "Vtrading 有现货和合约策略，以及网格机器人、DCA 机器人和其他类型的策略。 无论是牛市、熊市还是波动市场，您都可以选择不同的策略来执行交易。":"Vtrading 有现货和合约策略，以及网格机器人、DCA 机器人和其他类型的策略。 无论是牛市、熊市还是波动市场，您都可以选择不同的策略来执行交易。",
    "使用 vtrading 机器人进行交易安全吗？":"使用 vtrading 机器人进行交易安全吗？",
    "Vtrading不存储您的资金，您的账户只能通过API密钥访问进行交易，并且API经过多重算法加密，绝对安全。 您可以完全控制您的资金。":"Vtrading不存储您的资金，您的账户只能通过API密钥访问进行交易，并且API经过多重算法加密，绝对安全。 您可以完全控制您的资金。",
    "我可以随时取款吗？":"我可以随时取款吗？",
    "Vtrading不存储您的资金，因此您可以随时终止您的策略并转移您的资金。":"Vtrading不存储您的资金，因此您可以随时终止您的策略并转移您的资金。",
    
    /******** 10 */
    "公司主题曲":"公司主题曲",
    "我的人生要自己把握，Go with Vtrading to Victory。":"我的人生要自己把握，Go with Vtrading to Victory。",

    /******** 11 */
    "Vtrading数字资产":"Vtrading数字资产",
    "Al量化":"Al量化",
    "服务平台介绍":"服务平台介绍",
    "Vtrading数字资产Al量化服务平台介绍":"Vtrading数字资产Al量化服务平台介绍",
    "Vtrading成⽴于2017年12⽉，旨在为不同层次的客户提供差异化的量化交易策略服务。":"Vtrading成⽴于2017年12⽉，旨在为不同层次的客户提供差异化的量化交易策略服务。",
    "Vtrading数字资产AI量化服务平台提供可视化图元平台编辑策略、Hbase系统快速调试回测、自定义发布商城策略、资管系统、智能跟单系统、独⽴返佣系统、⼤客户私有定制部署、第三⽅嵌⼊式系统等功能及服务。":"Vtrading数字资产AI量化服务平台提供可视化图元平台编辑策略、Hbase系统快速调试回测、自定义发布商城策略、资管系统、智能跟单系统、独⽴返佣系统、⼤客户私有定制部署、第三⽅嵌⼊式系统等功能及服务。",
    "用户可通过Vtrading提供的多种服务来实现不同的量化需求，让普通用户无需懂得编写代码也能运行量化策略，让量化交易变得更简单。":"用户可通过Vtrading提供的多种服务来实现不同的量化需求，让普通用户无需懂得编写代码也能运行量化策略，让量化交易变得更简单。",


    /******** 底部 */
    "平台策略":"平台策略",
    "DCA策略":"DCA策略",
    "网格策略":"网格策略",
    "DCA合约策略":"DCA合约策略",
    "网格合约策略":"网格合约策略",
    "信号策略":"信号策略",
    "资源":"资源",
    "新手学院":"新手学院",
    "新闻资讯":"新闻资讯",
    "联系我们":"联系我们",
    


    /************************ 网格策略 */
    "什么是网格交易？":"什么是网格交易？",
    "网格交易是一种量化交易策略，即在震荡行情中通过一定的价格区间构造出一系列的买卖价位, 不断的执行低买高卖, 从而获得波段收益的一种交易方法。":"",
    "具体来说，网格交易是以一个价格为基础，在该价格的上下划分多个区间或者网格，并在每条网格上预先设定相应的买入和卖出订单。":"",
    
    "网格交易适用于什么行情？":"",
    "网格交易更合适在持续震荡的行情下交易获利。":"",
    "但如果行情上涨，您可以通过调整网格策略的参数从而将利润最大化":"",

    "Vtrading 网格机器人的优势":"",
    "Vtrading平台的网格机器人兼顾各种行情，无论是上涨、下跌还是震荡都可以获利。":"",
    "下跌行情使用币本位机器人套利赚币，上涨行情增加资金投入，震荡行情又可以调整网格区间":"",
    "灵活":"",
    "网格参数随用随改，及时生效":"",
    
    "持续稳定":"",
    "网格区间灵活调整，从容面对上涨或下跌行情":"",
    
    "风险可控":"",
    "通过参数来调整限制订单数量，从而应对突发行情，对于资金仓位的管控有非常好的帮助":"",


    "观看网格讲解视频":"",
    "播放":"",

    "如何启动网格交易策略？":"",
    "通过API链接您的交易所":"",
    "选择网格策略":"",
    "选择模式开启策略":"",

    

    "开始交易": '开始交易',
    "查看详情": '查看详情',
    "牛市行情如何盈利？": '牛市行情如何盈利？',
    "美元平均成本": '美元平均成本',
    "使用DCA策略，可以逐步降低买入成本，等待行情反弹从而获利。": '使用DCA策略，可以逐步降低买入成本，等待行情反弹从而获利。',
    "网格策略": '网格策略',
    "通过分批买入、分批卖出的形式不断在波动中套利": '通过分批买入、分批卖出的形式不断在波动中套利',
    "信号触发器": '信号触发器',
    "通过TradingView等外部警报信号，设置触发器，从而提前对熊市的早期迹象做出策略调整自动批量触发信号，执行止盈、止损、暂停、清仓等操作": '通过TradingView等外部警报信号，设置触发器，从而提前对熊市的早期迹象做出策略调整自动批量触发信号，执行止盈、止损、暂停、清仓等操作',
    "创建机器人": '创建机器人',
    "使用Vtrading交易机器人，让获利更简单": '使用Vtrading交易机器人，让获利更简单',
    "无论是上涨或者下跌行情，都可以通过Vtrading机器人获得利润，我们需要做的就是设定目标和调整策略，让机器人更智能化运行以达成目标。": '无论是上涨或者下跌行情，都可以通过Vtrading机器人获得利润，我们需要做的就是设定目标和调整策略，让机器人更智能化运行以达成目标。',
    "7*24小时自动执行交易": '7*24小时自动执行交易',
    "机器人会按照设定的参数执行交易，在震荡市场下产生的收益远远超出预期": '机器人会按照设定的参数执行交易，在震荡市场下产生的收益远远超出预期',
    "多重策略可供选择，无障碍穿越牛熊": '多重策略可供选择，无障碍穿越牛熊',
    "丰富的策略类型可为不同的市场行情提供多样的选择，无论是网格策略、趋势策略、还是合约策略，您都可用通过Vtrading建立策略模板执行自动化交易": '丰富的策略类型可为不同的市场行情提供多样的选择，无论是网格策略、趋势策略、还是合约策略，您都可用通过Vtrading建立策略模板执行自动化交易',
    "学会掌控市场": '学会掌控市场',
    "使用合理的策略，设置稳定的参数来建立您的加密货币投资组合，以达到交易利润最大化，通过策略及其参数控制风险，最小化您的交易损失": '使用合理的策略，设置稳定的参数来建立您的加密货币投资组合，以达到交易利润最大化，通过策略及其参数控制风险，最小化您的交易损失',
    "如何启动量化机器人": '如何启动量化机器人',
    "通过API密钥连接交易所并创建机器人执行下单交易": '通过API密钥连接交易所并创建机器人执行下单交易',
    "您的资金仍然在交易所，相比于将资金直接转入量化平台，使用API链接将更加安全可靠。并且通过API可以一键管理您的多个平台账户资产，查看账户持仓或执行头寸交易更加便捷。": '您的资金仍然在交易所，相比于将资金直接转入量化平台，使用API链接将更加安全可靠。并且通过API可以一键管理您的多个平台账户资产，查看账户持仓或执行头寸交易更加便捷。',
    "以下交易所支持使用Vtrading自动交易工具": '以下交易所支持使用Vtrading自动交易工具',
    
    "币安交易所":'币安交易所',
    "OKX交易所":'OKX交易所',
    "HTX交易所":'HTX交易所',


    "连接交易所": '连接交易所',
    "敬请期待": '敬请期待',
    "APP扫码下载": "APP扫码下载",



    // ***********
    "语言": '语言',
    "简体中文": '简体中文',
    "英文": 'English',
    "聚合账户": '聚合账户',
    "总资产折合": '总资产折合',
    "今日收益": '今日收益',
    "近7天累计收益": '近七天累计收益',
    "我的机器人": '我的机器人',
    "总收益": '总收益',
    "查看更多": '查看更多',
    "资产折合": '资产折合',
    "交易所": '交易所',
    "请选择交易所": '请选择交易所',
    "请输入": '请输入',
    "连接": '连接',
    "去": '去',
    "聚合账户详情": '聚合账户详情',
    "账户详情": '账户详情',
    "统计数据": '统计数据',
    "资产走势": '资产走势',
    "日期范围": '日期范围',
    "累计收益": '累计收益',
    "请选择日期范围": '请选择日期范围',
    "每日收益": '每日收益',
    "币币账户": '币币账户',
    "合约账户": '合约账户',
    "搜索": '搜索',
    "币种": '币种',
    "估值": '估值',
    "价格变化": '价格变化（24h）',
    "数量": '数量',
    "合计": '合计',
    "名称": '名称',
    "全部": '全部',
    "启用": '启用',
    "关闭": '关闭',
    "做多": '做多',
    "做空": '做空',
    "双向": '双向',
    "创建DCA机器人": '创建DCA机器人',
    "筛选": '筛选',
    "清除筛选": '清除筛选',
    "机器人名称": '机器人名称',
    "执行中": '执行中',
    "已终止": '已终止',
    "参数": '参数',
    "启动": '启动',
    "重启": '重启',
    "复制": '复制',
    "详情": '详情',
    "终止": '终止',
    "币对": '币对',
    "担保资产率": '担保资产率',
    "预估强平价": '预估强平价',
    "U本位合约账户": 'U本位合约账户',
    "交易方向": '交易方向',
    "方向": '方向',
    "保证金模式": '保证金模式',
    "全仓": '全仓',
    "逐仓": '逐仓',
    "杠杆": '杠杆',
    "持仓止盈": '持仓止盈',
    "持仓量": '持仓量',
    "止盈平仓模式": '止盈平仓模式',
    "一次性止盈": '一次性止盈',
    "逐单止盈": '逐单止盈',
    "止盈下单模式": '止盈下单模式',
    "追踪止盈": '追踪止盈',
    "预埋单止盈": '预埋单止盈',
    "止盈阈值": '止盈阈值',
    "止盈回撤比例": '止盈回撤比例',
    "持仓止损": '持仓止损',
    "止损阈值": '止损阈值',
    "止损后的操作": '止损后的操作',
    "终止机器人": '终止机器人',
    "继续交易": '继续交易',
    "高级设置": '高级设置',
    "最低价": '最低价',
    "最高价": '最高价',
    "开仓等待时长": '开仓等待时长',
    "总收益止盈": '总收益止盈',
    "总收益止损": '总收益止损',
    "终止时自动撤销委托": '终止时自动撤销委托',
    "终止时自动平仓": '终止时自动平仓',
    "账户资产": '账户资产',
    "可用权益": '可用权益',
    "划转": '划转',
    "建议投入": '建议投入',
    "当前最小须投入": 'MinFunds',
    "账户已有策略，暂不可建": '的账户中已有一个正在运行的全仓机器人，暂不可创建新的实例',
    "机器人": '机器人',
    "执行时长": '执行时长',
    "交易": '交易',
    "邀请好友": '邀请好友',
    "账户类型": '账户类型',
    "帮助": '帮助',
    "首页": '首页',
    "开仓均价": '开仓均价',
    "我的交易所": '我的交易所',
    "DCA机器人": 'DCA机器人',
    "机器人列表": '机器人列表',
    "活跃实例": '活跃实例',
    "历史": '历史',
    "未创建机器人": '你还没有创建机器人，点击下方按钮创建吧...',
    "你还没有连接到交易所": '你还没有连接到交易所',
    "连接交易所开始交易": '快去连接交易所，开始你的交易吧...',
    "开始日期": '开始日期',
    "结束日期": '结束日期',
    "同步资产": '同步资产',
    "请输入机器人名称": '请输入机器人名称',
    "请输入交易对": '请输入交易对',
    "暂停": '暂停',
    "未启用": '未启用',
    "启动中": '启动中',
    "启动失败": '启动失败',
    "终止中": '终止中',
    "首单投入": '首单投入',
    "补仓间隔比例": '补仓间隔比例',
    "补仓反弹比例": '补仓反弹比例',
    "最大做多单数": '最大做多单数',
    "最大做空单数": "最大做空单数",
    "天": '天',
    "时": '时',
    "分": '分',
    "实例": '实例',
    "交易对": '交易对',
    "盈亏": '盈亏',
    "状态": '状态',
    "未实现": '未实现',
    "止盈终止": "止盈终止",
    "自动终止": '自动终止',
    "止损终止": "止损终止",
    "定时终止": "定时终止",
    "手动终止": "手动终止",
    "强制终止": "强制终止",
    "成交记录": '成交记录',
    "暂无实例": '暂无实例',
    "成交时间": '成交时间',
    "开多": "开多",
    "开空": "开空",
    "平多": "平多",
    "平空": "平空",
    "委托数量": "委托数量",
    "委托价格": "委托价格",
    "收益": "收益",
    "计价单位": '计价单位',
    "切换主题": '切换主题',
    "退出登录": '退出登录',
    "删除": '删除',
    "持仓数量": '持仓数量',
    "开仓均价": '开仓均价',
    "强平价": '强平价',
    "补仓追踪": '补仓追踪',
    "当前委托": '当前委托',
    "当前做单数": "当前做单数",
    "加仓": '加仓',
    "市价平仓": '市价平仓',
    "邀请好友": '邀请好友',
    "邀请链接": "邀请链接",
    "访问": '访问',
    "邀请": "邀请",
    "邀请记录": "邀请记录",
    "邀请时间": "邀请时间",
    "账户设置": "账户设置",
    "昵称": '昵称',
    "头像": '头像',
    "绑定": '绑定',
    "已绑定": '已绑定',
    "安全设置": '安全设置',
    "修改": "修改",
    "谷歌验证器": "谷歌验证器",
    "未绑定": '未绑定',
    "已绑定": "已绑定",
    "换绑": "换绑",
    "登录验证": '登录验证',
    "账号绑定": "账号绑定",
    "登录设备": '登录设备',
    "当前设备": '当前设备',
    "请输入名称": '请输入名称',
    "请输入API key": '请输入API Key',
    "请输入API Secret": '请输入API Secret',
    "我已阅读并同意服务协议": '我已阅读并同意【用户服务协议】',
    "请输入币种": '请输入币种',
    "当前做单": '当前做单',
    "加载中": '加载中',
    "操作": '操作',
    "成交数量": '成交数量',
    "成交价格": '成交价格',
    "时间": '时间',
    "请输入划转数量": '请输入划转数量',
    "撤销": '撤销',
    "请选择": '请选择',
    "到": '到',
    "从": '从',
    "可划转": '可划转',
    "确定": '确定',
    "取消": '取消',
    "复制密钥": '复制密钥',
    "密钥": '密钥',
    "现货账户": '现货账户',
    "合约账户-U本位合约": '合约账户-U本位合约',
    "委托方向": '委托方向',
    "委托类型": '委托类型',
    "市价委托": '市价委托',
    "解除绑定": '解除绑定',
    "交易账户": '交易账户',
    "资金账户": '资金账户',
    "暂无数据": '暂无数据',
    "切换验证方式": '切换验证方式',
    "谷歌验证": '谷歌验证',
    "邮箱验证": '邮箱验证',
    "扫码登录": '扫码登录',
    "刷新二维码": '刷新二维码',
    "打开 VtradingAPP 扫一扫登录": '打开 VtradingAPP 扫一扫登录',
    "二维码有效期": '二维码有效期',
    "现在开始": '现在开始',
    "撤销中": '撤销中',
    "撤销成功": '撤销成功',
    "持仓": '持仓',
    "验证": '验证',
    "持仓收益": '持仓收益',
    "来源": '来源',
    "手动操作": '手动操作',
    "机器人": '机器人',
    "机器人表现": '机器人表现',
    "限价委托": '限价委托',
    "确定要删除当前机器人": '确定要删除当前机器人',
    "请输入价格": '请输入价格',
    "为了您的账号安全，请进行安全验证": '为了您的账号安全，请进行安全验证',
    "可用": '可用',
    "金额": '金额',
    "副本": '副本',
    "手动加仓": '手动加仓',
    "是否解除绑定Vtrading账户": '是否解除绑定Vtrading账户',
    "解绑": '解绑',
    "请勾选用户服务协议 ": '请勾选用户服务协议 ',
    "请登录后再试": '请登录后再试',
    "验证": '验证',
    "设备名": '设备名',
    "最近登录时间": '最近登录时间',
    "请输入正确的数量": '请输入正确的数量',
    "金额不能大于可用金额": '金额不能大于可用金额',
    "金额不能小于": '金额不能小于',
    "发送成功": '发送成功',
    "登录成功": '登录成功',
    "重复新邮箱": '重复新邮箱',
    "请输入新邮箱": '请输入新邮箱',
    "请输入新密码": '请输入新密码',
    "更新成功": '更新成功',
    "再次输入新密码": '再次输入新密码',
    "请再次输入新密码": '请再次输入新密码',
    "两次密码输入不一致": '两次密码输入不一致',
    "请再次输入新邮箱": '请再次输入新邮箱',
    "两次邮箱输入不一致": '两次邮箱输入不一致',
    "删除成功": '删除成功',
    "启动成功": '启动成功',
    "重启成功": '重启成功',
    "终止成功": '终止成功',
    "暂停成功": '暂停成功',
    "不能为空": '不能为空',
    "倍": '倍',
    "单": '单',
    "绑定成功": '绑定成功',
    "请输入百分比": '请输入百分比',
    "机器人触发价": '机器人触发价',
    "每日利润": '每日利润',
    "请输入数量": '请输入数量',
    "开启交易新世界": '开启交易新世界',
    "启动机器人": '启动机器人',
    "确定要启动当前机器人": '确定要启动当前机器人',
    "机器人创建成功": '机器人创建成功',
    "是否开启机器人": '是否开启机器人',
    "最大初始保证金": '最大初始保证金',
    "IP地址": 'IP地址',
    "OPEN UP A NEW WORLD OF TRADING": 'OPEN UP A NEW WORLD OF TRADING',
    "智 能 交 易 / 就 选 Vtrading": '智 能 交 易 / 就 选 Vtrading',
    "保存": '保存',
    "编辑交易所": '编辑交易所',
    "编辑": '编辑',
    "执行实例": '执行实例',
    "完成的实例数": '完成的实例数',
    "活跃的实例数": '活跃的实例数',
    "当前初始保证金占用": '当前初始保证金占用',
    "活跃": '活跃',
    "确定要终止当前机器人": '确定要终止当前机器人',
    "确定要删除当前账户": '确定要删除当前账户',
    "数据": '数据',
    "信息": '信息',
    "当前最小需投入": '当前最小需投入',
    "我的账户": '我的账户',
    "添加账户": '添加账户',
    "启用双重认证": '启用双重认证',
    "未设置昵称": '未设置昵称',
    "编辑机器人": '编辑机器人',
    "机器人详情": '机器人详情',
    "确定删除": '确定删除历史记录',
    "新邮箱": '新邮箱',
    "刷新成功": '刷新成功',
    "产品": '产品',
    "社交": '社交',
    "U本位合约DCA": 'U本位合约DCA',
    "提示": '提示',
    "请输入1-16位昵称": '请输入1-16位昵称',
    "请输入昵称": '请输入昵称',
    "绑定邀请码": '绑定邀请码',
    "邀请码仅在注册后15天内可绑定, 且绑定后不可修改, 请确认填写的邀请码是否正确": '邀请码仅在注册后15天内可绑定, 且绑定后不可修改, 请确认填写的邀请码是否正确',
    "安全验证成功": '安全验证成功',
    "连接成功,为了您的交易安全,已自动开启登录验证": '连接成功,为了您的交易安全,已自动开启登录验证',
    "绑定谷歌验证器": '绑定谷歌验证器',
    "邮箱绑定": '邮箱绑定',
    "邮箱验证未完成，请先完成邮箱验证": '邮箱验证未完成，请先完成邮箱验证',
    "账户未验证邮箱/绑定GA，请先验证/绑定": '账户未验证邮箱/绑定GA，请先验证/绑定',
    "等待开仓": '等待开仓',
    "平仓中": '平仓中',
    "做多开仓中": '做多开仓中',
    "做空开仓中": '做空开仓中',
    "划转成功": '划转成功',
    "划转不能大于可划转金额": '划转不能大于可划转金额',
    "划转需要大于1": '划转需要大于1',
    "权益": '权益',
    "可用担保资产": '可用担保资产',
    "操作成功": '操作成功',
    "复制成功": '复制成功',
    "最小值": '最小值',
    "考虑到衍生品本身风险及机器人计算性能,单个品种最多可同时执行一个全仓、一个做多逐仓、一个做空逐仓机器人;": '考虑到衍生品本身风险及机器人计算性能,单个品种最多可同时执行一个全仓、一个做多逐仓、一个做空逐仓机器人;',
    "您可以灵活的在机器人实例、智能交易及交易所APP中手动干预仓位,因盈亏数据由交易所提供,此类操作将影响机器人盈亏。": '您可以灵活的在机器人实例、智能交易及交易所APP中手动干预仓位,因盈亏数据由交易所提供,此类操作将影响机器人盈亏。',
    "安全验证成功,为了您的交易安全,已自动开启登录验证": "安全验证成功,为了您的交易安全,已自动开启登录验证",
    "设置": '设置',
    "下载谷歌验证器": '下载谷歌验证器',
    "下载": '下载',
    "用户登录": '用户登录',
    "安卓用户登录应用商店,或使用手机浏览器搜索下载": '安卓用户登录应用商店,或使用手机浏览器搜索下载',
    "在谷歌验证器中添加密钥并备份": '在谷歌验证器中添加密钥并备份',
    "打开谷歌验证器,扫描下方二维码或手动输入下述密钥添加验证令牌。": '打开谷歌验证器,扫描下方二维码或手动输入下述密钥添加验证令牌。',
    "在谷歌验证器中获取验证码并输入": '在谷歌验证器中获取验证码并输入',
    "U本位合约DCA使用教程": 'U本位合约DCA使用教程',
    "验证码": '验证码',
    "修改昵称": '修改昵称',
    "解绑成功": '解绑成功',
    "GA验证": 'GA验证',
    "请输入API名称": '请输入API名称',
    "删除账户": '删除账户',
    "录入成功": '录入成功',
    "补仓金额倍数": '补仓金额倍数',
    "倍投起始单": '倍投起始单',
    "补仓金额增量": '补仓金额增量',
    "补仓间隔比例": '补仓间隔比例',
    "补仓增幅": '补仓增幅',
    "补仓反弹比例": '补仓反弹比例',
    "最大做多单数": '最大做多单数',
    "最大做空单数": '最大做空单数',
    "参数出错": '参数出错',
    "调整杠杆": '调整杠杆',
    "暂不支持": '暂不支持',
    "删除机器人": '删除机器人',
    "退出成功": '退出成功',
    "请勾选用户服务协议": '请勾选用户服务协议',
    "您录入的API与当前API来自不同账户，确认录入将创建新的连接": '您录入的API 与当前API来自不同账户，确认录入将创建新的连接',
    "今日资产变动": '今日资产变动',
    "近7天资产变动": '近7天资产变动',
    "总资产变动": '总资产变动',
    "立即开启": '立即开启',
    "稍后再说": '稍后再说',
    "是否立即开启机器人": '是否立即开启机器人',
    "上传头像图片大小不能超过 2MB": '上传头像图片大小不能超过 2MB',
    "上传头像图片只能是 JPG、PNG、JPEG 格式": '上传头像图片只能是 JPG、PNG、JPEG 格式',
    "U本位合约网格": 'U本位合约网格',
    "U本位合约网格-m": 'U本位合约网格',
    "多": '多',
    "空": '空',
    "总盈亏": '总盈亏',
    "资金账户": '资金账户',
    "交易账户-现货": '交易账户-现货',
    "交易账户-合约": '交易账户-合约',
    "网格机器人": '网格机器人',
    "创建网格机器人": '创建网格机器人',
    "如何开始交易": '如何开始交易',
    "活跃机器人": '活跃机器人',
    "查看教程": '查看教程',
    "U本位合约DCA机器人，可自由切换补仓模式，止盈形式也更加灵活": 'U本位合约DCA机器人，可自由切换补仓模式，止盈形式也更加灵活',
    "U本位合约网格机器人，网格交易的合约版本，放大本金，分仓套利": 'U本位合约网格机器人，网格交易的合约版本，放大本金，分仓套利',
    "帮助中心": '帮助中心',
    "在线客服": '在线客服',
    "新手引导": '新手引导',
    "开启交易前你需要将你的交易账户连接至Vtrading": '开启交易前你需要将你的交易账户连接至Vtrading',
    "账号信息可以修改账户信息，安全设置，登录设备": '账号信息可以修改账户信息，安全设置，登录设备',
    "修改账号信息": '修改账号信息',
    "API已过期,请重新录入": 'API已过期,请重新录入',
    "接管账户仓位": '接管账户仓位',
    "网格": '网格',
    "网格间隔": '网格间隔',
    "开仓上下限": '开仓上下限',
    "取消修改": '取消修改',
    "复制参数到自定义": '复制参数到自定义',
    "机器人仓位及盈亏计算规则：": '机器人仓位及盈亏计算规则：',
    "正在启动": '正在启动',
    "运行中": '运行中',
    "暂停中": '暂停中',
    "正在终止": '正在终止',
    "请先进行邮箱验证,再绑定邀请码": '请先进行邮箱验证,再绑定邀请码',
    "最大做多网格数": '最大做多网格数',
    "最大做空网格数": '最大做空网格数',
    "帮助": '帮助',
















    'tradeOffset': '交易方向',
    'scaleIndex': '倍投起始单',
    'openWaitTime': '开仓等待时长',
    'openSpaceAdd': '补仓间隔增幅',
    'amount': '首单投入',
    'amountModel': '补仓数量模式',
    'amountScale': '补仓数量倍数',
    'closeLossAfter': '止损后操作',
    'closeLossModel': '持仓止损',
    'closeLossThreshold': '持仓止损阈值',
    'closeModel': '止盈下单模式',
    'closeRebound': '止盈回撤比例',
    'closeThreshold': '止盈阈值',
    'maxLongCount': '最大做多单数',
    'openModel': '补仓下单模式',
    'openRebound': '补仓反弹比例',
    'openSpace': '补仓间隔比例',
    'maxShortCount': '最大做空单数',





    "不能绑定自己的邀请码": '不能绑定自己的邀请码',
    "您录入的API与当前API来自不同账户，确认录入将创建新的连接": '您录入的API 与当前API来自不同账户，确认录入将创建新的连接',
    "您录入的API与名称为 ": '您录入的API与名称为',
    "的API来自同一账户": '的API 来自同一账户',
    "确认录入将更新原有API?": '确认录入将更新原有API?',
    "未知API，确认导入可能会导致运行中的交易错误": "未知API，确认导入可能会导致运行中的交易错误",
    "年": '年',
    "月": '月',
    "日": '日',
    "实例已终止": '实例已终止',
    "欢迎使用": '欢迎使用',
    "编辑DCA机器人": '编辑DCA机器人',
    "创建DCA机器人": '创建DCA机器人',

    "帮助": '帮助',
    "价格": '价格',
    "市场价": '市场价',

    "保证金比率": '保证金比率',
    "请选择账户": '请选择账户',
    "请选择交易对": '请选择交易对',
    "机器人名称长度限制10个字符": '机器人名称长度限制10个字符',
    "请输入最大初始保证金": '请输入最大初始保证金',
    "保证金率": '保证金率',
    "因交易所合并计算仓位成本，此类操作将影响到机器人的仓位成本，进而影响到止盈止损的基准价和机器人的盈亏计算。": '因交易所合并计算仓位成本，此类操作将影响到机器人的仓位成本，进而影响到止盈止损的基准价和机器人的盈亏计算。',
    "您可以灵活的在机器人实例、智能交易及交易所APP中手动干预仓位，": '您可以灵活的在机器人实例、智能交易及交易所APP中手动干预仓位，',
    "不能为零": '不能为零',
    "单格投入": '单格投入',
    "请确认修改的内容": '请确认修改的内容',
    "提交修改": '提交修改',
    "实例仓位": '实例仓位',
    "量化托管": '量化托管',
    "终止上下限": '终止上下限',
    "网格机器人委托工具": '网格机器人委托工具',
    "网格委托工具使用手册": '网格委托工具使用手册',
    "复位": '复位',
    "价格下限": '价格下限',
    "价格上限": '价格上限',
    "热门问题": '热门问题',
    "支付问题": '支付问题',
    "消费问题": '消费问题',
    "账户问题": '账户问题',
    "机器人": '机器人',
    "请输入关键词搜索": '请输入关键词搜索',
    "搜索": '搜索',
    "搜索结果": '搜索结果',
    "未生效": '未生效',
    "此版本暂不支持直接编辑": '此版本暂不支持直接编辑',
    "永续": '永续',
    "退出": '退出',
    "委托信息": '委托信息',
    "相关文章": '相关文章',
    "抱歉，没有找到相关内容": '抱歉，没有找到相关内容',
    "上限应大于下限": '上限应大于下限',
    "最低价终止": '最低价终止',
    "最高价终止": '最高价终止',
    "网格相关教程": '网格相关教程',

  }
}

export default cn

//       $t('m.搜索')