import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '/src/utils/rem.js'

Vue.config.productionTip = false


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import locale from 'element-ui/lib/locale/lang/en'

import './tv'

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

let lang=localStorage.getItem('lang')
if(lang=='ch'){
  Vue.use(ElementUI);
}else{
  Vue.use(ElementUI, { locale })
}

import moment from 'moment'
Vue.prototype.$moment = moment
moment.locale('zh-cn')


import i18n from './i18n/i18n'   //国际化


import md5 from "js-md5"
Vue.prototype.$md5 = md5    //md5 加密


import Public from "./public/index"
Vue.prototype.Public = Public

import lottie from 'vue-lottie';
Vue.component('lottie', lottie)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted () {
    //SEO预渲染
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
