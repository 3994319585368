<!--丰富的按钮 默认、悬浮、点击、选中 样式-->
<template>

  <div class="richbut" :style="richStyle" @click="$emit('click')"

  @mouseover="over = true"
  @mouseout="over = false"

  @touchstart="touchstart()"
  @touchend="touchend()" 
  @mousedown="down = true"
  @mouseup="down = false">
    <slot :richState="richState"></slot>
  </div>
</template>

<script>

export default {
  props: {
    select: { //true选中
      type:Boolean,
      default: false
    }, 

    /**
     * 背景 blan蓝 bhei黑 bhui灰
     * 文字 llan蓝 lhui灰
     */
    styleType: '', 

    hdown: Object, //点击样式
    hover: Object, //悬浮样式
    hselect: Object, //选中样式
  },

  data() {
    return {
      down: false, //点击
      over: false, //悬浮
      louchTimer: undefined, //触摸定时器


      blan:{
        'default':{'background': '#2670FA','color':'#FFFFFF'},
        'over':{'background': '#66A1FF','color':'#FFFFFF'},
        'down':{'background': '#2961D9','color':'#FFFFFF'},
        'select':{'background': '#2670FA','color':'#FFFFFF'}
      },
      bhei:{
        'default':{'background': '#0E111E','color':'#FFFFFF'},
        'over':{'background': '#3F4254','color':'#FFFFFF'},
        'down':{'background': '#000000','color':'#FFFFFF'},
        'select':{'background': '#000000','color':'#FFFFFF'}
      },
      bhui:{
        'default':{'background': '','color':'#181C32'},
        'over':{'background': '#F0F2F6','color':'#181C32'},
        'down':{'background': '#2670FA','color':'#FFFFFF'},
        'select':{'background': '#2670FA','color':'#FFFFFF'}
      },
      bhuimin:{
        'default':{'background': '','color':'#181C32'},
        'over':{'background': '#F0F2F6','color':'#181C32'},
        'down':{'background': '#F0F2F6','color':'#181C32'},
      },

      llan:{
        'default':{'color':'#2670FA'},
        'over':{'color':'#66A1FF'},
        'down':{'color':'#2961D9'},
        'select':{'color':'#2961D9'}
      },
      lhei:{
        'default':{'color':'#3F4254'},
        'over':{'color':'#2670FA'},
        'down':{'color':'#2961D9'},
        'select':{'color':'#2670FA'}
      },
      lhui:{
        'default':{'color':'#7E8299'},
        'over':{'color':'#3F4254'},
        'down':{'color':'#0E111E'},
        'select':{'color':'#0E111E'}
      },
    };
  },

  mounted() {
  },

  computed: {

    //当前状态
    richState() {

      var bol = /mobile/i.test(navigator.userAgent); //手机、平板
      if (this.select) return 'select';
      if (this.down) return 'down';
      if (this.over && !bol) return 'over'
      return 'default';
    },

    //当前样式
    richStyle() {

      if (this.styleType) {
        return this[this.styleType][this.richState];
      }
      
      if (this.select && (this.hselect || this.hdown)) {
        return this.hselect || this.hdown;
      }

      if(this.down && (this.hdown || this.hselect)) {
        return this.hdown || this.hselect;
      }

      if(this.over && this.hover) {
        return this.hover;
      }

      return {}
    }
  },

  methods: {

    /************************** 事件 */
    //H5-触摸
    touchstart() {
      this.down = true;

      if(this.louchTimer) clearTimeout(this.louchTimer);
      this.louchTimer = setTimeout(() => {
        this.down = false;
      }, 500);
    },

    //H5-结束触摸
    touchend() {
      clearTimeout(this.louchTimer);
      this.down = false;
    },

  },
};
</script>

<style lang='scss' scoped>
.richbut {
  cursor: pointer;
}
</style>
