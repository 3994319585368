const en = {
  m: {
    "langs":'En',

    "新手必读":"Must-read for beginners",
    "区块链资讯":"Blockchain information",
    "策略视频":"Strategy video",
    "暂未开放":"Not yet open",

    "个人中心":"Personal center",
    "交易策略":"Trading Bots",
    "合约":"Futures",
    "定价":"Pricing",


    "账户": "Account",
    "请输入邮箱": "Please enter your e-mail address",
    "密码": "Password",
    "还没有账户": "No account yet",
    "其他登录方式": "Other login methods",

    "账号": "Account",
    "请输入密码,字母加数字组合6-32位": "Please enter your password, a combination of letters and numbers from 6 to 32 digits",

    "重置密码": "Eeset password",
    "返回账号登录": "Eeturn to account login",
    "邮箱": "E-mail",
    "请输入邮箱地址": "Please enter your e-mail address",
    "返回上一步": "Return to previous step",
    "已发送": "Sent",
    "返回登录": "Return to account login",
    "新密码": "New password",
    "重复新密码": "Repeat the new password",
    "密码必须包含字母和数字，长度6-32位": "Passwords must contain letters and numbers with 6-32 digits",
    "两次输入密码不一致":"Inconsistency between two passwords",
    "验证成功": "Verification success",
    
    "邀请码": "Invitation code",
    
    
    /***** 登录注册 */
    "扫码登录": 'Scan Code',
    "邮箱登录": 'Email',
    "二维码有效期": 'QR code timeliness',
    "二维码过期": 'QR code expired',
    "重新获取": 'Retrieve',
    "打开VtradingAPP扫一扫登录": 'Open VtradingAPP and scan to log in',
    "没有账号": 'No account',
    "立即注册": 'Sign up',
    "请输入邮箱": 'Please enter your email',
    "请输入密码": "Please enter your password",
    "忘记密码": "Forget password",
    "登录": 'Sign in',
    "安全验证": "Security check",
    "谷歌验证": 'Google check',
    "邮箱验证": 'Email check',
    "请输入谷歌验证码": "Please enter the Google check code",
    "请输入邮箱验证码": "Please enter the email check code",
    "获取验证码": "Sent",
    "提交": "Commit",
    "登录成功": 'Sign in completed',
    "发送成功": 'Sent in completed',
    "修改成功": "Modification completed",
    "邮箱格式不正确": "Incorrect e-mail format",
    "请输入6-20位字母加数字新密码": 'New password with 6-20 letters and numbers',
    "请输入6-20位字母加数字密码": '6-20 letter and number password',
    "密码格式错误": 'Password format error',
    "确认密码格式错误": 'Confirm password format error',
    "请再次输入密码": "Please enter your password again",
    "两次密码不一致": 'Two passwords do not match',
    "重置密码": 'Reset password',
    "下一步": "Next",
    "请输入验证码": 'Please enter the verification code',
    "注册成功": 'Sign up completed',
    "邮箱注册": "Email sign up",
    "请输入邀请码": "Invitation code",
    "选填": "Optional",
    "注册": 'Sign up',
    "已有账号，立即登录": "Login with an account",

    /****** 量化学院 */
    "量化学院":"Academy",
    "欢迎来到新手学院": 'Welcome to the Beginners College',
    "请输入搜索内容": 'Search articles',
    "暂无内容": 'No content',
    "文章": 'Article',
    "推荐文章": 'Recent articles',
    "搜索结果": 'Result',
    "无搜索内容": 'No search content',
    "新手必读": 'Trading',
    "区块链资讯": 'Web3',
    "发布于": 'Published on',

    /***** 0 */
    "无需编码的":"Without coding",
    "自动加密货币交易":"Automated crypto  trading ",
    "机器人":"Bot",
    "像专业人士一样，释放您的交易潜力。":"Trade like a pro. Unlock your trading potential.",

    /***** 1 */
    "通过 Vtrading 机器人最大化您的利润":"Maximize your profit with Vtrading  bot",    
    "将您的交易帐户与API密钥连接起来并立即创建您的加密机器人":"Connect your trading account to the API key and immediately create your encryption robot",


    "交易机器人":"Trading bot",
    "适用于每种市场趋势看涨、持平或看跌。":"For every market trend bullish, flat, or bearish.",
    
    "终端":"Terminal",
    "批量执行手动订单。":"Execute manual orders in batches.",
    
    "移动应用程序":"Mobile app",
    "下载应用程序以随时随地管理您的交易机器人。":"Download the app to manage your trading bot anytime,anywhere.",
    "不错过任何市场趋势。":"Never miss any market trends.",
    
    "交易信号":"Trading signals",
    "从布林线、价格警报、交易视图信号等中进行选择，以便交易机器人在正确的时间启动和停止。":"Choose from  Bollinger, Price Alert,TradingView Signals, and more for the trading bot to start and stop at the right time.",
    
    "跟单交易":"Copy trading",
    "帮助您提高机器人的利润并学习其他交易者的策略。":"Help you  improve  bot's profits and learn  from other traders' strategies.",
    
    "试试看":"Try it out",


    /***** 2 */
    "在顶级加密货币交易所运行交易机器人":"Run trading bots on top crypto  exchanges",
    "将您的交易帐户与 API 密钥连接起来并立即创建您的加密机器人。":"Connect your exchange account with API keys and create your crypto bot instantly.",
    "接入账户":"Connect account",


    /***** 3 */
    "复制顶级交易机器人策略":"Copy top trading bots strategies",
    "发现并复制交易市场上最好的交易机器人以增加您的利润":"Discover and copy the best trading bots on the trading market to increase your profits",
    "下载APP":"Download App",

    /***** 3- */
    "策略收益排行榜":"Strategies benefit ranking",
    "与优秀的策略交易员同行":"Walking with excellent strategy traders",

    "总投入":"AUM",
    "总收益率":"PnL%",
    "账户权益":"Account Equity",
    "持仓盈亏":"UPnL",
    "总年化":"APY",
    "交易笔数":"Number of orders",
    "交易额":"Amount order",
    "最大回撤":"Max drawdown",

    "我要跟单":"Follow",
    "仓位阈值":"Position threshold",

    
    /***** 4 */
    "Vtrading是怎么运行的？":"How it Works ",
    "将您的交易所与 API 密钥连接起来并创建机器人进行交易。":"Connect your exchange with API keys and create bots to trade.",
    "您的资金绝对安全，API经过多重算法加密，安全性高！":"Your funds are  absolutely safe. The API is encrypted by multiple algorithms and is highly secure!",
    "您可以通过API连接多个交易所，管理不同的资金账户。":"You can connect multiple exchanges with API to manage different fund accounts.",
    "更轻松地检查您的帐户或执行订单。":"Make it easier to check your account or execute an order.",
    "连接账户":"Connect account",

    /***** 5 */
    "随时随地进行交易":"Trade anytime, anywhere",
    "扫描下载":"Scan to Download",
    
    /***** 6 */
    "复制其他交易者的策略": 'Copy other traders’ strategies',

    "订阅其他使用者的策略信号": 'Subscribe to other users’ strategy signals',

    "无须配置参数": 'No configuration parameters required',
    "小白也能快速启动策略": 'Beginners can also quickly start trading strategies',

    "自己建立策略信号发起跟随": 'Create your own strategy signals and initiate following',
    "其他使用者可通过付费跟随您的策略": 'Other users can pay to follow your strategy',

    /***** 7 */
    "从2017年开始":"Since 2017",
    "Vtrading和大家一起经历了熊市和牛市":"Vtrading has experienced bear and bull markets with everyone",
    "Vtrading和大家一起经历了熊市和牛市，从2017年开始":"Vtrading has experienced bear and bull markets with everyone, Since 2017",
    "全球用户":"Traders Worldwide",
    "机器人数量":"Bots Created",
    "日交易量":"Daily trading Volume",

    /***** 8 */
    "关注您的利润增长":"Watch Your Profits Grow",
    "从强大的交易机器人到智能订单工具，vtrading 机器人可以帮助您在加密世界中实现利润最大化":"From powerful trading bots to smart orders tools,vtrading bots can help you Maximize  profits  in the crypto world",
    
    "交易机器人":"Trading bots",
    "借助全自动交易机器人，24/7 从每个市场变动中获利。":"Profit from every market move 24/7, with fully automated trading bots.",
    "在几分钟内设置 GRID 和 DCA 策略。":" Set up  GRID and DCA strategies in a few minutes.",
    
    "智能订单":"Smart orders",
    "通过强大的订单功能执行您的交易策略。":" Execute your trading strategy with powerful order functionality.",
    "通过不同的功能控制您的风险或增加您的利润，如止损、止盈、追踪止盈、单轨等。":"Control your risk or increase your profits through different functions, like Stop Loss, Take Profit, Trailing Take Profit, Singal Track and so on.",

    "信号终端":"Signal terminal",
    "信号终端可以更智能地运行交易机器人。":"Signal terminals can run trading bots more intelligently.",
    "无论是复制的策略还是自己创建的外部信号通过 TradingView ，机器人可以自动执行。":"Whether it is a copied strategy or an external signal created by yourself through TradingView , bots can automatically execute.",


    /***** 9 */
    "设置机器人需要多长时间？":" How long does it take to set up the bot?",
    "只需几分钟即可开始交易，只需通过API密钥连接到您的交易所，选择所需的交易货币并设置参数即可。":" It only takes a few minutes to start trading. Just connect to  your exchange via API key, select the desired trading currency and set the parameters.",
    "启动交易机器人需要多少钱？":"How much  money do i need  to start a trading bot?",
    "没有最低交易限制，但为了使策略良好运行，建议单个机器人投资超过 200 美元。":"There is no minimum trading limit, but in order for the strategy to run well, it is recommended to invest more than $200 in a single bot.",
    "Vtrading可以在哪些类型的市场进行交易？":"What type  of markets can Vtrading trade in?",
    "Vtrading 有现货和合约策略，以及网格机器人、DCA 机器人和其他类型的策略。 无论是牛市、熊市还是波动市场，您都可以选择不同的策略来执行交易。":"Vtrading has spot and contract strategies, as well as Grid bots, DCA bots and other types of strategies. Whether it is a bull market, a bear market, or a volatile market, you can choose different strategies to execute trade.",
    "使用 vtrading 机器人进行交易安全吗？":"Is it safe to trade with vtrading bots?",
    "Vtrading不存储您的资金，您的账户只能通过API密钥访问进行交易，并且API经过多重算法加密，绝对安全。 您可以完全控制您的资金。":"Vtrading does not store your funds, your account can only be accessed via API keys to trade , and the API is encrypted by multiple algorithms, absolutely safe. You have complete control over your funds.",
    "我可以随时取款吗？":"Can I withdraw money at any time?",
    "Vtrading不存储您的资金，因此您可以随时终止您的策略并转移您的资金。":"Vtrading does not store your funds, so you can terminate your strategy and transfer your funds at any time.",

    /******** 10 */
    "公司主题曲":"Company theme song",
    "我的人生要自己把握，Go with Vtrading to Victory。":"My life needs to be controlled by myself, Go with Vtrading to Victory.",

    /******** 11 */
    "Vtrading数字资产":"About Vtrading  ",
    "Al量化":"AI Crypto ",
    "服务平台介绍":"Trading Platform",
    "Vtrading数字资产Al量化服务平台介绍":"About Vtrading  AI Crypto Trading Platform",
    "Vtrading成⽴于2017年12⽉，旨在为不同层次的客户提供差异化的量化交易策略服务。":"Vtrading was established in December 2017, aiming to provide differentiated AI quantitative trading strategy services to customers at different levels.",
    "Vtrading数字资产AI量化服务平台提供可视化图元平台编辑策略、Hbase系统快速调试回测、自定义发布商城策略、资管系统、智能跟单系统、独⽴返佣系统、⼤客户私有定制部署、第三⽅嵌⼊式系统等功能及服务。":"The Vtrading digital asset AI quantification service platform provides visual element platform editing strategies, Hbase system rapid debugging and backtesting, and customized publishing mall strategies.Asset management system, intelligent documentary system, independent rebate system, large customer private customized deployment, third-party embedded system and other functions and services.",
    "用户可通过Vtrading提供的多种服务来实现不同的量化需求，让普通用户无需懂得编写代码也能运行量化策略，让量化交易变得更简单。":"Users can realize different quantitative needs through the various services provided by Vtrading, allowing ordinary users to run quantitative strategies without knowing how to write code, making quantitative trading easier.",

    /******** bottom */
    "平台策略":"Platform",
    "DCA策略":"DCA Bot",
    "网格策略":"GRID Bot",
    "DCA合约策略":"DCA Futures Bot",
    "网格合约策略":"GRID Futures Bot",
    "信号策略":"Signal Bot",
    "资源":"Resources",
    "新手学院":"Academy",
    "新闻资讯":"News",
    "联系我们":"Contacts",


    /************************ 网格策略 */
    "什么是网格交易？":"What is grid trading?",
    "网格交易是一种量化交易策略，即在震荡行情中通过一定的价格区间构造出一系列的买卖价位, 不断的执行低买高卖, 从而获得波段收益的一种交易方法。":"Grid trading is a quantitative trading strategy, which is a trading method that constructs a series of buying and selling prices within a certain price range in a volatile market, and continuously executes buying low and selling high, thereby obtaining swing returns.",
    "具体来说，网格交易是以一个价格为基础，在该价格的上下划分多个区间或者网格，并在每条网格上预先设定相应的买入和卖出订单。":"Specifically, grid trading is based on a price, dividing multiple intervals or grids above and below the price, and pre-setting corresponding buy and sell orders on each grid.",
    
    "网格交易适用于什么行情？":"What market conditions is grid trading suitable for?",
    "网格交易更合适在持续震荡的行情下交易获利。":"Grid trading strategy  is more suitable for profit trading in continuously volatile market conditions.",
    "但如果行情上涨，您可以通过调整网格策略的参数从而将利润最大化":"But if the market goes up, you can maximize profits by adjusting the parameters of the grid strategy",

    "Vtrading 网格机器人的优势":"Advantages of Vtrading Grid Robot",
    "Vtrading平台的网格机器人兼顾各种行情，无论是上涨、下跌还是震荡都可以获利。":"The grid bot of the Vtrading platform takes into account various market conditions and can make profits whether it is rising, falling or oscillating.",
    "下跌行情使用币本位机器人套利赚币，上涨行情增加资金投入，震荡行情又可以调整网格区间":"Use coin-based bot arbitrage to earn coins when the market is falling, increase capital investment when the market is rising, and adjust the grid range when the market is turbulent.",
    "灵活":"Flexible",
    "网格参数随用随改，及时生效":"Grid parameters can be changed as needed and take effect in a timely manner",
    
    "持续稳定":"Sustained stability",
    "网格区间灵活调整，从容面对上涨或下跌行情":"The grid range can be flexibly adjusted to calmly face rising or falling market conditions.",
    
    "风险可控":"Risk controllable",
    "通过参数来调整限制订单数量，从而应对突发行情，对于资金仓位的管控有非常好的帮助":"Adjust the limit order quantity through parameters to cope with unexpected situations, which is very helpful for the management and control of capital positions. ",


    "观看网格讲解视频":"Watch the grid explainer video",
    "播放":"Play",

    "如何启动网格交易策略？":"How to start a grid trading strategy?",
    "通过API链接您的交易所":"Connect your exchange via API",
    "选择网格策略":"Choose  grid strategy",
    "选择模式开启策略":"Select mode to start the bot",


    /************************ 合约网格策略 */
    "期货网格交易机器人":"Futures Grid Trading bot",
    "降低您的风险，增强您的交易盈利":"Reduce your risk and enhance your trading profits",
    "期货市场波动比较大、交易风险高，如果我们使用网格交易机器人在期货市场交易，可以利用杠杆放大收益，通过设定的网格区间可以持续交易获利，交易产生的利润又可以补充保证金，从而降低期货账户的风险":"The futures market has relatively large fluctuations and high transaction risks. If we use a grid trading bot to trade in the futures market, we can use leverage to amplify profits. We can continue to make profits through the set grid range, and the profits generated by the transaction can replenish the margin. thereby reducing the risk of futures accounts",
    "立即交易":"Start trading",

    "利用加密货币期货交易获利":"Profit from Cryptocurrency Futures Trading",
    "以更低的保证金进行更高的交易质量":"Trades Higher volumes  with lower margins",
    "交易杠杆高达 X10":"Trading leverage up to X10",
    "灵活选择多空交易":"Flexible choice of long and short trade",

    
    "选择您的策略":"Choose Your Strategy",
    "无论市场是下跌还是上涨，都可以通过 Vtrading 的网格期货加密交易机器人增强您的交易游戏并实现您的目标。":"Empower your trading game and achieve your goals with Vtrading's GRID Futures crypto trading bot, whether the market is falling or rising.",
    
    "当市场上涨时":"When price rises",
    "增强利润":"Enhance profits",
    "网格策略期货多头":"Grid Strategy Futures Long",
    "通过不间断交易来积累利润，从而增强收益。":"Enhance your profits by accumulating profits through non-stop trading.",
    
    "当市场下跌时":"Earn crypto in",
    "赚取加密货币":"The falling market",
    "网格策略期货空头":"Grid Strategy Futures Short",
    "通过不断高卖低买的形式，持续追踪下跌市场交易获利。":"By constantly selling high and buying low,  track the falling market transactions and make profits.",
    
    "只需三步就可以实现网格期货自动化交易":"Just three steps to automate grid futures trading",
    "选择期货网格策略":"Choose a futures grid strategy",
    "选择策略方向并启动策略":"Select the trading direction and start the strategy",

    
    /************************ DAC合约网格策略 */
    "什么是DCA策略？":"What is DCA strategy",
    "DCA是一种美元平均成本策略，通过分批买入建仓从而降低买入成本，同时将DCA的策略运用到期货机器人上更能有效的控制风险，借助期货的杠杆特性有效的利用资金放大账户收益。":"DCA is a dollar-cost averaging strategy that reduces buying costs by buying and building positions in batches. At the same time, applying DCA's strategy to futures bots can more effectively control risks, and use the leverage characteristics of futures to effectively use funds to amplify account returns.",
    

    "选择您的策略":"Choose Your Strategy",
    "无论市场是下跌还是上涨，都可以通过 Vtrading 的DCA期货加密交易机器人增强您的交易游戏并实现您的目标。":"Whether the market is falling or rising, enhance your trading game and achieve your goals with Vtrading’s DCA Futures crypto trading bot.",
    
    "利润增强":"Profit enhancement",
    "当价格上涨时":"When the market rise",
    "DCA策略期货多头":"DCA Futures Long",
    "将您的资金分批投入以获得更好的平均价格。 最大化您的回报并最小化波动影响。":"Invest your money in tranches to get a better average price. Maximize your returns and minimize volatility.",
    
    "赚取加密货币":"Earn crypto",
    "当市场下跌时":"When the market falls",
    "DCA期货空头":"DCA Futures Short",
    "分批建立空头头寸，从而控制市场风险，避免账户持仓均价建立在行情底部，同时追踪下跌行情来交易获利。":"Open  short positions in batches to control market risks, avoid the average account position price from being established at the bottom of the market, and track the falling market to make trading profits.",
    

    "DCA策略+信号触发组合机器人":"DCA strategy + signal trigger  bot",
    "最大化您的交易利润":"Maximize your trading profits",
    "通过Vtrading提供的信号触发功能，可以设置价格突破或跌破策略，也可以自定义设置 TradingView 启动和停止信号，以帮助交易者寻找更好的入场时机，从而增强策略的盈利能力":"Through the signal triggering function provided by Vtrading, you can set up price breakthrough or breakdown strategies, and you can also customize TradingView's start and stop signals to help traders find better open opportunities, thereby enhancing the profitability of the strategy.",

    
    "只需 5个简单步骤即可启动 DCA 合约策略":"Just 5 Easy steps to automate DCA Futures Bot",
    "通过 Vtrading 开始交易，释放您的交易潜力":"Start trading  to unleash your trading potential with Vtrading",
    "通过API连接您的交易所":"Connect your exchange via API",
    "选择DCA期货机器人":"Choose DCA Futures Bot",
    "选择交易方向并启动策略":"Select the trading direction and start the strategy",
    "选交易标的":"Pick a pair",
    "运行机器人并观察其盈利":"Run the bot and watch it profits",

    
    /************************ 下载 */
    "download_one_1":"Trade like a pro with Vtrading",
    "download_one_2":"bot",
    "download_one_3":"Make crypto trading easier",
    "download_one_but_1":"Download App",
    "download_one_but_2":"Download Android version",

    "download_two_1":"Watch Your Profits Grow",
    "download_two_2":"From powerful trading bots to smart orders tools",
    "download_two_3":"Just need one app",

    "download_three_1":"Automated crypto  trading bot without coding",
    "download_three_2":"Trade crypto like a pro with our crypto trading platform on your Mobile terminal.",
    "download_three_3":"Help you improve bot’ s profits and learn from other traders‘ strategies.",

    "download_four_1":"One terminal manages accounts on multiple exchanges",
    "download_four_2":"Connect to the exchange and manage accounts via API, automatically run strategies, and monitor profit trends anytime and anywhere...",

    "download_five_1":"Maximize your profits",
    "download_five_2":"Unlock your trading potential.",
    "download_five_but_1":"Download App",



    
    

    "开始交易": 'Start now',
    "查看详情": 'Find more details',
    "牛市行情如何盈利？": 'How to achieve profit in a bull market?',
    "美元平均成本": 'DCA (Dollar-COST Averaging)',
    "使用DCA策略，可以逐步降低买入成本，等待行情反弹从而获利。": 'Lower the cost gradually and take profit until the market rebound',
    "网格策略": 'GRID Bot',
    "通过分批买入、分批卖出的形式不断在波动中套利": 'Continuously arbitrage profits from fluctuations by buying and selling in batches ',
    "信号触发器": 'Signal trigger',
    "通过TradingView等外部警报信号，设置触发器，从而提前对熊市的早期迹象做出策略调整自动批量触发信号，执行止盈、止损、暂停、清仓等操作": 'Set the trigger based on the external alarm signal such as TradingView   to make decisions accordingly to the early signs of the bear market.Trigger the signals automatically to execute actions of take-profit, stop-loss, pause, clearance, etc.',
    "创建机器人": 'Created Bot',
    "使用Vtrading交易机器人，让获利更简单": 'Trading is hard ,but Vtrading makes it easier',
    "无论是上涨或者下跌行情，都可以通过Vtrading机器人获得利润，我们需要做的就是设定目标和调整策略，让机器人更智能化运行以达成目标。": 'Win the profit with Vtrading trading bots regardless of the market change. Just set the goal and adjust the strategy, allowing the bots to run more intellectually to achieve your target',
    "7*24小时自动执行交易": '7*24 auto trading',
    "机器人会按照设定的参数执行交易，在震荡市场下产生的收益远远超出预期": 'Vtrading bots will execute trading with preset parameters, generating more revenue than expected in the fluctuated market',
    "多重策略可供选择，无障碍穿越牛熊": 'Multiple strategies are available, barrier-free crossing of bulls and bears',
    "丰富的策略类型可为不同的市场行情提供多样的选择，无论是网格策略、趋势策略、还是合约策略，您都可用通过Vtrading建立策略模板执行自动化交易": 'A variety of strategies adapt to different market situations. No matter grid strategy, trend strategy, or future strategy, create a strategy template with Trading to perform the auto trading',
    "学会掌控市场": 'Learn how to master the market',
    "使用合理的策略，设置稳定的参数来建立您的加密货币投资组合，以达到交易利润最大化，通过策略及其参数控制风险，最小化您的交易损失": 'Create a investment combo with a smart strategy to achieve profit maximazation—control risk through strategy and parameters to minimize your loss',
    "如何启动量化机器人": 'How to start the quantitative trading bot ',
    "通过API密钥连接交易所并创建机器人执行下单交易": 'Connect to exchange through API key and create bots to perform trading.',
    "您的资金仍然在交易所，相比于将资金直接转入量化平台，使用API链接将更加安全可靠。并且通过API可以一键管理您的多个平台账户资产，查看账户持仓或执行头寸交易更加便捷。": 'Your funds are still kept in exchange. API key connection is safer compared to transferring funds to the platform directly.Multiple funds account in different exchanges can be managed with API connections, making checking holding positions and trade positions easier. ',
    "以下交易所支持使用Vtrading自动交易工具": 'Exchanges that support Vtrading auto bots',
    "币安交易所":'Binance',
    "OKX交易所":'OKX',
    "HTX交易所":'HTX',
    


    "连接交易所": 'Connect exchange',
    "复制其他交易者的策略": 'Copy other traders’ strategies',
    "订阅其他使用者的策略信号": 'Subscribe to other users’ strategy signals',
    "无须配置参数": 'No configuration parameters required',
    "小白也能快速启动策略": 'Beginners can also quickly start trading strategies',
    "自己建立策略信号发起跟随": 'Create your own strategy signals and initiate following',
    "其他使用者可通过付费跟随您的策略": 'Other users can pay to follow your strategy',
    "敬请期待": 'Coming soon…',

    "APP扫码下载": "App code",

    // ****************
    "语言": 'language',


    "简体中文": '简体中文',
    "英文": 'English',

    "聚合账户": 'Aggregate Account',
    "总资产折合": 'Estimated Total Value',
    "今日收益": "Today's Return",
    "近7天累计收益": 'Total benefits of past 7d',
    "我的机器人": 'My Bot',
    "总收益": 'Total Benefit',

    "查看更多": 'More',

    "我的交易所": 'My Exchanges',
    "资产折合": 'Estimated Value',

    "切换主题": 'Theme',
    "交易所": 'Exchange',
    "担保资产率": 'Margin Ratio',
    "请选择交易所": 'Choose Exchange',
    "退出登录": 'Sign out',
    "请输入": 'Please enter',
    "计价单位": 'Currency',
    "连接": 'Connect',
    "开仓均价": 'Entry Price',
    "预估强平价": 'Est. Liquidation Price',
    "去": 'go',
    "聚合账户详情": 'Aggregate Account Details',
    "账户详情": 'Account Details',
    "统计数据": 'Statistical data',
    "资产走势": 'Asset Trend',
    "日期范围": 'Dates range',
    "累计收益": 'Benefits',
    "请选择日期范围": 'Please select date range',
    "每日收益": 'Daily Benefit',
    "币币账户": 'Exchange',
    "合约账户": 'Derivatives',
    "搜索": 'search',
    "币种": 'Tokens',
    "估值": 'Estimation',
    "价格变化": 'Change（24h）',
    "数量": 'Volume',
    "合计": 'Total',
    "名称": 'Name',
    "全部": 'All',
    "启用": 'Enabled',
    "关闭": 'Close',
    "做多": 'Long',
    "做空": 'Short',
    "双向": 'Two Side',
    "持仓量": 'Avail. | Total',
    "创建DCA机器人": 'Create DCA bot',
    "同步资产": 'Refresh',
    "账户类型": 'Account Type',
    "筛选": 'Filter',
    "清除筛选": 'Clear Filters',
    "机器人名称": 'Bot Name',
    "执行中": 'Running',
    "已终止": 'Terminated',
    "参数": 'Parameter',
    "启动": 'Start',
    "重启": 'Restart',
    "复制": 'Copy',
    "详情": 'Details',
    "终止": 'Abort',
    "币对": 'Contracts',
    // "U本位合约账户": 'USDT-M Futures Wallet',
    "U本位合约账户": 'USDT-M Futures Account',
    "交易方向": 'TradeDirection',
    "方向": 'Side',
    "保证金模式": 'MarginMode',
    "全仓": 'Cross',
    "逐仓": 'Isolated',
    "杠杆": 'Leverage',
    "持仓止盈": 'TakeProfit',
    "止盈平仓模式": 'Take profit mode',
    "一次性止盈": 'Take profit',
    "逐单止盈": 'Take profit inbatches',
    "止盈下单模式": 'TakeProfitMode',
    "追踪止盈": 'TrailingTP',
    "预埋单止盈": 'LimitOrderTP',
    "止盈阈值": 'TPThreshold',
    "止盈回撤比例": 'TPDevintion',
    "持仓止损": 'StopLoss',
    "止损阈值": 'StopLossThreshold',
    "止损后的操作": 'ExecutionAfterStopLoss',
    "终止机器人": 'TerminateBot',
    "继续交易": 'ContinueTrading',
    "高级设置": 'AdvancedSettings',
    "最低价": 'MinPrice',
    "最高价": 'MaxPrice',
    "开仓等待时长": 'CooldownBetweenDeals',
    "总收益止盈": 'TakeProfit',
    "总收益止损": 'StopLoss',
    "终止时自动撤销委托": 'AutoTermination',
    "终止时自动平仓": 'AutoCloseout',
    "账户资产": 'Balance',
    "可用权益": 'AvailableFunds',
    "划转": 'Transfer',
    "建议投入": 'RecommendFunds',
    "当前最小须投入": 'MinFunds',
    "账户已有策略，暂不可建": 'account has a full-position bot running, failed to create new orders',
    "执行时长": 'execution time',
    "交易": 'Trading Bots',
    "邀请好友": 'Invite Friends',
    "帮助": 'Help',
    "首页": 'Home',
    "DCA机器人": 'DCA Bot',
    "机器人列表": 'Bot list',
    "活跃实例": 'Active Instances',
    "历史": 'History',
    "未创建机器人": 'You have not created to an bot,click on the bottom of the button to create...',
    "连接交易所开始交易": 'Go connect to the exchange and start trading',
    "开始日期": 'startTime',
    "结束日期": 'endTim',
    "请输入机器人名称": 'Please enter bot name',
    "请输入交易对": 'Please enter pairs',
    "请勾选用户服务协议 ": 'Please select user Service Protocol ',
    "未启用": 'Disabled',
    "暂停": 'Paused',
    "启动中": 'Starting',
    "启动失败": 'Fail to start',
    "终止中": 'Terminating',
    "首单投入": 'BaseOrder',
    "补仓间隔比例": 'AddFundsSpaceRatio',
    "补仓反弹比例": 'TrailingBuyRatio',
    "最大做多单数": 'MaxLongOrder',
    "最大做空单数": 'MaxShortOrders',
    "请登录后再试": 'Please login and try again',
    // "天": 'days',
    // "时": 'hours',
    // "分": 'minutes',
    "天": 'd',
    "时": 'h',
    "分": 'm',
    "实例": 'Instances',
    "交易对": 'pairs',
    "盈亏": 'Profit and Loss',
    "状态": 'state',
    "未实现": 'Unrealized',
    "止盈终止": "Close at TP",
    "止损终止": "Close at SL",
    "自动终止": 'Close at Auto',
    "定时终止": "Close at Timed",
    "手动终止": "Close at Manual",
    "强制终止": "Close at Force",
    "成交记录": 'TradeHistory',
    "暂无实例": 'No data',
    "暂无数据": 'Empty Data',
    "开多": "Open long",
    "开空": "Open short",
    "平多": "Close long",
    "平空": "Close short",
    "委托数量": "Size",
    "委托价格": "Price",
    "收益": "Profits",
    "删除": 'Delete',
    "成交时间": 'Time',
    "持仓数量": 'Avail. | Total',
    "开仓均价": 'Open Price',
    "强平价": "Liq.Price",
    "补仓追踪": 'Add Funds Trailing',
    "当前委托": 'OpenOrders',
    "当前做单数": "SafetyOrders",
    "加仓": 'Add Funds',
    "市价平仓": 'Close All Positions',
    "邀请好友": 'Invite friend',
    "邀请链接": "Invite link",
    "访问": 'Visitors',
    "邀请": "Invite",
    "邀请记录": "Invite record",
    "邀请时间": "Time",
    "账户设置": "Account Settings",
    "昵称": 'NickName',
    "头像": 'Avatar',
    "绑定": 'Bound',
    "安全设置": 'Safety Settings',
    "修改": "Change",
    "谷歌验证器": "Google Authenticator",
    "未绑定": 'Unbound',
    "已绑定": "Bound",
    "换绑": "Change",
    "登录验证": 'Login Authentication​',
    "账号绑定": "Bind on Account",
    "登录设备": 'Login Device',
    "当前设备": 'Current device',
    "请输入名称": 'Please enter name',
    "请输入API key": 'Please enter Api Key',
    "请输入API Secret": 'Please enter Api Secret',
    "我已阅读并同意服务协议": 'I have read and agree to the [User Service Agreement]',

    "请输入币种": 'Enter Trading Tokens',
    "当前做单": 'Current Order',
    "加载中": 'Loading',
    "操作": 'Action',
    "成交数量": 'Closed Size',
    "成交价格": 'Closed Price',
    "时间": 'Time',
    "请输入划转数量": 'Enter Transfer Amount',
    "撤销": 'Revoke',
    "请选择": 'Choose',
    "到": 'to',
    "从": 'From',
    "可划转": 'Available for transfer',
    "确定": 'Confirm',
    "取消": 'Cancel',
    "复制密钥": 'Copy secret key ',
    "密钥": 'Secret key',
    "现货账户": 'Spot Account',
    "合约账户-U本位合约": 'Futures Account-USDT-M',
    "委托方向": 'Direction',
    "委托类型": 'Type',
    "市价委托": 'Market price',
    "解除绑定": 'Unbind',
    "交易账户": 'Trade account',
    "资金账户": 'Funding account',
    "切换验证方式": 'Swich',
    "刷新二维码": 'Refresh QR code',
    "打开 VtradingAPP 扫一扫登录": 'Open the Vtrading App and scan the code to login',
    "现在开始": 'Enter',
    "撤销中": 'Canceling',
    "持仓": 'Take  position',
    "持仓收益": 'Position income ',
    "来源": 'Source',
    "手动操作": 'Manual operation',
    "机器人表现": "Bot's performance",
    "限价委托": 'Limit',
    "确定要删除当前机器人": 'Are you sure you want to delete the current bot',
    "请输入价格": 'Enter a price',
    "可用": 'Available',
    "金额": 'Amount',
    "手动加仓": 'Manual',
    "是否解除绑定Vtrading账户": 'Are you sureyou want to unbind Vtrading account',
    "解绑": 'Unlink',
    "验证": 'verification',
    "设备名": 'Device',
    "最近登录时间": 'Recent login',
    "请输入正确的数量": 'Enter the correcct amount',
    "金额不能大于可用金额": 'Amount must less than available amount',
    "金额不能小于": '金额不能小于',
    "重复新邮箱": 'Enter new mail  again ',
    "请输入新邮箱": 'Enter new mail',
    "请输入新密码": 'Enter new password',
    "更新成功": 'Updated successfully',
    "再次输入新密码": 'Enter new password again',
    "请再次输入新密码": 'Enter new password again',
    "两次密码输入不一致": 'Inconsistent password',
    "请再次输入新邮箱": 'Enter new email address again',
    "两次邮箱输入不一致": 'Inconsistent  email',
    
    "删除成功": 'Delete Successful',
    "启动成功": 'Start  Successfully',
    "重启成功": 'Restart  Successfully',
    "终止成功": 'Terminate  Successfully',
    "暂停成功": 'Pause Successfully',
    "不能为空": "can't be blank",
    "倍": 'X',
    "单": 'Size',
    "绑定成功": 'Binding  successed',
    "请输入百分比": 'Enter a percentage',
    "机器人触发价": 'Trigger price',
    "每日利润": 'Daily profit',
    "请输入数量": 'Enter the quantity',
    "开启交易新世界": 'OPEN UP A NEW WORLD OF TRADING',
    "启动机器人": 'Confirm',
    "确定要启动当前机器人": 'Click to confirm starting current bot',
    "机器人创建成功": 'Bot Create Successful',
    "是否开启机器人": 'Confirm to Start Bot',
    "最大初始保证金": 'Maximun inicial margin',
    "IP地址": 'IP Address',
    "OPEN UP A NEW WORLD OF TRADING": '开启交易新世界',
    "智 能 交 易 / 就 选 Vtrading": 'Start your smart trading / today with Vtrading',
    "保存": 'Save',
    "编辑交易所": 'Link API',
    "编辑": 'Edit',
    "执行实例": 'Runing orders',
    "完成的实例数": 'Finished orders',
    "活跃的实例数": 'Active orders',
    "当前初始保证金占用": 'Inicial margin occupation',
    "活跃": 'Active',
    "确定要终止当前机器人": 'Are you sure you  want to  terminate the current  bot',
    "确定要删除当前账户": 'Are you sure you want to delete the current account',
    "数据": 'Data',
    "信息": 'info',
    "当前最小需投入": 'Minimum',
    "未设置昵称": 'Set  nickname',
    "编辑机器人": 'Choose stratge bot',
    "机器人详情": 'Bot Details',
    "新邮箱": 'New email',
    "刷新成功": 'Refresh succeeded',
    "产品": 'Products',
    "社交": 'Community',
    "U本位合约DCA": 'USDT-M DCA',
    "提示": 'Tips',
    "请输入1-16位昵称": 'Nickname must be  between  1-16 characters long',
    "请输入昵称": 'Set a  nickname',
    "绑定邀请码": 'Bind invitation code',
    "邀请码仅在注册后15天内可绑定, 且绑定后不可修改, 请确认填写的邀请码是否正确": 'The invitation code can only bound within 15 days after regestration,and cannnot be modified after binding,please confirm wether the invitation code is correct',
    "安全验证成功": 'Securite verification succeeded',
    "连接成功,为了您的交易安全,已自动开启登录验证": 'conection succeeded, for the security ,login verification has been turned  on ',
    "绑定谷歌验证器": 'Bind  google authenticator',
    "邮箱验证未完成，请先完成邮箱验证": 'Verify by email first',
    "账户未验证邮箱/绑定GA，请先验证/绑定": 'Your account is not bound to GA,please bind it first',







    "邮箱绑定": 'Email binding',
    "等待开仓": 'Open position waiting ',
    "平仓中": 'Closing',
    "做多开仓中": 'Exeuting a long position',
    "做空开仓中": 'Executing a short position',
    "划转成功": 'Successful transfer',
    "划转不能大于可划转金额": 'Amount must less than available amount',
    "划转需要大于1": 'Amount must be greater than 1',







    "权益": 'Equity',
    "可用担保资产": 'Avail. Margin',
    "最小值": 'min',





    "考虑到衍生品本身风险及机器人计算性能,单个品种最多可同时执行一个全仓、一个做多逐仓、一个做空逐仓机器人;": 'Considering the derevetives and performence of the bot,one coin pair can execute at most one cross  position ,one long isolate position bot , and one short isolate position bot at the same time ',
    "您可以灵活的在机器人实例、智能交易及交易所APP中手动干预仓位,因盈亏数据由交易所提供,此类操作将影响机器人盈亏。": 'You can mannually adjust positions between bot cases,automatic case or  Exchage case,this action will affects the profit and loss of bot cases.',









    "安全验证成功,为了您的交易安全,已自动开启登录验证": "Securite verification succeeded,login verification has been turned on ",
    "设置": 'Settings',
    "下载谷歌验证器": 'Download google authenticator',
    "下载": 'Download',
    "用户登录": 'User login',
    "安卓用户登录应用商店,或使用手机浏览器搜索下载": 'Android users could log in to appstore,or use the mobile browser to search and download',
    "在谷歌验证器中添加密钥并备份": 'Add key in google authenticator and backup',
    "打开谷歌验证器,扫描下方二维码或手动输入下述密钥添加验证令牌。": 'Open google authenticator  scan the QR code blow or manually enter the following key to add a verification  token ',
    "在谷歌验证器中获取验证码并输入": 'Get the verification code in google authenticator and enter it.',
    "U本位合约DCA使用教程": 'The tutorials course of DCA at USD-margine future',
    "验证码": 'code',
    "修改昵称": 'Change nickname',
    "验证": 'verification',
    "为了您的账号安全，请进行安全验证": 'For the security of your account, please verify your security',
    "副本": 'copy',


    "我的账户": "My Account",
    "添加账户": 'Add Account',
    "启用双重认证": 'Turn on  two-factor authentication',

    "操作成功": 'Operation successfully',
    "复制成功": 'Copy successfully',
    "解绑成功": "Unbind successfully",
    "GA验证": 'Google Authenticator',

    "确定删除": 'Confirm Detele',
    "请输入API名称": 'Enter api name',
    "删除账户": 'Delete account',
    "你还没有连接到交易所": 'You have not connected to an exchange',
    "录入成功": 'Connection succeeded',
    "补仓金额倍数": 'Add Funds ratio',
    "倍投起始单": 'Starting order multiplied',
    "补仓金额增量": 'Fixed Add Funds',
    "补仓间隔比例": 'Add Funds space Ratio',
    "补仓增幅": 'Add Funds Amplification',
    "补仓反弹比例": 'Trailing buy ratio',
    "最大做多单数": 'Max Long Order',
    "最大做空单数": 'Max Short Orders',
    "参数出错": 'Parameters Error',
    "调整杠杆": 'Adjust Leverage',
    "暂不支持": 'Not currently supported',
    "删除机器人": 'Delete bot',
    "退出成功": 'Exit successfully',
    "请勾选用户服务协议": 'Please select user Service Protocol',
    "多": 'Long',
    "空": 'short',
    "总盈亏": 'Total profit and loss',









    'tradeOffset': 'tradeOffset',
    'scaleIndex': 'scaleIndex',
    'openWaitTime': 'openWaitTime',
    'openSpaceAdd': 'openSpaceAdd',
    'amount': 'amount',
    'amountModel': 'amountModel',
    'amountScale': 'amountScale',
    'closeLossAfter': 'closeLossAfter',
    'closeLossModel': 'closeLossModel',
    'closeLossThreshold': 'closeLossThreshold',
    'closeModel': 'closeModel',
    'closeRebound': 'closeRebound',
    'closeThreshold': 'closeThreshold',
    'maxLongCount': 'maxLongCount',
    'openModel': 'openModel',
    'openRebound': 'openRebound',
    'openSpace': 'openSpace',
    'openSpaceAdd': 'openSpaceAdd',
    'openWaitTime': 'openWaitTime',
    'scaleIndex': 'scaleIndex',
    'tradeOffset': 'tradeOffset',
    'maxShortCount': 'maxShortCount',
    "不能绑定自己的邀请码": 'You cannot bind your own invitation code',

    "您录入的API与当前API来自不同账户，确认录入将创建新的连接": 'The API you entered is from another account ,confirming the entry will create a new link',


    "您录入的API与名称为": 'The API named',
    "的API来自同一账户": 'as same account as your entered',
    "确认录入将更新原有API?": 'comforming will update the original API?',
    "未知API，确认导入可能会导致运行中的交易错误": 'API is not recoglized,Importing may result transaction errors',
    "今日资产变动": 'Assets Today',
    "近7天资产变动": 'Assets in last 7 days',
    "总资产变动": 'Total Assets',
    "年": 'Year',
    "月": 'Month',
    "日": 'Day',

    "交易账户-现货": 'Trade account-spot',
    "交易账户-合约": 'Trade account-future',
    "网格机器人": 'Grid Bot',
    "创建网格机器人": 'Create a grid bot',
    "如何开始交易": 'How to start trading',
    "活跃机器人": 'Active Bot',
    "查看教程": 'Check the tutorial',
    "U本位合约DCA机器人，可自由切换补仓模式": 'Future DCA bot (golden standard)，freely switch adding modes',
    "U本位合约网格机器人，网格交易的合约版本": 'Future grid bot (golden standard), future version of grid trading',

    "帮助中心": 'Help Center',
    "在线客服": 'Online service',
    "新手引导": 'Beginner Guide',
    "上一步": 'Last step',
    "开启交易前你需要将你的交易账户连接至Vtrading": 'Connect your account to Vtrading before you start',
    "账号信息可以修改账户信息，安全设置，登录设备": 'Modify account info, security setting and login devices',
    "修改账号信息": 'Modify account info',
    "API已过期,请重新录入": 'The API key is expired, please try with new one',
    "接管账户仓位": 'Take over positions',
    "立即开启": 'Start immediately',
    "稍后再说": 'Talk later',
    "是否立即开启机器人": 'Start the bot?',
    "上传头像图片大小不能超过 2MB": 'Images must be 2MB or less',
    "上传头像图片只能是 JPG、PNG、JPEG 格式": 'Images must be .jpg or .jpeg format',
    "U本位合约网格": 'Golden-standard future grid',
    "U本位合约网格-m": 'USD-M future Grid',
    "网格": 'Grid',
    "网格间隔": 'Grid Interval',
    "开仓上下限": 'Upper/Lower price',
    "取消修改": 'Cancle Modification',
    "复制参数到自定义": 'Copy to custom',
    "机器人仓位及盈亏计算规则：": 'Calculation rules of bot position and P&L',

    "正在启动": 'Turning on',
    "运行中": 'Running',
    "暂停中": 'Pausing',
    "正在终止": 'Terminating',
    "请先进行邮箱验证,再绑定邀请码": 'Please verify  your email adrress befor binding the invitation code',
    "做多等待开仓": 'Waiting  for a long  position to open',
    "做空等待开仓": 'Waiting  for a short position to open',
    "做多平仓中": 'Closing the long',
    "做空平仓中": 'Closing the short ',
    "做多追踪止盈": 'Long direction Trailing TP',
    "做空追踪止盈": ' Short direction Trailing TP',
    "实例已终止": 'Active orders terminated',
    "欢迎使用": 'Welcome',
    "编辑DCA机器人": 'Choose stratge DCA bot',
    "最大做空网格数": 'Max grids in short direction',
    "最大做多网格数": 'Max grids in long direction',

    "帮助": 'Help',
    "价格": 'Price',
    "市场价": 'Market price',

    "保证金比率": 'Margine ratio',
    "请选择账户": 'Select account',
    "请选择交易对": 'Select trading pair',
    "机器人名称长度限制10个字符": 'Robot name length is limited to 10 characters',
    "请输入最大初始保证金": 'Enter a max initial margine',

    "U本位合约DCA机器人，可自由切换补仓模式，止盈形式也更加灵活": 'The DCA bot witn  usd-m future can freely switch  the adding mode ,and  take profit more flexible',
    "U本位合约网格机器人，网格交易的合约版本，放大本金，分仓套利": 'Grid bot with USD-M future,the future version of grid trading, enlarge the principle,split positions and arbitrage',
    "保证金率": 'Margine ratio',
    "因交易所合并计算仓位成本，此类操作将影响到机器人的仓位成本，进而影响到止盈止损的基准价和机器人的盈亏计算。": 'The exchange caculate the position cost in a consolidated manner will affect the position cost of the bot which inturn affects the benchmark price oftake profit an d stop loss and the calculation of the profit and stop loss of the bot',
    "您可以灵活的在机器人实例、智能交易及交易所APP中手动干预仓位，": "You can flexibly manually intervene positions in bot's instance, intelligente trading and Exchang APPs.",
    "不能为零": 'Cannot be 0',
    "单格投入": 'Input per grid',
    "请确认修改的内容": 'Please confirm the modified content',
    "提交修改": 'Submit changes',
    "实例仓位": 'Running position',
    "量化托管": 'Quantitative account custody',
    "终止上下限": 'Terminate upper and lower limits',
    "网格机器人委托工具": 'Grid bot delegate tool',
    "网格委托工具使用手册": 'Grid toll user manual',
    "复位": 'Reset',
    "价格下限": 'Lower price',
    "价格上限": 'Upper price',
    "热门问题": 'Top questions',
    "支付问题": 'About payment',
    "消费问题": 'About consumption',
    "账户问题": 'About account',
    "请输入关键词搜索": 'Enter a keyword to search',
    "未生效": 'Not active',
    "此版本暂不支持直接编辑": "This version doesn't support edit",
    "永续": 'Perpetual',
    "退出": 'Sign out',
    "委托信息": 'Orders imformation',
    "相关文章": 'Related articles',
    "抱歉，没有找到相关内容": 'Sorry nothing found',
    "上限应大于下限": 'The upper limit should be greater than the lower limit',
    "最低价终止": 'Termination of lowest price',
    "最高价终止": 'Termination of highest price',
    "网格相关教程": 'How it works',
  }
}

export default en
