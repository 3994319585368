<template>
<div class="bottomh5" v-if="bottom">
  
  <div class="column" :class="bottom.but1.click ? 'but' : ''"
  @click="tapClick(bottom.but1.click)">
    <img  class="img-logoh5" :src="bottom.but1.src">
    <span class="font12 ch7 mb32">{{bottom.but1.txt}}</span>
  </div>
  
  <span class="font14 fontc ch7 mb16" @click="tapClick(bottom.but4.click)"
  :class="bottom.but4.click ? 'but' : ''">{{$t('m.'+bottom.but4.txt)}}</span>

  <span class="font14 fontc cbai mb16" @click="tapClick(bottom.but5.click)"
  :class="bottom.but5.click ? 'but' : ''">{{$t('m.'+bottom.but5.txt)}}</span>

  <span class="font14 fontc cbai mb16" @click="tapClick(bottom.but6.click)"
  :class="bottom.but6.click ? 'but' : ''">{{$t('m.'+bottom.but6.txt)}}</span>

  <span class="font14 fontc cbai mb16" @click="tapClick(bottom.but7.click)"
  :class="bottom.but7.click ? 'but' : ''">{{$t('m.'+bottom.but7.txt)}}</span>

  <span class="font14 fontc cbai mb16" @click="tapClick(bottom.but8.click)"
  :class="bottom.but8.click ? 'but' : ''">{{$t('m.'+bottom.but8.txt)}}</span>

  <span class="font14 fontc cbai mb32" @click="tapClick(bottom.but9.click)"
  :class="bottom.but9.click ? 'but' : ''">{{$t('m.'+bottom.but9.txt)}}</span>
  
  <div class="row mb32">

      <div class="column all">
        <span class="font14 fontc ch7 mb16" @click="tapClick(bottom.but10.click)"
        :class="bottom.but10.click ? 'but' : ''">{{$t('m.'+bottom.but10.txt)}}</span>

        <span class="font14 fontc cbai mb16" @click="tapClick(bottom.but11.click)"
        :class="bottom.but11.click ? 'but' : ''">{{$t('m.'+bottom.but11.txt)}}</span>

        <span class="font14 fontc cbai" @click="tapClick(bottom.but12.click)"
        :class="bottom.but12.click ? 'but' : ''">{{$t('m.'+bottom.but12.txt)}}</span>
      </div>

      <div class="column all">
        <span class="font14 fontc ch7 mb16" @click="tapClick(bottom.but13.click)"
        :class="bottom.but13.click ? 'but' : ''">{{$t('m.'+bottom.but13.txt)}}</span>

        <span class="font14 fontc cbai mb16" @click="tapClick(bottom.but14.click, bottom.but14)"
        :class="bottom.but14.click ? 'but' : ''">{{bottom.but14.txt}}</span>

        <span class="font14 fontc cbai mb16" @click="tapClick(bottom.but15.click, bottom.but15)"
        :class="bottom.but15.click ? 'but' : ''">{{bottom.but15.txt}}</span>

        <span class="font14 fontc cbai" @click="tapClick(bottom.but16.click, bottom.but16)"
        :class="bottom.but16.click ? 'but' : ''">{{bottom.but16.txt}}</span>
      </div>
  </div>

  <div class="row acenter">

      <img v-for="(but, index) in bottom.buts" :key="'but'+index"
      class="img-icqh5" :src="but.src" 
      @click="tapClick(but.click, but)"/>
  </div>
</div>
</template>

<script>
export default {

  components: {
  },

  props: {
    bottom: Object
  },

  watch: {
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param) {
      if(!name) return;
      this.$emit('tapClick', name, param);
    }
  }
};
</script>

<style lang='scss'>
@import "@/assets/sass/_themeify.scss";
/**** 底部 */
.bottomh5 {
  display: flex;
  flex-direction: column;
  background: #181C32;
  width: 100%;
  height: 100%;
  padding: 32px;
  .img-logoh5 {
    width: 136px;
    height: 33px;
    margin-bottom: 6px;
  }

  .img-icqh5 {
    cursor: pointer;
    width: 36px;
    height: 36px;
    margin-right: 16px;
    background-size: cover;
  }
}
</style>
