<template>
  <div class="navpc">

    <div class="fixedpc">
      <div class="warppc">
        <div class="buts-leftpc">
        
          <RichBut class="logopc" @click="tapClick('goHome')">
            <template #default="{richState}">
              <img v-if="richState=='over'" 
              class="img-logopc" src="@/assets/image/v3/logo_lan_over.png">
              
              <img v-else-if="richState=='down'" 
              class="img-logopc" src="@/assets/image/v3/logo_lan_down.png">

              <img v-else
              class="img-logopc" src="@/assets/image/v3/logo_lan.png">
            </template>
          </RichBut>
  
          
          <RichBut @click="tapClick(item.click)"
          v-for="(item, index) in menus" :key="'menu'+index"
          class="menupc" styleType="bhui"
          :select="menu==index">
            <span>{{ $t("m."+item.title) }}</span>
          </RichBut>
        </div>
        
        <div class="buts-rightpc">

          <template v-if="!token">

            <RichBut @click="Public.toLogin()"
            class="butpc" styleType="bhui">
            {{$t("m.登录")}}
            </RichBut>

            <RichBut @click="Public.toRegister()" 
            class="butpc" styleType="blan">
            {{$t("m.注册")}}
            </RichBut>
          </template>

          <div v-if="token && accInfo" class="infopc" @click="tapClick('goSetUp');">
            
            <img v-if="accInfo.avatar" class="img-accpc" :src="accInfo.avatar"/>
            <img v-else class="img-accpc" src="@/assets/image/avatar.svg"/>
            
            <span v-if="accInfo.nickName || accInfo.account" class="namepc">{{ accInfo.nickName || accInfo.account }}</span>
          </div>

          <RichBut @click="yy = !yy"
          class="but-yypc" styleType="bhui"
          :select="yy">
            <img class="img-gqpc" :src="lang.url">
            <span>{{lang.tag}}</span>
          </RichBut>
        </div>
      </div>
    </div>
    
    <div v-if="yy" class="maskpc" @click.self="yy = false">
      
      <div class="sizespc" @click.self="yy = false">
        <div class="sizepc">
          
          <RichBut @click="tapClick('switchLang', item)"
          v-for="(item, index) in langs" :key="'lang'+index"
          class="cellpc" styleType="bhuimin">
            <img class="img-yypc" :src="item.url"/>
            <span class="font18 fontc">{{item.label}}</span>
          </RichBut>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RichBut from "@/components/RichBut.vue"
export default {
  components: {
    RichBut,
  },
  
  props: {
    /*菜单
     home(首页)、signin(注册)、signup(登录)
     0(交易)、1(合约)、2(量化学院)*/
    menu: Number, 
    menus: Array,
    lang: Object,
    langs: Array,
    token: Boolean,
    accInfo: Object,
  },

  data() {
    return {
      yy: false, //true 显示语言选择
      select: 'home', //hone 
      signupDown: false, //true 登录按钮按下
      signinDown: false, //true 注册按钮按下
    };
  },

  watch: {},
  
  mounted() {
  },

  methods: {

    /******************************* 点击 */
    //更多方法点击
    tapClick(name, param, ) {
      this.yy = false;
      this.$emit('tapClick', name, param);
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.navpc {
  width: 100%;
  height: 80px;
  
  .fixedpc {
    z-index: 888;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;

    display: flex;
    flex-direction: row ;
    justify-content: center ;
    .warppc {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 1200px;
      height: 100%;
      @include themeify {
        color: themed("topColor") !important;
        border-bottom: 1px solid themed("bottomShadow") !important;
      }
      
      .buts-leftpc {
        display: flex;
        flex-direction: row;
        align-items: center;

        .logopc {
          margin-right: 80px;
          .img-logopc {
            width: 147px;
            height: 36px;
          }
        }

        .menupc {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 36px;
          border-radius: 6px;
          padding: 0 12px;
          margin-right: 12px;

          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #181C32;
        }
      }

      .buts-rightpc {
        display: flex;
        flex-direction: row;
        align-items: center;

        .butpc {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 57px;
          height: 36px;
          padding: 0 12px;
          border-radius: 6px;
          margin-right: 20px;

          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
        }

        .but-yypc {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 74px;
          height: 36px;
          border-radius: 6px;
          border: 1px solid #E4E6EF;

          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #181C32;
          .img-gqpc {
            width: 24px;
            height: 16px;
            margin-right: 4px;
          }
        }

        .infopc {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 20px;

          .img-accpc {
            cursor: pointer;
            width: 36px;
            height: 36px;
            border-radius: 18px;
          }
          .namepc {
            font-size: 16px;
            margin-left: 16px;
            @include themeify {
              color: themed("primaryColor") !important;
            }
          }
        }
      }
    }

  }

  .maskpc {
    z-index: 889;
    position: fixed;
    top: 80px;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    .sizespc {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 1200px;
      .sizepc {
        z-index: 88;
        display: flex;
        flex-direction: column;
        background: #fff;
        padding: 10px;
        box-shadow:0 5px 10px 0 rgba($color: #000000, $alpha: 0.1);
        
        .cellpc {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          border-radius: 6px;
          .img-yypc {
            width: 24px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

::v-deep.el-dropdown-menu {
  z-index: 6500 !important;
}
</style>
