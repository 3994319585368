import ch from './ch'
import hk from './hk'
import en from './en'
import ko from './ko'
import ja from './ja'
export default {
  ch, //汉语
  hk, //繁体
  en, //英文
  ko, //韩文
  ja //日文
}

// Chinese      ZH     汉语 ch
// Hong Kong    HK     繁体
// English      EN     英文
// Korea        KO     韩文
// Japan        JA     日文
