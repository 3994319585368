const en = {
  m: {
    "langs":'En',

    

    "新手必读":"初心者向け",
    "区块链资讯":"ブロックチェーン・ニュース",
    "策略视频":"戦略ビデオ",
    "暂未开放":"まだオープンしてません",

    "个人中心":"個人センター",
    "交易策略":"取引戦略",
    "合约":"コントラクト",
    "量化学院":"クオンツ学校",
    "定价":"定価",

    "账户": "アカウント",
    "请输入邮箱": "メールを教えてください",
    "请输入账户": "アカウントを教えてください",
    "密码": "パスワード",
    "还没有账户": "またアカウントを持っていません",
    "其他登录方式": "他のログイン方法",

    "账号": "アカウント",
    "请输入密码,字母加数字组合6-32位": "パスワードを字母と数字の６－３２位の組み合わせで入力してください。",

    "重置密码": "パスワードをリセットします",
    "返回账号登录": "ログインページに戻ります",
    "邮箱": "メール",
    "请输入邮箱地址": "メールアドレスを入力してください",
    "返回上一步": "前のページに戻ります",
    "已发送": "配信しました",
    "返回登录": "ログインページに戻ります",
    "新密码": "ニューパスワード",
    "重复新密码": "ニューパスワードを再生してください",
    "密码必须包含字母和数字，长度6-32位": "パスワードにはぜひ６－３２位の字母と数字の組み合わせにしてください",
    "两次输入密码不一致":"二回のパスワードが一致していません",
    "验证成功": "確認成功",
    
    "邀请码": "招待コード",
    
    /***** 登录注册 */
    "扫码登录": 'スキャンログイン',
    "邮箱登录": 'メールボックス',
    "二维码有效期": 'QRコード有効期間',
    "二维码过期": 'QRコードの有効期限',
    "重新获取": '再取得',
    "打开VtradingAPP扫一扫登录": 'VtradingAPPスキャンログインを開く',
    "没有账号": 'アカウントがありません',
    "立即注册": '今すぐ登録',
    "请输入邮箱": 'メールアドレスを入力してください',
    "请输入密码": "パスワードを入力してください",
    "忘记密码": "パスワードをわすれました",
    "登录": "ログインします",
    "安全验证": "セキュリティ確認します",
    "谷歌验证": 'グーグル検証',
    "邮箱验证": 'メールボックスの検証',
    "请输入谷歌验证码": "グーグルの確認コードを入力してください",
    "请输入邮箱验证码": "メールのパスワードを入力してください",
    "获取验证码": "送信",
    "提交": "クエリ―",
    "登录成功": 'ログイン成功',
    "发送成功": '送信成功',
    "修改成功": "修正成功",
    "邮箱格式不正确": "メールの形式が不正解です",
    "请输入6-20位字母加数字新密码": '6-20ビットアルファベットと数字の新しいパスワード',
    "请输入6-20位字母加数字密码": '6-20ビットアルファベットと数字のパスワード',
    "密码格式错误": 'パスワードフォーマットエラー',
    "确认密码格式错误": 'パスワードフォーマットエラーの確認',
    "请再次输入密码": "パスワードをもう一度入力してください",
    "两次密码不一致": '2回のパスワードが一致しません',
    "重置密码": 'パスワードをリセット',
    "下一步": "次へ",
    "请输入验证码": '認証コードを入力してください',
    "注册成功": '登録に成功しました',
    "邮箱注册": "メールで登録します",
    "请输入邀请码": "招待コードを入力してください",
    "选填": "任意",
    "注册": "登録します",
    "已有账号，立即登录": "もうアカウントを持って、ログインします",

    /****** 量化学院 */
    "欢迎来到新手学院": '新人学院へようこそ',
    "请输入搜索内容": '検索内容を入力してください',
    "暂无内容": 'しばらく内容がない',
    "量化学院": '量子化学院',
    "文章": '文章',
    "推荐文章": 'おすすめ記事',
    "搜索结果": '検索結果',
    "无搜索内容": '検索内容なし',
    "新手必读": '初心者必読',
    "交易策略": '取引戦略',
    "区块链资讯": 'ブロックチェーン',
    "发布于": '公開',

    /***** 0 */
    "无需编码的":"エンコーディング必要がありません",
    "自动加密货币交易":"自動的に暗号化のトレード",
    "机器人":"ロボット",
    "像专业人士一样，释放您的交易潜力。":"専門家のように、あなたのトレードタレントを解放します",

    /***** 1 */
    "通过 Vtrading 机器人最大化您的利润":"ロボット・ヴィートレンディンであなたの利益をさいだいかにします",
    "将您的交易帐户与API密钥连接起来并立即创建您的加密机器人":"お客様の取引アカウントとAPI秘密鍵をユナイトしてあなたの暗号化ロボットを創造します",

    "交易机器人":"取引ロボット",
    "适用于每种市场趋势看涨、持平或看跌。":"各市場に適用します。強気、横ばい、弱気全部観測できます",
    
    "终端":"端末",
    "批量执行手动订单。":"注文を一括に実行します",
    
    "移动应用程序":"モバイルアプリ",
    "下载应用程序以随时随地管理您的交易机器人。":"アプリをダウンロードして随時あなたの取引ロボットを管理できます",
    "不错过任何市场趋势。":"市場の傾向を見逃しません",
    
    "交易信号":"トレード信号",
    "从布林线、价格警报、交易视图信号等中进行选择，以便交易机器人在正确的时间启动和停止。":"ボリンジャーバンド、プライスアラート、トレードビュー信号などから選択し、適切なタイミングで取引ロボットを開始・停止することができます。",
    "跟单交易":"ドキュメンタリー取引",
    "帮助您提高机器人的利润并学习其他交易者的策略。":"ロボットからの利益を向上させて他の連中の戦略を学ぶことを協力します",

    "试试看":"トライ",
    

    /***** 2 */
    "在顶级加密货币交易所运行交易机器人":"トップランクの暗号化貨幣取引所で取引ロボットを使用します",
    "将您的交易帐户与 API 密钥连接起来并立即创建您的加密机器人。":"お客様の取引アカウントとAPI秘密鍵をユナイトして暗号化ロボットを創造します",
    "接入账户":"アカウントにアクセスします",

    /***** 3 */
    "复制顶级交易机器人策略":"トップランクの取引ロボットの戦略をコピーします",
    "发现并复制交易市场上最好的交易机器人以增加您的利润":"市場でのさいこうの取引ロボットを発見してその戦略をコピーしてお客様の利益を増加します",
    "下载APP": 'のダウンロードAPP',

    /***** 3- */
    "策略收益排行榜":"戦略収益ランキング",
    "与优秀的策略交易员同行":"優れた戦略トレーダーとの同行",
    "总投入":"総投入",
    "总收益率":"総収益率",
    "账户权益":"アカウント持分",
    "持仓盈亏":"持倉損益",
    "总年化":"そうねんか",
    "交易笔数":"取引件数",
    "交易额":"取引高",
    "最大回撤":"最大ロールバック",
    "我要跟单":"私は注文します",
    "仓位阈值":"倉庫のしきい値",


    /***** 4 */
    "Vtrading是怎么运行的？":"ヴィートレンディンはどのように機能するのか",
    "将您的交易所与 API 密钥连接起来并创建机器人进行交易。":"お客様の取引所とAPI秘密鍵をユナイトしてロボットを創造して取引します",
    "您的资金绝对安全，API经过多重算法加密，安全性高！":"APIが多重のプログラムで暗号化されて、安全性が高いから、お客様の資金は絶対安全です。",
    "您可以通过API连接多个交易所，管理不同的资金账户。":"お客様がAPIを通して数多くの取引所とリンクして、違うアカウントの資金を管理できます",
    "更轻松地检查您的帐户或执行订单。":"お客様のアカウントと注文を楽に確認できます",
    "连接账户":"アカウントにアクセスします",

    /***** 5 */
    "随时随地进行交易":"随時に取引します",
    "扫描下载":"スキャンしてダウンロードします",
    
    /***** 6 */
    "复制其他交易者的策略": "他の取引先の戦略をコピーします",

    "订阅其他使用者的策略信号": "他の使用者の戦略信号を購読します",

    "无须配置参数": "設定パラメータ不要",
    "小白也能快速启动策略": "素人でもクイックスタートできます",
    
    "自己建立策略信号发起跟随": "独自の戦略信号を作成して、追随者を募る",
    "其他使用者可通过付费跟随您的策略": "他の使用者がお金を払ってお客様の戦略を購読できます",

    /***** 7 */
    "从2017年开始":"２０１７年をはじめに",
    "Vtrading和大家一起经历了熊市和牛市":"ヴィートレンディンはお客様たちと一緒に弱気市場と強気市場を経験した",
    "Vtrading和大家一起经历了熊市和牛市，从2017年开始":"２０１７年をはじめに、ヴィートレンディンはお客様たちと一緒に弱気市場と強気市場を経験した",
    "全球用户":"グローバルの使用者",
    "机器人数量":"ロボット数",
    "日交易量":"日取引量",

    /***** 8 */
    "关注您的利润增长":"お客様の利益増加に関心します",
    "从强大的交易机器人到智能订单工具，vtrading 机器人可以帮助您在加密世界中实现利润最大化":"賢い取引ロボットから知能的注文ツールまで、ヴィートレンディンロボットはお客様の暗号化世界で利益を最大化に協力します",
    "交易机器人":"取引ロボット",
    "借助全自动交易机器人，24/7 从每个市场变动中获利。":"全自動式取引ロボットで、２４時間を通じて市場から利益を獲得します",
    "在几分钟内设置 GRID 和 DCA 策略。":"数分でGRIDとDCA戦略を設定します",
    
    "智能订单":"知能化注文",
    "通过强大的订单功能执行您的交易策略。":"強い注文機能でお客様の取引戦略を実行します",
    "通过不同的功能控制您的风险或增加您的利润，如止损、止盈、追踪止盈、单轨等。":"いくつかの機能でお客様のリスク把握と利益増加のことでき、例えばロスストップとプロフィットストップだけでなく、それにそれぞれの追跡こともできます。",

    "信号终端":"信号端末",
    "信号终端可以更智能地运行交易机器人。":"信号端末は知能的に取引ロボットをコントロールできます",
    "无论是复制的策略还是自己创建的外部信号通过 TradingView ，机器人可以自动执行。":"戦略をコピーすることも、独自の外部信号がTradingView を通じることも、ロボットが自動実行できます。",

    /***** 9 */
    "设置机器人需要多长时间？":"ロボットを設定するにかかる時間は",
    "只需几分钟即可开始交易，只需通过API密钥连接到您的交易所，选择所需的交易货币并设置参数即可。":"数分でよろしいです。API秘密鍵でお客様の取引所にアクセスして、必要な取引貨幣を選択し数値を設定することでよろしいです。",
    "启动交易机器人需要多少钱？":"取引ロボットを起動にはいくらかかるか",
    "没有最低交易限制，但为了使策略良好运行，建议单个机器人投资超过 200 美元。":"最低限の取引額が必要ないが、戦略をよく動くためには、一つのロボットへの投資額が２００ドル―以上のほうがよろしいです。",
    "Vtrading可以在哪些类型的市场进行交易？":"ヴィートレンディンはどんなタイプの市場で動くか",
    "Vtrading 有现货和合约策略，以及网格机器人、DCA 机器人和其他类型的策略。 无论是牛市、熊市还是波动市场，您都可以选择不同的策略来执行交易。":"ヴィートレンディンには現品およびコントラクト戦略があり、ネットロボとDCAロボそれに他のタイプの戦略もあります。強気市場でも弱気市場でも不安定な市場でも、お客様は違う戦略を選択して取引します。",
    "使用 vtrading 机器人进行交易安全吗？":"ヴィートレンディンロボットを使って取引することは安全ですか",
    "Vtrading不存储您的资金，您的账户只能通过API密钥访问进行交易，并且API经过多重算法加密，绝对安全。 您可以完全控制您的资金。":"ヴィートレンディンはお客様のお金を保存せず、アカウントがAPI秘密鍵しかを通じて取引できず、それにAPIが多重のプログラムで暗号化されるから、絶対安全です。お客様は自分の資金を完全にコントロールできます",
    "我可以随时取款吗？":"いつでも貯金をとれますか",
    "Vtrading不存储您的资金，因此您可以随时终止您的策略并转移您的资金。":"ヴィートレンディンはお客様の貯金を持てないから、いつでも戦略を停止して資金を取れます。",
    
    /******** 10 */
    "公司主题曲":"会社のテーマソング",
    "我的人生要自己把握，Go with Vtrading to Victory。":"自分の人生は自分で決める！Go with Vtrading to Victory ",

    /******** 11 */
    "Vtrading数字资产":"ヴィートレンディンの数字財産",
    "Al量化":"AIクオンツします",
    "服务平台介绍":"サービスプラットフォームの紹介",
    "Vtrading数字资产Al量化服务平台介绍":"ヴィートレンディンの数字財産AIクオンツするサービスプラットフォームの紹介",
    "Vtrading成⽴于2017年12⽉，旨在为不同层次的客户提供差异化的量化交易策略服务。":"ヴィートレンディンは２０１７年１２月に成立されて、さまざまなレベルの顧客むけに差別化されたクオンツ取引戦略をサービスするに目標します。",
    "Vtrading数字资产AI量化服务平台提供可视化图元平台编辑策略、Hbase系统快速调试回测、自定义发布商城策略、资管系统、智能跟单系统、独⽴返佣系统、⼤客户私有定制部署、第三⽅嵌⼊式系统等功能及服务。":"ヴィートレンディンの数字財産AIクオンツするサービスプラットフォームがビジュアル・メタプラットフォームの編集戦略、Hbaseシステムの快速テスト、カスタムして商事戦略の配布、財産管理システム、知能式注文追跡システム、独立のバックマージン返すシステム、大手のお客様の私有オーダーメイド、第三者挿入システムおよびその他のサービスを提供します。",
    "用户可通过Vtrading提供的多种服务来实现不同的量化需求，让普通用户无需懂得编写代码也能运行量化策略，让量化交易变得更简单。":"使用者がヴィートレンディンを通じて多種のサービスを提供して、さまざまのクオンツ要求を満足し、素人がエンコーディングができないでもクオン戦略を使用して、クオンツ取引を簡単化にします。",


    /******** 底部 */
    "平台策略":"プラットフォーム戦略",
    "DCA策略":"ＤCA戦略",
    "网格策略":"ブロック戦略",
    "DCA合约策略":"DCAコントラクト戦略",
    "网格合约策略":"ブロックコントラクト戦略",
    "信号策略":"信号戦略",
    "资源":"リソース",
    "新手学院":"初心者学校",
    "新闻资讯":"ニュース",
    "联系我们":"連絡先",
    


    /************************ 网格策略 */
    "什么是网格交易？":"グリッド取引とは？",
    "网格交易是一种量化交易策略，即在震荡行情中通过一定的价格区间构造出一系列的买卖价位, 不断的执行低买高卖, 从而获得波段收益的一种交易方法。":"グリッド取引は定量的な取引戦略であり、振動相場の中で一定の価格帯を通じて一連の売買価格を構築し、絶えず低買高売を実行し、それによって帯域収益を得る取引方法である。",
    "具体来说，网格交易是以一个价格为基础，在该价格的上下划分多个区间或者网格，并在每条网格上预先设定相应的买入和卖出订单。":"具体的には、グリッド取引は1つの価格をもとに、その価格の上下に複数の区間またはグリッドを分割し、それぞれのグリッドに対応する購入と販売注文を予め設定する。",
    
    "网格交易适用于什么行情？":"グリッド取引はどのような相場に適用されますか？",
    "网格交易更合适在持续震荡的行情下交易获利。":"グリッド取引は、揺れ続ける相場で取引して利益を得るのに適している。",
    "但如果行情上涨，您可以通过调整网格策略的参数从而将利润最大化":"しかし、相場が上昇した場合は、グリッド戦略のパラメータを調整することで利益を最大化することができます",

    "Vtrading 网格机器人的优势":"Vtradingメッシュロボットの利点",
    "Vtrading平台的网格机器人兼顾各种行情，无论是上涨、下跌还是震荡都可以获利。":"Vtradingプラットフォームのグリッドロボットは、上昇、下落、振動にかかわらず利益を得ることができます。",
    "下跌行情使用币本位机器人套利赚币，上涨行情增加资金投入，震荡行情又可以调整网格区间":"下落相場は貨幣本位ロボットを用いて利益を得、上昇相場は資金投入を増やし、相場を揺るがすことでグリッド区間を調整することができる",
    "灵活":"柔軟性",
    "网格参数随用随改，及时生效":"グリッドパラメータは使用に応じて変更され、適時に発効する",
    
    "持续稳定":"じぞくあんてい",
    "网格区间灵活调整，从容面对上涨或下跌行情":"グリッド区間は柔軟に調整し、上昇や下落相場に余裕を持って臨む",
    
    "风险可控":"リスク制御可能",
    "通过参数来调整限制订单数量，从而应对突发行情，对于资金仓位的管控有非常好的帮助":"パラメータによって注文数を調整し、突発相場に対応することは、資金倉庫の管理に非常に良い助けになる",


    "观看网格讲解视频":"グリッド解説ビデオを見る",
    "播放":"再生",

    "如何启动网格交易策略？":"グリッド取引戦略を開始する方法？",
    "通过API链接您的交易所":"API経由で取引所をリンク",
    "选择网格策略":"メッシュポリシーの選択",
    "选择模式开启策略":"モードオンポリシーの選択",


    
    /************************ 合约网格策略 */
    "期货网格交易机器人":"先物グリッド取引ロボット",
    "降低您的风险，增强您的交易盈利":"リスクを軽減し、取引収益を向上",
    "期货市场波动比较大、交易风险高，如果我们使用网格交易机器人在期货市场交易，可以利用杠杆放大收益，通过设定的网格区间可以持续交易获利，交易产生的利润又可以补充保证金，从而降低期货账户的风险":"先物市場は変動が大きく、取引リスクが高い。もし我々がグリッド取引ロボットを用いて先物市場で取引すれば、レバレッジを利用して収益を拡大することができ、設定されたグリッド区間を通じて利益を取引し続けることができ、取引による利益はまた保証金を補充することができ、それによって先物口座のリスクを下げることができる",
    "立即交易":"すぐ取引します",

    "利用加密货币期货交易获利":"暗号化通貨先物取引で利益を得る",
    "以更低的保证金进行更高的交易质量":"より低い保証金でより高い取引品質を実現",
    "交易杠杆高达 X10":"取引レバレッジはX 10まで",
    "灵活选择多空交易":"複数のトランザクションを柔軟に選択",

    "选择您的策略":"ポリシーの選択",
    "无论市场是下跌还是上涨，都可以通过 Vtrading 的网格期货加密交易机器人增强您的交易游戏并实现您的目标。":"市場が下落しても上昇しても、Vtradingのメッシュ先物暗号化トランザクションロボットによってトランザクションゲームを強化し、目標を達成することができます。",
    "当市场上涨时":"市場が上昇すると",
    "增强利润":"利益の向上",
    "网格策略期货多头":"グリッド戦略先物多頭",
    "通过不间断交易来积累利润，从而增强收益。":"無停止取引で利益を積み上げ、収益を高める。",
    
    "当市场下跌时":"市場が下落したとき",
    "赚取加密货币":"暗号化通貨の取得",
    "网格策略期货空头":"メッシュ戦略先物ヘッダー",
    "通过不断高卖低买的形式，持续追踪下跌市场交易获利。":"絶えず高く売って低く買う形式を通じて、下落した市場取引の利益を追跡し続けている。",
    
    "只需三步就可以实现网格期货自动化交易":"3ステップでメッシュ先物自動取引を実現",
    "选择期货网格策略":"先物グリッドポリシーの選択",
    "选择策略方向并启动策略":"ポリシーの方向を選択し、ポリシーを開始",

    
    /************************ DAC合约网格策略 */
    "什么是DCA策略？":"DCAポリシーとは？",
    "DCA是一种美元平均成本策略，通过分批买入建仓从而降低买入成本，同时将DCA的策略运用到期货机器人上更能有效的控制风险，借助期货的杠杆特性有效的利用资金放大账户收益。":"DCAはドル平均コスト戦略であり、分割購入による倉庫建設により購入コストを削減するとともに、DCAの戦略を先物ロボットに運用することでリスクをより効果的に制御し、先物のレバレッジ特性を利用して資金を有効に利用して口座収益を拡大する。",
    
    "选择您的策略":"ポリシーの選択",
    "无论市场是下跌还是上涨，都可以通过 Vtrading 的DCA期货加密交易机器人增强您的交易游戏并实现您的目标。":"市場が下落しても上昇しても、VtradingのDCA先物暗号化取引ロボットによって取引ゲームを強化し、目標を達成することができます。",
    
    "利润增强":"利益の増加",
    "当价格上涨时":"価格が上昇すると",
    "DCA策略期货多头":"DCA戦略先物多頭",
    "将您的资金分批投入以获得更好的平均价格。 最大化您的回报并最小化波动影响。":"より良い平均価格を得るために、資金を分割して投入します。リターンを最大化し、変動の影響を最小化します。",
    
    "赚取加密货币":"暗号化通貨の取得",
    "当市场下跌时":"市場が下落したとき",
    "DCA期货空头":"DCA先物ヘッダー",
    "分批建立空头头寸，从而控制市场风险，避免账户持仓均价建立在行情底部，同时追踪下跌行情来交易获利。":"いくつかのバッチで空頭頭頭寸を構築し、それによって市場リスクを制御し、口座持倉の平均価格が相場の底に構築されるのを回避し、同時に下落相場を追跡して取引して利益を得る。",
    

    "DCA策略+信号触发组合机器人":"DCAポリシー+シグナルトリガコンビネーションロボット",
    "最大化您的交易利润":"取引利益の最大化",
    "通过Vtrading提供的信号触发功能，可以设置价格突破或跌破策略，也可以自定义设置 TradingView 启动和停止信号，以帮助交易者寻找更好的入场时机，从而增强策略的盈利能力":"Vtradingが提供するシグナルトリガ機能により、価格ブレークスルーまたはブレークダウンポリシーを設定することができます。また、トレーダーがより良い入場機会を見つけるのを支援するために、トレーダーが戦略の収益性を高めるために、TradingViewの起動と停止信号をカスタマイズすることもできます。",

    
    "只需 5个简单步骤即可启动 DCA 合约策略":"DCA契約ポリシーを開始するための簡単な5つのステップ",
    "通过 Vtrading 开始交易，释放您的交易潜力":"Vtradingによる取引開始により、取引の可能性を解放",
    "通过API连接您的交易所":"APIで取引所に接続",
    "选择DCA期货机器人":"DCA先物ロボットの選択",
    "选择交易方向并启动策略":"取引の方向を選択し、ポリシーを開始",
    "选交易标的":"トランザクションターゲットの選択",
    "运行机器人并观察其盈利":"ロボットを動かして利益を観察する",


    
    /************************ 下载 */
    "download_one_1":"Trade like a pro with Vtrading",
    "download_one_2":"bot",
    "download_one_3":"Make crypto trading easier",
    "download_one_but_1":"Download App",
    "download_one_but_2":"Download Android version",

    "download_two_1":"Watch Your Profits Grow",
    "download_two_2":"From powerful trading bots to smart orders tools",
    "download_two_3":"Just need one app",

    "download_three_1":"Automated crypto  trading bot without coding",
    "download_three_2":"Trade crypto like a pro with our crypto trading platform on your Mobile terminal.",
    "download_three_3":"Help you improve bot’ s profits and learn from other traders‘ strategies.",

    "download_four_1":"One terminal manages accounts on multiple exchanges",
    "download_four_2":"Connect to the exchange and manage accounts via API, automatically run strategies, and monitor profit trends anytime and anywhere...",

    "download_five_1":"Maximize your profits",
    "download_five_2":"Unlock your trading potential.",
    "download_five_but_1":"Download App",



    
    

    "开始交易": 'Start now',
    "查看详情": 'Find more details',
    "牛市行情如何盈利？": 'How to achieve profit in a bull market?',
    "美元平均成本": 'DCA (Dollar-COST Averaging)',
    "使用DCA策略，可以逐步降低买入成本，等待行情反弹从而获利。": 'Lower the cost gradually and take profit until the market rebound',
    "通过分批买入、分批卖出的形式不断在波动中套利": 'Continuously arbitrage profits from fluctuations by buying and selling in batches ',
    "信号触发器": 'Signal trigger',
    "通过TradingView等外部警报信号，设置触发器，从而提前对熊市的早期迹象做出策略调整自动批量触发信号，执行止盈、止损、暂停、清仓等操作": 'Set the trigger based on the external alarm signal such as TradingView   to make decisions accordingly to the early signs of the bear market.Trigger the signals automatically to execute actions of take-profit, stop-loss, pause, clearance, etc.',
    "创建机器人": 'Created Bot',
    "使用Vtrading交易机器人，让获利更简单": 'Trading is hard ,but Vtrading makes it easier',
    "无论是上涨或者下跌行情，都可以通过Vtrading机器人获得利润，我们需要做的就是设定目标和调整策略，让机器人更智能化运行以达成目标。": 'Win the profit with Vtrading trading bots regardless of the market change. Just set the goal and adjust the strategy, allowing the bots to run more intellectually to achieve your target',
    "7*24小时自动执行交易": '7*24 auto trading',
    "机器人会按照设定的参数执行交易，在震荡市场下产生的收益远远超出预期": 'Vtrading bots will execute trading with preset parameters, generating more revenue than expected in the fluctuated market',
    "多重策略可供选择，无障碍穿越牛熊": 'Multiple strategies are available, barrier-free crossing of bulls and bears',
    "丰富的策略类型可为不同的市场行情提供多样的选择，无论是网格策略、趋势策略、还是合约策略，您都可用通过Vtrading建立策略模板执行自动化交易": 'A variety of strategies adapt to different market situations. No matter grid strategy, trend strategy, or future strategy, create a strategy template with Trading to perform the auto trading',
    "学会掌控市场": 'Learn how to master the market',
    "使用合理的策略，设置稳定的参数来建立您的加密货币投资组合，以达到交易利润最大化，通过策略及其参数控制风险，最小化您的交易损失": 'Create a investment combo with a smart strategy to achieve profit maximazation—control risk through strategy and parameters to minimize your loss',
    "如何启动量化机器人": 'How to start the quantitative trading bot ',
    "通过API密钥连接交易所并创建机器人执行下单交易": 'Connect to exchange through API key and create bots to perform trading.',
    "您的资金仍然在交易所，相比于将资金直接转入量化平台，使用API链接将更加安全可靠。并且通过API可以一键管理您的多个平台账户资产，查看账户持仓或执行头寸交易更加便捷。": 'Your funds are still kept in exchange. API key connection is safer compared to transferring funds to the platform directly.Multiple funds account in different exchanges can be managed with API connections, making checking holding positions and trade positions easier. ',
    "以下交易所支持使用Vtrading自动交易工具": 'Exchanges that support Vtrading auto bots',
    "币安交易所":'Binance',
    "OKX交易所":'OKX',
    "HTX交易所":'HTX',
    


    "连接交易所": 'Connect exchange',
    "敬请期待": 'Coming soon…',

    
    "APP扫码下载": "App code",

    // ****************
    "语言": 'language',


    "简体中文": '简体中文',
    "英文": 'English',

    "聚合账户": 'Aggregate Account',
    "总资产折合": 'Estimated Total Value',
    "今日收益": "Today's Return",
    "近7天累计收益": 'Total benefits of past 7d',
    "我的机器人": 'My Bot',
    "总收益": 'Total Benefit',

    "查看更多": 'More',

    "我的交易所": 'My Exchanges',
    "资产折合": 'Estimated Value',

    "切换主题": 'Theme',
    "交易所": 'Exchange',
    "担保资产率": 'Margin Ratio',
    "请选择交易所": 'Choose Exchange',
    "退出登录": 'Sign out',
    "请输入": 'Please enter',
    "计价单位": 'Currency',
    "连接": 'Connect',
    "开仓均价": 'Entry Price',
    "预估强平价": 'Est. Liquidation Price',
    "去": 'go',
    "聚合账户详情": 'Aggregate Account Details',
    "账户详情": 'Account Details',
    "统计数据": 'Statistical data',
    "资产走势": 'Asset Trend',
    "日期范围": 'Dates range',
    "累计收益": 'Benefits',
    "请选择日期范围": 'Please select date range',
    "每日收益": 'Daily Benefit',
    "币币账户": 'Exchange',
    "合约账户": 'Derivatives',
    "搜索": 'search',
    "币种": 'Tokens',
    "估值": 'Estimation',
    "价格变化": 'Change（24h）',
    "数量": 'Volume',
    "合计": 'Total',
    "名称": 'Name',
    "全部": 'All',
    "启用": 'Enabled',
    "关闭": 'Close',
    "做多": 'Long',
    "做空": 'Short',
    "双向": 'Two Side',
    "持仓量": 'Avail. | Total',
    "创建DCA机器人": 'Create DCA bot',
    "同步资产": 'Refresh',
    "账户类型": 'Account Type',
    "筛选": 'Filter',
    "清除筛选": 'Clear Filters',
    "机器人名称": 'Bot Name',
    "执行中": 'Running',
    "已终止": 'Terminated',
    "参数": 'Parameter',
    "启动": 'Start',
    "重启": 'Restart',
    "复制": 'Copy',
    "详情": 'Details',
    "终止": 'Abort',
    "币对": 'Contracts',
    // "U本位合约账户": 'USDT-M Futures Wallet',
    "U本位合约账户": 'USDT-M Futures Account',
    "交易方向": 'TradeDirection',
    "方向": 'Side',
    "保证金模式": 'MarginMode',
    "全仓": 'Cross',
    "逐仓": 'Isolated',
    "杠杆": 'Leverage',
    "持仓止盈": 'TakeProfit',
    "止盈平仓模式": 'Take profit mode',
    "一次性止盈": 'Take profit',
    "逐单止盈": 'Take profit inbatches',
    "止盈下单模式": 'TakeProfitMode',
    "追踪止盈": 'TrailingTP',
    "预埋单止盈": 'LimitOrderTP',
    "止盈阈值": 'TPThreshold',
    "止盈回撤比例": 'TPDevintion',
    "持仓止损": 'StopLoss',
    "止损阈值": 'StopLossThreshold',
    "止损后的操作": 'ExecutionAfterStopLoss',
    "终止机器人": 'TerminateBot',
    "继续交易": 'ContinueTrading',
    "高级设置": 'AdvancedSettings',
    "最低价": 'MinPrice',
    "最高价": 'MaxPrice',
    "开仓等待时长": 'CooldownBetweenDeals',
    "总收益止盈": 'TakeProfit',
    "总收益止损": 'StopLoss',
    "终止时自动撤销委托": 'AutoTermination',
    "终止时自动平仓": 'AutoCloseout',
    "账户资产": 'Balance',
    "可用权益": 'AvailableFunds',
    "划转": 'Transfer',
    "建议投入": 'RecommendFunds',
    "当前最小须投入": 'MinFunds',
    "账户已有策略，暂不可建": 'account has a full-position bot running, failed to create new orders',
    "执行时长": 'execution time',
    "交易": 'Trading Bots',
    "邀请好友": 'Invite Friends',
    "帮助": 'Help',
    "首页": 'Home',
    "DCA机器人": 'DCA Bot',
    "机器人列表": 'Bot list',
    "活跃实例": 'Active Instances',
    "历史": 'History',
    "未创建机器人": 'You have not created to an bot,click on the bottom of the button to create...',
    "连接交易所开始交易": 'Go connect to the exchange and start trading',
    "开始日期": 'startTime',
    "结束日期": 'endTim',
    "请输入机器人名称": 'Please enter bot name',
    "请输入交易对": 'Please enter pairs',
    "请勾选用户服务协议 ": 'Please select user Service Protocol ',
    "未启用": 'Disabled',
    "暂停": 'Paused',
    "启动中": 'Starting',
    "启动失败": 'Fail to start',
    "终止中": 'Terminating',
    "首单投入": 'BaseOrder',
    "补仓间隔比例": 'AddFundsSpaceRatio',
    "补仓反弹比例": 'TrailingBuyRatio',
    "最大做多单数": 'MaxLongOrder',
    "最大做空单数": 'MaxShortOrders',
    "请登录后再试": 'Please login and try again',
    // "天": 'days',
    // "时": 'hours',
    // "分": 'minutes',
    "天": 'd',
    "时": 'h',
    "分": 'm',
    "实例": 'Instances',
    "交易对": 'pairs',
    "盈亏": 'Profit and Loss',
    "状态": 'state',
    "未实现": 'Unrealized',
    "止盈终止": "Close at TP",
    "止损终止": "Close at SL",
    "自动终止": 'Close at Auto',
    "定时终止": "Close at Timed",
    "手动终止": "Close at Manual",
    "强制终止": "Close at Force",
    "成交记录": 'TradeHistory',
    "暂无实例": 'No data',
    "暂无数据": 'Empty Data',
    "开多": "Open long",
    "开空": "Open short",
    "平多": "Close long",
    "平空": "Close short",
    "委托数量": "Size",
    "委托价格": "Price",
    "收益": "Profits",
    "删除": 'Delete',
    "成交时间": 'Time',
    "持仓数量": 'Avail. | Total',
    "开仓均价": 'Open Price',
    "强平价": "Liq.Price",
    "补仓追踪": 'Add Funds Trailing',
    "当前委托": 'OpenOrders',
    "当前做单数": "SafetyOrders",
    "加仓": 'Add Funds',
    "市价平仓": 'Close All Positions',
    "邀请好友": 'Invite friend',
    "邀请链接": "Invite link",
    "访问": 'Visitors',
    "邀请": "Invite",
    "邀请记录": "Invite record",
    "邀请时间": "Time",
    "账户设置": "Account Settings",
    "昵称": 'NickName',
    "头像": 'Avatar',
    "绑定": 'Bound',
    "安全设置": 'Safety Settings',
    "修改": "Change",
    "谷歌验证器": "Google Authenticator",
    "未绑定": 'Unbound',
    "已绑定": "Bound",
    "换绑": "Change",
    "登录验证": 'Login Authentication​',
    "账号绑定": "Bind on Account",
    "登录设备": 'Login Device',
    "当前设备": 'Current device',
    "请输入名称": 'Please enter name',
    "请输入API key": 'Please enter Api Key',
    "请输入API Secret": 'Please enter Api Secret',
    "我已阅读并同意服务协议": 'I have read and agree to the [User Service Agreement]',

    "请输入币种": 'Enter Trading Tokens',
    "当前做单": 'Current Order',
    "加载中": 'Loading',
    "操作": 'Action',
    "成交数量": 'Closed Size',
    "成交价格": 'Closed Price',
    "时间": 'Time',
    "请输入划转数量": 'Enter Transfer Amount',
    "撤销": 'Revoke',
    "请选择": 'Choose',
    "到": 'to',
    "从": 'From',
    "可划转": 'Available for transfer',
    "确定": 'Confirm',
    "取消": 'Cancel',
    "复制密钥": 'Copy secret key ',
    "密钥": 'Secret key',
    "现货账户": 'Spot Account',
    "合约账户-U本位合约": 'Futures Account-USDT-M',
    "委托方向": 'Direction',
    "委托类型": 'Type',
    "市价委托": 'Market price',
    "解除绑定": 'Unbind',
    "交易账户": 'Trade account',
    "资金账户": 'Funding account',
    "切换验证方式": 'Swich',
    "刷新二维码": 'Refresh QR code',
    "打开 VtradingAPP 扫一扫登录": 'Open the Vtrading App and scan the code to login',
    "现在开始": 'Enter',
    "撤销中": 'Canceling',
    "持仓": 'Take  position',
    "持仓收益": 'Position income ',
    "来源": 'Source',
    "手动操作": 'Manual operation',
    "机器人表现": "Bot's performance",
    "限价委托": 'Limit',
    "确定要删除当前机器人": 'Are you sure you want to delete the current bot',
    "请输入价格": 'Enter a price',
    "可用": 'Available',
    "金额": 'Amount',
    "手动加仓": 'Manual',
    "是否解除绑定Vtrading账户": 'Are you sureyou want to unbind Vtrading account',
    "解绑": 'Unlink',
    "验证": 'verification',
    "设备名": 'Device',
    "最近登录时间": 'Recent login',
    "请输入正确的数量": 'Enter the correcct amount',
    "金额不能大于可用金额": 'Amount must less than available amount',
    "金额不能小于": '金额不能小于',
    "重复新邮箱": 'Enter new mail  again ',
    "请输入新邮箱": 'Enter new mail',
    "请输入新密码": 'Enter new password',
    "更新成功": 'Updated successfully',
    "再次输入新密码": 'Enter new password again',
    "请再次输入新密码": 'Enter new password again',
    "两次密码输入不一致": 'Inconsistent password',
    "请再次输入新邮箱": 'Enter new email address again',
    "两次邮箱输入不一致": 'Inconsistent  email',
    
    "删除成功": 'Delete Successful',
    "启动成功": 'Start  Successfully',
    "重启成功": 'Restart  Successfully',
    "终止成功": 'Terminate  Successfully',
    "暂停成功": 'Pause Successfully',
    "不能为空": "can't be blank",
    "倍": 'X',
    "单": 'Size',
    "绑定成功": 'Binding  successed',
    "请输入百分比": 'Enter a percentage',
    "机器人触发价": 'Trigger price',
    "每日利润": 'Daily profit',
    "请输入数量": 'Enter the quantity',
    "开启交易新世界": 'OPEN UP A NEW WORLD OF TRADING',
    "启动机器人": 'Confirm',
    "确定要启动当前机器人": 'Click to confirm starting current bot',
    "机器人创建成功": 'Bot Create Successful',
    "是否开启机器人": 'Confirm to Start Bot',
    "最大初始保证金": 'Maximun inicial margin',
    "IP地址": 'IP Address',
    "OPEN UP A NEW WORLD OF TRADING": '开启交易新世界',
    "智 能 交 易 / 就 选 Vtrading": 'Start your smart trading / today with Vtrading',
    "保存": 'Save',
    "编辑交易所": 'Link API',
    "编辑": 'Edit',
    "执行实例": 'Runing orders',
    "完成的实例数": 'Finished orders',
    "活跃的实例数": 'Active orders',
    "当前初始保证金占用": 'Inicial margin occupation',
    "活跃": 'Active',
    "确定要终止当前机器人": 'Are you sure you  want to  terminate the current  bot',
    "确定要删除当前账户": 'Are you sure you want to delete the current account',
    "数据": 'Data',
    "信息": 'info',
    "当前最小需投入": 'Minimum',
    "未设置昵称": 'Set  nickname',
    "编辑机器人": 'Choose stratge bot',
    "机器人详情": 'Bot Details',
    "新邮箱": 'New email',
    "刷新成功": 'Refresh succeeded',
    "产品": 'Products',
    "社交": 'Community',
    "U本位合约DCA": 'USDT-M DCA',
    "提示": 'Tips',
    "请输入1-16位昵称": 'Nickname must be  between  1-16 characters long',
    "请输入昵称": 'Set a  nickname',
    "绑定邀请码": 'Bind invitation code',
    "邀请码仅在注册后15天内可绑定, 且绑定后不可修改, 请确认填写的邀请码是否正确": 'The invitation code can only bound within 15 days after regestration,and cannnot be modified after binding,please confirm wether the invitation code is correct',
    "安全验证成功": 'Securite verification succeeded',
    "连接成功,为了您的交易安全,已自动开启登录验证": 'conection succeeded, for the security ,login verification has been turned  on ',
    "绑定谷歌验证器": 'Bind  google authenticator',
    "邮箱验证未完成，请先完成邮箱验证": 'Verify by email first',
    "账户未验证邮箱/绑定GA，请先验证/绑定": 'Your account is not bound to GA,please bind it first',







    "邮箱绑定": 'Email binding',
    "等待开仓": 'Open position waiting ',
    "平仓中": 'Closing',
    "做多开仓中": 'Exeuting a long position',
    "做空开仓中": 'Executing a short position',
    "划转成功": 'Successful transfer',
    "划转不能大于可划转金额": 'Amount must less than available amount',
    "划转需要大于1": 'Amount must be greater than 1',







    "权益": 'Equity',
    "可用担保资产": 'Avail. Margin',
    "最小值": 'min',





    "考虑到衍生品本身风险及机器人计算性能,单个品种最多可同时执行一个全仓、一个做多逐仓、一个做空逐仓机器人;": 'Considering the derevetives and performence of the bot,one coin pair can execute at most one cross  position ,one long isolate position bot , and one short isolate position bot at the same time ',
    "您可以灵活的在机器人实例、智能交易及交易所APP中手动干预仓位,因盈亏数据由交易所提供,此类操作将影响机器人盈亏。": 'You can mannually adjust positions between bot cases,automatic case or  Exchage case,this action will affects the profit and loss of bot cases.',









    "安全验证成功,为了您的交易安全,已自动开启登录验证": "Securite verification succeeded,login verification has been turned on ",
    "设置": 'Settings',
    "下载谷歌验证器": 'Download google authenticator',
    "下载": 'Download',
    "用户登录": 'User login',
    "安卓用户登录应用商店,或使用手机浏览器搜索下载": 'Android users could log in to appstore,or use the mobile browser to search and download',
    "在谷歌验证器中添加密钥并备份": 'Add key in google authenticator and backup',
    "打开谷歌验证器,扫描下方二维码或手动输入下述密钥添加验证令牌。": 'Open google authenticator  scan the QR code blow or manually enter the following key to add a verification  token ',
    "在谷歌验证器中获取验证码并输入": 'Get the verification code in google authenticator and enter it.',
    "U本位合约DCA使用教程": 'The tutorials course of DCA at USD-margine future',
    "验证码": 'code',
    "修改昵称": 'Change nickname',
    "验证": 'verification',
    "为了您的账号安全，请进行安全验证": 'For the security of your account, please verify your security',
    "副本": 'copy',


    "我的账户": "My Account",
    "添加账户": 'Add Account',
    "启用双重认证": 'Turn on  two-factor authentication',

    "操作成功": 'Operation successfully',
    "复制成功": 'Copy successfully',
    "解绑成功": "Unbind successfully",
    "GA验证": 'Google Authenticator',

    "确定删除": 'Confirm Detele',
    "请输入API名称": 'Enter api name',
    "删除账户": 'Delete account',
    "你还没有连接到交易所": 'You have not connected to an exchange',
    "录入成功": 'Connection succeeded',
    "补仓金额倍数": 'Add Funds ratio',
    "倍投起始单": 'Starting order multiplied',
    "补仓金额增量": 'Fixed Add Funds',
    "补仓间隔比例": 'Add Funds space Ratio',
    "补仓增幅": 'Add Funds Amplification',
    "补仓反弹比例": 'Trailing buy ratio',
    "最大做多单数": 'Max Long Order',
    "最大做空单数": 'Max Short Orders',
    "参数出错": 'Parameters Error',
    "调整杠杆": 'Adjust Leverage',
    "暂不支持": 'Not currently supported',
    "删除机器人": 'Delete bot',
    "退出成功": 'Exit successfully',
    "请勾选用户服务协议": 'Please select user Service Protocol',
    "多": 'Long',
    "空": 'short',
    "总盈亏": 'Total profit and loss',









    'tradeOffset': 'tradeOffset',
    'scaleIndex': 'scaleIndex',
    'openWaitTime': 'openWaitTime',
    'openSpaceAdd': 'openSpaceAdd',
    'amount': 'amount',
    'amountModel': 'amountModel',
    'amountScale': 'amountScale',
    'closeLossAfter': 'closeLossAfter',
    'closeLossModel': 'closeLossModel',
    'closeLossThreshold': 'closeLossThreshold',
    'closeModel': 'closeModel',
    'closeRebound': 'closeRebound',
    'closeThreshold': 'closeThreshold',
    'maxLongCount': 'maxLongCount',
    'openModel': 'openModel',
    'openRebound': 'openRebound',
    'openSpace': 'openSpace',
    'openSpaceAdd': 'openSpaceAdd',
    'openWaitTime': 'openWaitTime',
    'scaleIndex': 'scaleIndex',
    'tradeOffset': 'tradeOffset',
    'maxShortCount': 'maxShortCount',
    "不能绑定自己的邀请码": 'You cannot bind your own invitation code',

    "您录入的API与当前API来自不同账户，确认录入将创建新的连接": 'The API you entered is from another account ,confirming the entry will create a new link',


    "您录入的API与名称为": 'The API named',
    "的API来自同一账户": 'as same account as your entered',
    "确认录入将更新原有API?": 'comforming will update the original API?',
    "未知API，确认导入可能会导致运行中的交易错误": 'API is not recoglized,Importing may result transaction errors',
    "今日资产变动": 'Assets Today',
    "近7天资产变动": 'Assets in last 7 days',
    "总资产变动": 'Total Assets',
    "年": 'Year',
    "月": 'Month',
    "日": 'Day',

    "交易账户-现货": 'Trade account-spot',
    "交易账户-合约": 'Trade account-future',
    "网格机器人": 'Grid Bot',
    "创建网格机器人": 'Create a grid bot',
    "如何开始交易": 'How to start trading',
    "活跃机器人": 'Active Bot',
    "查看教程": 'Check the tutorial',
    "U本位合约DCA机器人，可自由切换补仓模式": 'Future DCA bot (golden standard)，freely switch adding modes',
    "U本位合约网格机器人，网格交易的合约版本": 'Future grid bot (golden standard), future version of grid trading',

    "帮助中心": 'Help Center',
    "在线客服": 'Online service',
    "新手引导": 'Beginner Guide',
    "开启交易前你需要将你的交易账户连接至Vtrading": 'Connect your account to Vtrading before you start',
    "账号信息可以修改账户信息，安全设置，登录设备": 'Modify account info, security setting and login devices',
    "修改账号信息": 'Modify account info',
    "API已过期,请重新录入": 'The API key is expired, please try with new one',
    "接管账户仓位": 'Take over positions',
    "立即开启": 'Start immediately',
    "稍后再说": 'Talk later',
    "是否立即开启机器人": 'Start the bot?',
    "上传头像图片大小不能超过 2MB": 'Images must be 2MB or less',
    "上传头像图片只能是 JPG、PNG、JPEG 格式": 'Images must be .jpg or .jpeg format',
    "U本位合约网格": 'Golden-standard future grid',
    "U本位合约网格-m": 'USD-M future Grid',
    "网格": 'Grid',
    "网格间隔": 'Grid Interval',
    "开仓上下限": 'Upper/Lower price',
    "取消修改": 'Cancle Modification',
    "复制参数到自定义": 'Copy to custom',
    "机器人仓位及盈亏计算规则：": 'Calculation rules of bot position and P&L',

    "正在启动": 'Turning on',
    "运行中": 'Running',
    "暂停中": 'Pausing',
    "正在终止": 'Terminating',
    "请先进行邮箱验证,再绑定邀请码": 'Please verify  your email adrress befor binding the invitation code',
    "做多等待开仓": 'Waiting  for a long  position to open',
    "做空等待开仓": 'Waiting  for a short position to open',
    "做多平仓中": 'Closing the long',
    "做空平仓中": 'Closing the short ',
    "做多追踪止盈": 'Long direction Trailing TP',
    "做空追踪止盈": ' Short direction Trailing TP',
    "实例已终止": 'Active orders terminated',
    "欢迎使用": 'Welcome',
    "编辑DCA机器人": 'Choose stratge DCA bot',
    "最大做空网格数": 'Max grids in short direction',
    "最大做多网格数": 'Max grids in long direction',

    "帮助": 'Help',
    "价格": 'Price',
    "市场价": 'Market price',

    "保证金比率": 'Margine ratio',
    "请选择账户": 'Select account',
    "请选择交易对": 'Select trading pair',
    "机器人名称长度限制10个字符": 'Robot name length is limited to 10 characters',
    "请输入最大初始保证金": 'Enter a max initial margine',

    "U本位合约DCA机器人，可自由切换补仓模式，止盈形式也更加灵活": 'The DCA bot witn  usd-m future can freely switch  the adding mode ,and  take profit more flexible',
    "U本位合约网格机器人，网格交易的合约版本，放大本金，分仓套利": 'Grid bot with USD-M future,the future version of grid trading, enlarge the principle,split positions and arbitrage',
    "保证金率": 'Margine ratio',
    "因交易所合并计算仓位成本，此类操作将影响到机器人的仓位成本，进而影响到止盈止损的基准价和机器人的盈亏计算。": 'The exchange caculate the position cost in a consolidated manner will affect the position cost of the bot which inturn affects the benchmark price oftake profit an d stop loss and the calculation of the profit and stop loss of the bot',
    "您可以灵活的在机器人实例、智能交易及交易所APP中手动干预仓位，": "You can flexibly manually intervene positions in bot's instance, intelligente trading and Exchang APPs.",
    "不能为零": 'Cannot be 0',
    "单格投入": 'Input per grid',
    "请确认修改的内容": 'Please confirm the modified content',
    "提交修改": 'Submit changes',
    "实例仓位": 'Running position',
    "量化托管": 'Quantitative account custody',
    "终止上下限": 'Terminate upper and lower limits',
    "网格机器人委托工具": 'Grid bot delegate tool',
    "网格委托工具使用手册": 'Grid toll user manual',
    "复位": 'Reset',
    "价格下限": 'Lower price',
    "价格上限": 'Upper price',
    "热门问题": 'Top questions',
    "支付问题": 'About payment',
    "消费问题": 'About consumption',
    "账户问题": 'About account',
    "请输入关键词搜索": 'Enter a keyword to search',
    "搜索结果": 'Result',
    "未生效": 'Not active',
    "此版本暂不支持直接编辑": "This version doesn't support edit",
    "永续": 'Perpetual',
    "退出": 'Sign out',
    "委托信息": 'Orders imformation',
    "相关文章": 'Related articles',
    "抱歉，没有找到相关内容": 'Sorry nothing found',
    "上限应大于下限": 'The upper limit should be greater than the lower limit',
    "最低价终止": 'Termination of lowest price',
    "最高价终止": 'Termination of highest price',
    "网格相关教程": 'How it works',
  }
}

export default en
