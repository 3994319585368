const en = {
  m: {
    "langs":'En',

    

    "新手必读":"조보 필독", 
    "区块链资讯":"블록체인 정보", 
    "策略视频":"전략 비도오", 
    "暂未开放":"미개방", 

    "个人中心":"개인 센터",
    "交易策略":"거래 전략",
    "合约":"계약",
    "定价":"정가",

    "账户": "계정",
    "请输入邮箱": "메일을 입력하십시오.",
    "请输入账户": "계정을 입력하십시오.",
    "密码": "비밀번호",
    "还没有账户": "미등록",
    "其他登录方式": "다른 로그인 방식",

    "账号": "아이디",
    "请输入密码,字母加数字组合6-32位": "문자 + 숫자의 6-32자인 비밀번호 입력하십시오.",

    "重置密码": "비밀번호 재설정",
    "返回账号登录": "아이디 로그인으로 돌아가기",
    "邮箱": "메일",
    "请输入邮箱地址": "메일 입력하십시오.",
    "返回上一步": "뒤로 돌아가기",
    "已发送": "전송됨",
    "返回登录": "로그인으로 돌아가기",
    "新密码": "새암호",
    "重复新密码": "새암호 확인",
    "密码必须包含字母和数字，长度6-32位": "암호 필수 조건는 문자+숫자 6-32자입니다.",
    "两次输入密码不一致":"입력한 암호가 일치하지 않습니다.",
    "验证成功": "인증 성공",
    
    "邀请码": "초대코드",


    /***** 登录注册 */
    "扫码登录": '스캔 로그인',
    "邮箱登录": '메일박스 로그인',
    "二维码有效期": 'QR코드 유효기간',
    "二维码过期": 'QR코드 만료',
    "重新获取": '다시 가져오기',
    "打开VtradingAPP扫一扫登录": 'VtradingAPP를 열고 로그인 스캔',
    "没有账号": '계정 없음',
    "立即注册": '지금 등록',
    "请输入邮箱": '이메일 번호를 입력하십시오.',
    "请输入密码": "비밀번호를 입력하십시오.",
    "忘记密码": "비밀번호 찾기",
    "登录": "로그인",
    "安全验证": "보안인증",
    "谷歌验证": '구글 검증',
    "邮箱验证": '메일박스 인증',
    "请输入谷歌验证码": "구글 인증번호를 입력하십시오.",
    "请输入邮箱验证码": "메일 인증코드 입력하십시오.",
    "获取验证码": "발송",
    "提交": "제출",
    "登录成功": '로그인 성공',
    "发送成功": '발송 성공',
    "修改成功": "수정 성공",
    "邮箱格式不正确": "메일 메일박스 형식이 잘못되었습니다.",
    "请输入6-20位字母加数字新密码": '6-20자리 알파벳과 숫자 새 비밀번호를 입력하세요',
    "请输入6-20位字母加数字密码": '6-20자리 알파벳과 숫자 비밀번호를 입력하십시오.',
    "密码格式错误": '암호 형식 오류',
    "确认密码格式错误": '암호 형식 확인 오류',
    "请再次输入密码": "암호 다시 입력하십시오.",
    "两次密码不一致": '비밀번호가 두 번 일치하지 않습니다.',
    "重置密码": '비밀번호 재설정',
    "下一步": "다음",
    "请输入验证码": '인증 코드를 입력하십시오.',
    "注册成功": '등록 성공',
    "邮箱注册": "메일 등록",
    "请输入邀请码": "초대코드 입력하십시오.",
    "选填": "선택입력",
    "注册": "등록",
    "已有账号，立即登录": "아이디 있으니 바로 로그인합니다.",


    /****** 量化学院 */
    "欢迎来到新手学院": '초보 학원에 오신 걸 환영합니다.',
    "请输入搜索内容": '검색 내용을 입력하십시오.',
    "暂无内容": '컨텐트 없음',
    "量化学院":"계량화 학원",
    "文章": '기사',
    "推荐文章": '추천 기사',
    "搜索结果": '검색 결과',
    "无搜索内容": '검색 내용 없음',
    "新手必读": '초보자 필독',
    "交易策略": '거래 전략',
    "区块链资讯": '블록체인 정보',
    "发布于": '게시',

    /***** 0 */
    "无需编码的":"인코딩 불필요",
    "自动加密货币交易":"자동 암호화폐 거래",
    "机器人":"로봇",
    "像专业人士一样，释放您的交易潜力。":"전문가처럼 당신의 거래 잠재력을 발휘하십시오.",

    /***** 1 */
    "通过 Vtrading 机器人最大化您的利润":"Vtrading로봇을 통해 당신의 수익을 극대화하십시오.",
    "将您的交易帐户与API密钥连接起来并立即创建您的加密机器人":"당신의 거래 계정과 API키를 연결하고 암호로봇을 바로 생성합니다.",

    "交易机器人":"거래 로봇",
    "适用于每种市场趋势看涨、持平或看跌。":"상승세, 보합세, 하락세 등 모든 시장 추세에 적용됩니다.",
    
    "终端":"단말기",
    "批量执行手动订单。":"수동주문 대량 실행하기",
    
    "移动应用程序":"모바일 앱",
    "下载应用程序以随时随地管理您的交易机器人。":"언제 어디서나 당신의 거래 로봇을 관리할 수 있는 앱을 다운로드하세요.",
    "不错过任何市场趋势。":"어떤 시장 추세도 놓치지 마세요.",
    
    "交易信号":"거래신호",
    "从布林线、价格警报、交易视图信号等中进行选择，以便交易机器人在正确的时间启动和停止。":"Boll, 가격경보, 거래뷰신호 등 중에서 선택하여 거래로봇이 정확한 시간에 작동하고 스톱할 수 있도록 합니다.",

    "跟单交易":"화환거래",
    "帮助您提高机器人的利润并学习其他交易者的策略。":"로봇의 수익을 높이고 다른 거래자의 전략을 배울 수 있도록 도와줍니다.",

    "试试看":"해보세요.",

    /***** 2 */
    "在顶级加密货币交易所运行交易机器人":"톱암호화폐거래소에 거래로봇을 운영합니다.",
    "将您的交易帐户与 API 密钥连接起来并立即创建您的加密机器人。":"당신의 거래 계정이 API키과 연결하여 암호로봇을 바로 생성합니다",
    "接入账户":"계정 접속",

    /***** 3 */
    "复制顶级交易机器人策略":"톱거래로봇 전략 복사하기",
    "发现并复制交易市场上最好的交易机器人以增加您的利润":"당신의 수익을 높일 수 있도록 시장에  톱거래로봇을 찾아내 복사합니다.",
    "下载APP": '다운로드 APP',

    /***** 3- */
    "策略收益排行榜":"정책 이익 순위",
    "与优秀的策略交易员同行":"우수한 전략 거래원과 동행",
    "总投入":"총 투입",
    "总收益率":"총수익률",
    "账户权益":"계좌 권익",
    "持仓盈亏":"창고 보유 손익",
    "总年化":"총년화",
    "交易笔数":"거래 건수",
    "交易额":"거래액",
    "最大回撤":"최대 복귀",
    "我要跟单":"주문할게요.",
    "仓位阈值":"창고 임계값",

    /***** 4 */
    "Vtrading是怎么运行的？":"Vtrading은 어떻게 실행합니까?",
    "将您的交易所与 API 密钥连接起来并创建机器人进行交易。":"당신의 거래소가 API키과연결하고 로봇을 생성하여 거래합니다.",
    "您的资金绝对安全，API经过多重算法加密，安全性高！":"당신의 자금은 절대 안전합니다. API가 다중 알고리즘으로 암호화되어 보안성이 최상이다!",
    "您可以通过API连接多个交易所，管理不同的资金账户。":"API를 통해 여러 거래소를 연결하여 다른 자금계정을 관리할 수 있습니다.",
    "更轻松地检查您的帐户或执行订单。":"당신의 계정이나 실행주문을 더 쉽게 체크할 수있습니다.",
    "连接账户":"계정 접속",

    /***** 5 */
    "随时随地进行交易":"언제 어디서나 거래할 수 있습니다.",
    "扫描下载":"스캔 다운로드",
    
    /***** 6 */
    "复制其他交易者的策略": "다른 거래자의 전략 복사하기",

    "订阅其他使用者的策略信号": "다른 사용자의 전략신호 가입하기",

    "无须配置参数": "배치 파라미터 불필요",
    "小白也能快速启动策略": "초보도 전략을 빨리 시작할 수 있습니다.",
    
    "自己建立策略信号发起跟随": "스스로 전략신호 생성하여 추적 시작하기",
    "其他使用者可通过付费跟随您的策略": "다른 사용자는 비용을 지불하여 당신의 전력을 따를 수 있습니다.",

    /***** 7 */
    "从2017年开始":"2017년부터",
    "Vtrading和大家一起经历了熊市和牛市":"Vtrading은 여러분과 함께 하락장과 상승장을 경험해 왔습니다.",
    "全球用户":"글로벌 사용자",
    "机器人数量":"로봇 수량",
    "日交易量":"일일 거래량",

    /***** 8 */
    "关注您的利润增长":"당신의 수익 증가에 주목하겠습니다. ",
    "从强大的交易机器人到智能订单工具，vtrading 机器人可以帮助您在加密世界中实现利润最大化":"강대한 거래로봇부터 스마트 주문까지 vtrading 로봇은 암호화 세게에서 당신의 수익을 최대화할 수 있습니다.",
    "交易机器人":"거래로봇",
    "借助全自动交易机器人，24/7 从每个市场变动中获利。":"자동 거래로봇으로 24/7 모든 시장의 변동에서 이익을 얻습니다.",
    "在几分钟内设置 GRID 和 DCA 策略。":"몇분간에 GRID과 DCA전략을 설정합니다.",
    
    "智能订单":"스마트 주문",
    "通过强大的订单功能执行您的交易策略。":"강대한 주문 기등으로 당신의 거래전략을 실행하십시오.",
    "通过不同的功能控制您的风险或增加您的利润，如止损、止盈、追踪止盈、单轨等。":"스톱 로스, 스톱 프로픽트, 트래킹 프로픽트, 모노레일 등과 같은 다양한 기능을 통해 당신의 리스크를 컨트롤하거나 수익을 증가하십시오.",

    "信号终端":"신호 단말기",
    "信号终端可以更智能地运行交易机器人。":"신호 단말기는 거래로봇을 더 지능적으로 실행할 수 있습니다.",
    "无论是复制的策略还是自己创建的外部信号通过 TradingView ，机器人可以自动执行。":"복사된 전략이든 스스로 생성한 외부신호든 TradingView를 통해 로봇은 자동으로 작동할 수 있습니다.",

    /***** 9 */
    "设置机器人需要多长时间？":"로봇을 세팅하는 데 얼마나 걸립니까?",
    "只需几分钟即可开始交易，只需通过API密钥连接到您的交易所，选择所需的交易货币并设置参数即可。":"몇 분이면 거래을 시작할 수 있습니다. API키로 당신의 거래소과 연결하여 원하는 거래화폐를 선택하고 조건을 설정하기만 하면 됩니다.",
    "启动交易机器人需要多少钱？":"거래로봇을 작동시키려면 얼마 필요합니까?",
    "没有最低交易限制，但为了使策略良好运行，建议单个机器人投资超过 200 美元。":"최소 거래 제한은 없지만 전략을 잘 실행하기 위해 로봇 하나에 200달러 이상을 투자하는 것이 좋습니다.",
    "Vtrading可以在哪些类型的市场进行交易？":"Vtrading은 어떤 종류의 시장에서 거래할 수 있습니까?",
    "Vtrading 有现货和合约策略，以及网格机器人、DCA 机器人和其他类型的策略。 无论是牛市、熊市还是波动市场，您都可以选择不同的策略来执行交易。":"Vtrading 에는 현물, 계약 전략, 그리드 로봇, DCA로봇과 다른 종류의 전략이 있습니다. 상승장이든 하락장이든 변동장이든 다양한 전략으로 거래를 실행할 수 있습니다.",
    "使用 vtrading 机器人进行交易安全吗？":"vtrading로봇을 통한 거래는 안전합니까?",
    "Vtrading不存储您的资金，您的账户只能通过API密钥访问进行交易，并且API经过多重算法加密，绝对安全。 您可以完全控制您的资金。":"Vtrading은 당신의 자금을 저축안합니다. 당신의 계정은 API키를 통해서만 거래할 수 있으며 API는 다중 알고리즘으로 암호화되어 절대적으로 안전합니다. 당신은 당신의 자금을 완전히 컨트롤할 수 있습니다.", 
    "我可以随时取款吗？":"언제든 인출할 수 있습니까?",
    "Vtrading不存储您的资金，因此您可以随时终止您的策略并转移您的资金。":"Vtrading에은 자금을 저축하지 않으므로 언제든지 전략을 중지하고 자금을 이전할 수 있습니다.",
    
    /******** 10 */
    "公司主题曲":"회사 주제곡",
    "我的人生要自己把握，Go with Vtrading to Victory。":"나의 인생은 내 스스로, Go with Vtrading to Victory",

    /******** 11 */
    "Vtrading数字资产":"Vtrading 디지털 자산",
    "Al量化":"AI 계량화",
    "服务平台介绍":"서비스 플랫폼 소개",
    "Vtrading数字资产Al量化服务平台介绍":"Vtrading 디지털 자산, AI 계량화, 서비스 플랫폼 소개",
    "Vtrading成⽴于2017年12⽉，旨在为不同层次的客户提供差异化的量化交易策略服务。":"Vtrading은 2017년 12월에 설립되었으며 다양한 계층의 고객에게 차별화적인 계량화 거래 전략 서비스를 제공합니다.",
    "Vtrading数字资产AI量化服务平台提供可视化图元平台编辑策略、Hbase系统快速调试回测、自定义发布商城策略、资管系统、智能跟单系统、独⽴返佣系统、⼤客户私有定制部署、第三⽅嵌⼊式系统等功能及服务。":"Vtrading 디지털 자산 AI계량화 서비스 플랫폼은 시각화 프리미티브 플랫폼 편집 전략, Hbase시스템 빠른 디버깅 백테스트, 자체 정의 마켓 전략, 자금 관리 시스템, 스마트 화환 시스템, 개별 리베이트 시스템, 대형 고객 개인 맞춤형 배치, 제3의 임베디드 시스템 등의 기능과 서비스를 제공합니다.",
    "用户可通过Vtrading提供的多种服务来实现不同的量化需求，让普通用户无需懂得编写代码也能运行量化策略，让量化交易变得更简单。":"사용자는 Vtrading이 제공하는 다양한 서비스를 통해 다양한 계량화 요구를 실현할 수 있으며 일반 사용자가 코드를 작성하지 않고도 계량화 전략을 실행할 수 있도록 하여 계량화 거래가 더 쉽게 합니다.",

    /******** 底部 */ 
    "平台策略":"플랫폼 전략",
    "DCA策略":"DCA 전략",
    "网格策略":"그리드 전략",
    "DCA合约策略":"DCA 계약 전략",
    "网格合约策略":"그리드 계약 전략",
    "信号策略":"신호 전략",
    "资源":"정보", 
    "新手学院":"조보 학원", 
    "新闻资讯":"뉴스 정보", 
    "联系我们":"연락해 주십시오.", 


    /************************ 网格策略 */
    "什么是网格交易？":"그리드 거래란 무엇입니까?",
    "网格交易是一种量化交易策略，即在震荡行情中通过一定的价格区间构造出一系列的买卖价位, 不断的执行低买高卖, 从而获得波段收益的一种交易方法。":"그리드 거래는 일종의 양적 거래 전략으로, 즉 흔들리는 시세에서 일정한 가격 구간을 통해 일련의 매매 가격대를 구성하고, 끊임없이 낮은 매수와 높은 매도를 집행하여 주파수 대역의 수익을 얻는 거래 방법이다.",
    "具体来说，网格交易是以一个价格为基础，在该价格的上下划分多个区间或者网格，并在每条网格上预先设定相应的买入和卖出订单。":"구체적으로 그리드 거래는 한 가격을 바탕으로 이 가격의 상하로 여러 구간이나 그리드를 나누고 각 그리드에 해당하는 매입과 매도 주문을 미리 설정하는 것이다.",
    
    "网格交易适用于什么行情？":"그리드 거래는 어떤 시세에 적용됩니까?",
    "网格交易更合适在持续震荡的行情下交易获利。":"그리드 거래는 지속적으로 흔들리는 시세에서 거래하여 이익을 얻는 것이 더 적합하다.",
    "但如果行情上涨，您可以通过调整网格策略的参数从而将利润最大化":"그러나 시세가 상승하면 그리드 정책의 매개 변수를 조정하여 이익을 극대화할 수 있습니다.",

    "Vtrading 网格机器人的优势":"Vtrading 메쉬 로봇의 이점",
    "Vtrading平台的网格机器人兼顾各种行情，无论是上涨、下跌还是震荡都可以获利。":"Vtrading 플랫폼의 그리드 로봇은 각종 시세를 모두 고려하여 상승, 하락, 진동 모두 이익을 얻을 수 있다.",
    "下跌行情使用币本位机器人套利赚币，上涨行情增加资金投入，震荡行情又可以调整网格区间":"하락 시세는 화폐 본위 로봇 차익을 사용하여 화폐를 벌고, 상승 시세는 자금 투입을 증가시키며, 진동 시세는 또 격자 구간을 조정할 수 있다",
    "灵活":"유연성",
    "网格参数随用随改，及时生效":"메쉬 매개 변수는 사용과 함께 변경되며 즉시 적용됩니다.",
    
    "持续稳定":"지속적 안정",
    "网格区间灵活调整，从容面对上涨或下跌行情":"그리드 구간은 유연하게 조정되어 상승 또는 하락 시세에 침착하게 직면한다",
    
    "风险可控":"위험 관리",
    "通过参数来调整限制订单数量，从而应对突发行情，对于资金仓位的管控有非常好的帮助":"파라미터를 통해 주문 수량을 조정하여 제한함으로써 돌발 시세에 대응하는 것은 자금 창고의 관리 통제에 매우 좋은 도움이 된다",

    "观看网格讲解视频":"그리드 설명 비디오 보기",
    "播放":"재생",

    "如何启动网格交易策略？":"그리드 거래 정책을 어떻게 시작합니까?",
    "通过API链接您的交易所":"API를 통해 거래소 연결",
    "选择网格策略":"격자 정책 선택",
    "选择模式开启策略":"모드 선택 정책 열기",


    
    /************************ 合约网格策略 */
    "期货网格交易机器人":"선물 그리드 거래 로봇",
    "降低您的风险，增强您的交易盈利":"위험 요소 감소, 수익 증대",
    "期货市场波动比较大、交易风险高，如果我们使用网格交易机器人在期货市场交易，可以利用杠杆放大收益，通过设定的网格区间可以持续交易获利，交易产生的利润又可以补充保证金，从而降低期货账户的风险":"선물시장의 파동이 비교적 크고 거래위험이 높다. 만약 우리가 격자거래로보트를 사용하여 선물시장에서 거래한다면 지레대를 리용하여 수익을 확대할수 있고 설정한 격자구간을 통해 지속적으로 거래하여 리익을 얻을수 있으며 거래에서 산생된 리윤은 또 보증금을 보충할수 있어 선물구좌의 위험을 낮출수 있다.",
    "立即交易":"즉시 거래",

    "利用加密货币期货交易获利":"암호화폐 선물 거래를 이용하여 이익을 얻다",
    "以更低的保证金进行更高的交易质量":"더 낮은 보증금으로 더 높은 거래 품질 제공",
    "交易杠杆高达 X10":"거래 레버리지 최대 X10",
    "灵活选择多空交易":"다중 공용 트랜잭션을 유연하게 선택",

    
    "选择您的策略":"정책 선택",
    "无论市场是下跌还是上涨，都可以通过 Vtrading 的网格期货加密交易机器人增强您的交易游戏并实现您的目标。":"시장이 하락하든 상승하든 Vtrading의 그리드 선물 암호화 거래 로봇을 통해 거래 게임을 강화하고 목표를 달성할 수 있습니다.",
    "当市场上涨时":"시장이 상승할 때",
    "增强利润":"이익 증대",
    "网格策略期货多头":"그리드 정책 선물 다중 헤더",
    "通过不间断交易来积累利润，从而增强收益。":"끊임없는 거래를 통해 이윤을 축적하여 수익을 높이다.",
    
    "当市场下跌时":"시장이 하락할 때",
    "赚取加密货币":"암호화폐를 벌어들이다",
    "网格策略期货空头":"그리드 정책 선물 공백",
    "通过不断高卖低买的形式，持续追踪下跌市场交易获利。":"끊임없이 높은 것을 팔고 낮은 것을 사는 형식을 통해 지속적으로 하락 시장 거래의 이익을 추적한다.",
    
    "只需三步就可以实现网格期货自动化交易":"3단계 만에 그리드 선물 자동화 거래를 실현할 수 있다",
    "选择期货网格策略":"선물 그리드 정책 선택",
    "选择策略方向并启动策略":"정책 방향 선택 및 정책 시작",

    
    /************************ DAC合约网格策略 */
    "什么是DCA策略？":"DCA 정책이란 무엇입니까?",
    "DCA是一种美元平均成本策略，通过分批买入建仓从而降低买入成本，同时将DCA的策略运用到期货机器人上更能有效的控制风险，借助期货的杠杆特性有效的利用资金放大账户收益。":"DCA는 일종의 달러 평균 원가 전략으로, 분할 매입을 통해 창고를 건설함으로써 매입 원가를 낮추는 동시에 DCA의 전략을 선물 로봇에 활용하여 더욱 효과적으로 위험을 통제할 수 있으며, 선물의 레버리지 특성을 빌어 효과적으로 자금을 이용하여 계좌 수익을 확대할 수 있다.",
    
    "选择您的策略":"정책 선택",
    "无论市场是下跌还是上涨，都可以通过 Vtrading 的DCA期货加密交易机器人增强您的交易游戏并实现您的目标。":"시장이 하락하든 상승하든 Vtrading의 DCA 선물 암호화 거래 로봇을 통해 거래 게임을 강화하고 목표를 달성할 수 있습니다.",
    
    "利润增强":"이익 증대",
    "当价格上涨时":"가격이 오를 때",
    "DCA策略期货多头":"DCA 정책 선물 다중 헤더",
    "将您的资金分批投入以获得更好的平均价格。 最大化您的回报并最小化波动影响。":"더 나은 평균 가격을 얻기 위해 자금을 분할 투입합니다.수익을 극대화하고 변동성을 최소화합니다.",
    
    "赚取加密货币":"암호화폐를 벌어들이다",
    "当市场下跌时":"시장이 하락할 때",
    "DCA期货空头":"DCA 선물 공매",
    "分批建立空头头寸，从而控制市场风险，避免账户持仓均价建立在行情底部，同时追踪下跌行情来交易获利。":"분할적으로 공매도 포지션을 구축하여 시장 위험을 통제하고, 계좌 보유 평균 가격이 시세 하단에 세워지는 것을 피하며, 동시에 하락 시세를 추적하여 거래하여 이익을 얻는다.",
    

    "DCA策略+信号触发组合机器人":"DCA 정책 + 신호 트리거 조합 로봇",
    "最大化您的交易利润":"거래 수익 극대화",
    "通过Vtrading提供的信号触发功能，可以设置价格突破或跌破策略，也可以自定义设置 TradingView 启动和停止信号，以帮助交易者寻找更好的入场时机，从而增强策略的盈利能力":"Vtrading에서 제공하는 신호 트리거 기능을 통해 가격 돌파 또는 하락 정책을 설정하거나 TradingView 시작 및 중지 신호를 사용자 정의하여 거래자가 더 나은 입장 시기를 찾을 수 있도록 도와 정책의 수익성을 향상시킬 수 있습니다.",

    
    "只需 5个简单步骤即可启动 DCA 合约策略":"간단한 5단계로 DCA 계약 정책 시작",
    "通过 Vtrading 开始交易，释放您的交易潜力":"Vtrading을 통해 거래를 시작하여 거래 잠재력 확보",
    "通过API连接您的交易所":"API를 통한 거래소 연결",
    "选择DCA期货机器人":"DCA 선물 로봇 선택",
    "选择交易方向并启动策略":"거래 방향 선택 및 정책 시작",
    "选交易标的":"거래 대상 선택",
    "运行机器人并观察其盈利":"로봇을 실행하고 수익을 관찰",

    
    /************************ 下载 */
    "download_one_1":"Trade like a pro with Vtrading",
    "download_one_2":"bot",
    "download_one_3":"Make crypto trading easier",
    "download_one_but_1":"Download App",
    "download_one_but_2":"Download Android version",

    "download_two_1":"Watch Your Profits Grow",
    "download_two_2":"From powerful trading bots to smart orders tools",
    "download_two_3":"Just need one app",

    "download_three_1":"Automated crypto  trading bot without coding",
    "download_three_2":"Trade crypto like a pro with our crypto trading platform on your Mobile terminal.",
    "download_three_3":"Help you improve bot’ s profits and learn from other traders‘ strategies.",

    "download_four_1":"One terminal manages accounts on multiple exchanges",
    "download_four_2":"Connect to the exchange and manage accounts via API, automatically run strategies, and monitor profit trends anytime and anywhere...",

    "download_five_1":"Maximize your profits",
    "download_five_2":"Unlock your trading potential.",
    "download_five_but_1":"Download App",

    


    

    "开始交易": 'Start now',
    "查看详情": 'Find more details',
    "牛市行情如何盈利？": 'How to achieve profit in a bull market?',
    "美元平均成本": 'DCA (Dollar-COST Averaging)',
    "使用DCA策略，可以逐步降低买入成本，等待行情反弹从而获利。": 'Lower the cost gradually and take profit until the market rebound',
    "通过分批买入、分批卖出的形式不断在波动中套利": 'Continuously arbitrage profits from fluctuations by buying and selling in batches ',
    "信号触发器": 'Signal trigger',
    "通过TradingView等外部警报信号，设置触发器，从而提前对熊市的早期迹象做出策略调整自动批量触发信号，执行止盈、止损、暂停、清仓等操作": 'Set the trigger based on the external alarm signal such as TradingView   to make decisions accordingly to the early signs of the bear market.Trigger the signals automatically to execute actions of take-profit, stop-loss, pause, clearance, etc.',
    "创建机器人": 'Created Bot',
    "使用Vtrading交易机器人，让获利更简单": 'Trading is hard ,but Vtrading makes it easier',
    "无论是上涨或者下跌行情，都可以通过Vtrading机器人获得利润，我们需要做的就是设定目标和调整策略，让机器人更智能化运行以达成目标。": 'Win the profit with Vtrading trading bots regardless of the market change. Just set the goal and adjust the strategy, allowing the bots to run more intellectually to achieve your target',
    "7*24小时自动执行交易": '7*24 auto trading',
    "机器人会按照设定的参数执行交易，在震荡市场下产生的收益远远超出预期": 'Vtrading bots will execute trading with preset parameters, generating more revenue than expected in the fluctuated market',
    "多重策略可供选择，无障碍穿越牛熊": 'Multiple strategies are available, barrier-free crossing of bulls and bears',
    "丰富的策略类型可为不同的市场行情提供多样的选择，无论是网格策略、趋势策略、还是合约策略，您都可用通过Vtrading建立策略模板执行自动化交易": 'A variety of strategies adapt to different market situations. No matter grid strategy, trend strategy, or future strategy, create a strategy template with Trading to perform the auto trading',
    "学会掌控市场": 'Learn how to master the market',
    "使用合理的策略，设置稳定的参数来建立您的加密货币投资组合，以达到交易利润最大化，通过策略及其参数控制风险，最小化您的交易损失": 'Create a investment combo with a smart strategy to achieve profit maximazation—control risk through strategy and parameters to minimize your loss',
    "如何启动量化机器人": 'How to start the quantitative trading bot ',
    "通过API密钥连接交易所并创建机器人执行下单交易": 'Connect to exchange through API key and create bots to perform trading.',
    "您的资金仍然在交易所，相比于将资金直接转入量化平台，使用API链接将更加安全可靠。并且通过API可以一键管理您的多个平台账户资产，查看账户持仓或执行头寸交易更加便捷。": 'Your funds are still kept in exchange. API key connection is safer compared to transferring funds to the platform directly.Multiple funds account in different exchanges can be managed with API connections, making checking holding positions and trade positions easier. ',
    "以下交易所支持使用Vtrading自动交易工具": 'Exchanges that support Vtrading auto bots',
    "币安交易所":'Binance',
    "OKX交易所":'OKX',
    "HTX交易所":'HTX',
    


    "连接交易所": 'Connect exchange',
    "敬请期待": 'Coming soon…',

    "APP扫码下载": "App code",

    // ****************
    "语言": 'language',


    "简体中文": '简体中文',
    "英文": 'English',

    "聚合账户": 'Aggregate Account',
    "总资产折合": 'Estimated Total Value',
    "今日收益": "Today's Return",
    "近7天累计收益": 'Total benefits of past 7d',
    "我的机器人": 'My Bot',
    "总收益": 'Total Benefit',

    "查看更多": 'More',

    "我的交易所": 'My Exchanges',
    "资产折合": 'Estimated Value',

    "切换主题": 'Theme',
    "交易所": 'Exchange',
    "担保资产率": 'Margin Ratio',
    "请选择交易所": 'Choose Exchange',
    "退出登录": 'Sign out',
    "请输入": 'Please enter',
    "计价单位": 'Currency',
    "连接": 'Connect',
    "开仓均价": 'Entry Price',
    "预估强平价": 'Est. Liquidation Price',
    "去": 'go',
    "聚合账户详情": 'Aggregate Account Details',
    "账户详情": 'Account Details',
    "统计数据": 'Statistical data',
    "资产走势": 'Asset Trend',
    "日期范围": 'Dates range',
    "累计收益": 'Benefits',
    "请选择日期范围": 'Please select date range',
    "每日收益": 'Daily Benefit',
    "币币账户": 'Exchange',
    "合约账户": 'Derivatives',
    "搜索": 'search',
    "币种": 'Tokens',
    "估值": 'Estimation',
    "价格变化": 'Change（24h）',
    "数量": 'Volume',
    "合计": 'Total',
    "名称": 'Name',
    "全部": 'All',
    "启用": 'Enabled',
    "关闭": 'Close',
    "做多": 'Long',
    "做空": 'Short',
    "双向": 'Two Side',
    "持仓量": 'Avail. | Total',
    "创建DCA机器人": 'Create DCA bot',
    "同步资产": 'Refresh',
    "账户类型": 'Account Type',
    "筛选": 'Filter',
    "清除筛选": 'Clear Filters',
    "机器人名称": 'Bot Name',
    "执行中": 'Running',
    "已终止": 'Terminated',
    "参数": 'Parameter',
    "启动": 'Start',
    "重启": 'Restart',
    "复制": 'Copy',
    "详情": 'Details',
    "终止": 'Abort',
    "币对": 'Contracts',
    // "U本位合约账户": 'USDT-M Futures Wallet',
    "U本位合约账户": 'USDT-M Futures Account',
    "交易方向": 'TradeDirection',
    "方向": 'Side',
    "保证金模式": 'MarginMode',
    "全仓": 'Cross',
    "逐仓": 'Isolated',
    "杠杆": 'Leverage',
    "持仓止盈": 'TakeProfit',
    "止盈平仓模式": 'Take profit mode',
    "一次性止盈": 'Take profit',
    "逐单止盈": 'Take profit inbatches',
    "止盈下单模式": 'TakeProfitMode',
    "追踪止盈": 'TrailingTP',
    "预埋单止盈": 'LimitOrderTP',
    "止盈阈值": 'TPThreshold',
    "止盈回撤比例": 'TPDevintion',
    "持仓止损": 'StopLoss',
    "止损阈值": 'StopLossThreshold',
    "止损后的操作": 'ExecutionAfterStopLoss',
    "终止机器人": 'TerminateBot',
    "继续交易": 'ContinueTrading',
    "高级设置": 'AdvancedSettings',
    "最低价": 'MinPrice',
    "最高价": 'MaxPrice',
    "开仓等待时长": 'CooldownBetweenDeals',
    "总收益止盈": 'TakeProfit',
    "总收益止损": 'StopLoss',
    "终止时自动撤销委托": 'AutoTermination',
    "终止时自动平仓": 'AutoCloseout',
    "账户资产": 'Balance',
    "可用权益": 'AvailableFunds',
    "划转": 'Transfer',
    "建议投入": 'RecommendFunds',
    "当前最小须投入": 'MinFunds',
    "账户已有策略，暂不可建": 'account has a full-position bot running, failed to create new orders',
    "执行时长": 'execution time',
    "交易": 'Trading Bots',
    "邀请好友": 'Invite Friends',
    "帮助": 'Help',
    "首页": 'Home',
    "DCA机器人": 'DCA Bot',
    "机器人列表": 'Bot list',
    "活跃实例": 'Active Instances',
    "历史": 'History',
    "未创建机器人": 'You have not created to an bot,click on the bottom of the button to create...',
    "连接交易所开始交易": 'Go connect to the exchange and start trading',
    "开始日期": 'startTime',
    "结束日期": 'endTim',
    "请输入机器人名称": 'Please enter bot name',
    "请输入交易对": 'Please enter pairs',
    "请勾选用户服务协议 ": 'Please select user Service Protocol ',
    "未启用": 'Disabled',
    "暂停": 'Paused',
    "启动中": 'Starting',
    "启动失败": 'Fail to start',
    "终止中": 'Terminating',
    "首单投入": 'BaseOrder',
    "补仓间隔比例": 'AddFundsSpaceRatio',
    "补仓反弹比例": 'TrailingBuyRatio',
    "最大做多单数": 'MaxLongOrder',
    "最大做空单数": 'MaxShortOrders',
    "请登录后再试": 'Please login and try again',
    // "天": 'days',
    // "时": 'hours',
    // "分": 'minutes',
    "天": 'd',
    "时": 'h',
    "分": 'm',
    "实例": 'Instances',
    "交易对": 'pairs',
    "盈亏": 'Profit and Loss',
    "状态": 'state',
    "未实现": 'Unrealized',
    "止盈终止": "Close at TP",
    "止损终止": "Close at SL",
    "自动终止": 'Close at Auto',
    "定时终止": "Close at Timed",
    "手动终止": "Close at Manual",
    "强制终止": "Close at Force",
    "成交记录": 'TradeHistory',
    "暂无实例": 'No data',
    "暂无数据": 'Empty Data',
    "开多": "Open long",
    "开空": "Open short",
    "平多": "Close long",
    "平空": "Close short",
    "委托数量": "Size",
    "委托价格": "Price",
    "收益": "Profits",
    "删除": 'Delete',
    "成交时间": 'Time',
    "持仓数量": 'Avail. | Total',
    "开仓均价": 'Open Price',
    "强平价": "Liq.Price",
    "补仓追踪": 'Add Funds Trailing',
    "当前委托": 'OpenOrders',
    "当前做单数": "SafetyOrders",
    "加仓": 'Add Funds',
    "市价平仓": 'Close All Positions',
    "邀请好友": 'Invite friend',
    "邀请链接": "Invite link",
    "访问": 'Visitors',
    "邀请": "Invite",
    "邀请记录": "Invite record",
    "邀请时间": "Time",
    "账户设置": "Account Settings",
    "昵称": 'NickName',
    "头像": 'Avatar',
    "绑定": 'Bound',
    "安全设置": 'Safety Settings',
    "修改": "Change",
    "谷歌验证器": "Google Authenticator",
    "未绑定": 'Unbound',
    "已绑定": "Bound",
    "换绑": "Change",
    "登录验证": 'Login Authentication​',
    "账号绑定": "Bind on Account",
    "登录设备": 'Login Device',
    "当前设备": 'Current device',
    "请输入名称": 'Please enter name',
    "请输入API key": 'Please enter Api Key',
    "请输入API Secret": 'Please enter Api Secret',
    "我已阅读并同意服务协议": 'I have read and agree to the [User Service Agreement]',

    "请输入币种": 'Enter Trading Tokens',
    "当前做单": 'Current Order',
    "加载中": 'Loading',
    "操作": 'Action',
    "成交数量": 'Closed Size',
    "成交价格": 'Closed Price',
    "时间": 'Time',
    "请输入划转数量": 'Enter Transfer Amount',
    "撤销": 'Revoke',
    "请选择": 'Choose',
    "到": 'to',
    "从": 'From',
    "可划转": 'Available for transfer',
    "确定": 'Confirm',
    "取消": 'Cancel',
    "复制密钥": 'Copy secret key ',
    "密钥": 'Secret key',
    "现货账户": 'Spot Account',
    "合约账户-U本位合约": 'Futures Account-USDT-M',
    "委托方向": 'Direction',
    "委托类型": 'Type',
    "市价委托": 'Market price',
    "解除绑定": 'Unbind',
    "交易账户": 'Trade account',
    "资金账户": 'Funding account',
    "切换验证方式": 'Swich',
    "扫码登录": 'Scan login',
    "刷新二维码": 'Refresh QR code',
    "打开 VtradingAPP 扫一扫登录": 'Open the Vtrading App and scan the code to login',
    "现在开始": 'Enter',
    "撤销中": 'Canceling',
    "持仓": 'Take  position',
    "持仓收益": 'Position income ',
    "来源": 'Source',
    "手动操作": 'Manual operation',
    "机器人表现": "Bot's performance",
    "限价委托": 'Limit',
    "确定要删除当前机器人": 'Are you sure you want to delete the current bot',
    "请输入价格": 'Enter a price',
    "可用": 'Available',
    "金额": 'Amount',
    "手动加仓": 'Manual',
    "是否解除绑定Vtrading账户": 'Are you sureyou want to unbind Vtrading account',
    "解绑": 'Unlink',
    "验证": 'verification',
    "设备名": 'Device',
    "最近登录时间": 'Recent login',
    "请输入正确的数量": 'Enter the correcct amount',
    "金额不能大于可用金额": 'Amount must less than available amount',
    "金额不能小于": '金额不能小于',
    "重复新邮箱": 'Enter new mail  again ',
    "请输入新邮箱": 'Enter new mail',
    "请输入新密码": 'Enter new password',
    "更新成功": 'Updated successfully',
    "再次输入新密码": 'Enter new password again',
    "请再次输入新密码": 'Enter new password again',
    "两次密码输入不一致": 'Inconsistent password',
    "请再次输入新邮箱": 'Enter new email address again',
    "两次邮箱输入不一致": 'Inconsistent  email',
    
    "删除成功": 'Delete Successful',
    "启动成功": 'Start  Successfully',
    "重启成功": 'Restart  Successfully',
    "终止成功": 'Terminate  Successfully',
    "暂停成功": 'Pause Successfully',
    "不能为空": "can't be blank",
    "倍": 'X',
    "单": 'Size',
    "绑定成功": 'Binding  successed',
    "请输入百分比": 'Enter a percentage',
    "机器人触发价": 'Trigger price',
    "每日利润": 'Daily profit',
    "请输入数量": 'Enter the quantity',
    "开启交易新世界": 'OPEN UP A NEW WORLD OF TRADING',
    "启动机器人": 'Confirm',
    "确定要启动当前机器人": 'Click to confirm starting current bot',
    "机器人创建成功": 'Bot Create Successful',
    "是否开启机器人": 'Confirm to Start Bot',
    "最大初始保证金": 'Maximun inicial margin',
    "IP地址": 'IP Address',
    "OPEN UP A NEW WORLD OF TRADING": '开启交易新世界',
    "智 能 交 易 / 就 选 Vtrading": 'Start your smart trading / today with Vtrading',
    "保存": 'Save',
    "编辑交易所": 'Link API',
    "编辑": 'Edit',
    "执行实例": 'Runing orders',
    "完成的实例数": 'Finished orders',
    "活跃的实例数": 'Active orders',
    "当前初始保证金占用": 'Inicial margin occupation',
    "活跃": 'Active',
    "确定要终止当前机器人": 'Are you sure you  want to  terminate the current  bot',
    "确定要删除当前账户": 'Are you sure you want to delete the current account',
    "数据": 'Data',
    "信息": 'info',
    "当前最小需投入": 'Minimum',
    "未设置昵称": 'Set  nickname',
    "编辑机器人": 'Choose stratge bot',
    "机器人详情": 'Bot Details',
    "新邮箱": 'New email',
    "刷新成功": 'Refresh succeeded',
    "产品": 'Products',
    "社交": 'Community',
    "U本位合约DCA": 'USDT-M DCA',
    "提示": 'Tips',
    "请输入1-16位昵称": 'Nickname must be  between  1-16 characters long',
    "请输入昵称": 'Set a  nickname',
    "绑定邀请码": 'Bind invitation code',
    "邀请码仅在注册后15天内可绑定, 且绑定后不可修改, 请确认填写的邀请码是否正确": 'The invitation code can only bound within 15 days after regestration,and cannnot be modified after binding,please confirm wether the invitation code is correct',
    "安全验证成功": 'Securite verification succeeded',
    "连接成功,为了您的交易安全,已自动开启登录验证": 'conection succeeded, for the security ,login verification has been turned  on ',
    "绑定谷歌验证器": 'Bind  google authenticator',
    "邮箱验证未完成，请先完成邮箱验证": 'Verify by email first',
    "账户未验证邮箱/绑定GA，请先验证/绑定": 'Your account is not bound to GA,please bind it first',







    "邮箱绑定": 'Email binding',
    "等待开仓": 'Open position waiting ',
    "平仓中": 'Closing',
    "做多开仓中": 'Exeuting a long position',
    "做空开仓中": 'Executing a short position',
    "划转成功": 'Successful transfer',
    "划转不能大于可划转金额": 'Amount must less than available amount',
    "划转需要大于1": 'Amount must be greater than 1',







    "权益": 'Equity',
    "可用担保资产": 'Avail. Margin',
    "最小值": 'min',





    "考虑到衍生品本身风险及机器人计算性能,单个品种最多可同时执行一个全仓、一个做多逐仓、一个做空逐仓机器人;": 'Considering the derevetives and performence of the bot,one coin pair can execute at most one cross  position ,one long isolate position bot , and one short isolate position bot at the same time ',
    "您可以灵活的在机器人实例、智能交易及交易所APP中手动干预仓位,因盈亏数据由交易所提供,此类操作将影响机器人盈亏。": 'You can mannually adjust positions between bot cases,automatic case or  Exchage case,this action will affects the profit and loss of bot cases.',









    "安全验证成功,为了您的交易安全,已自动开启登录验证": "Securite verification succeeded,login verification has been turned on ",
    "设置": 'Settings',
    "下载谷歌验证器": 'Download google authenticator',
    "下载": 'Download',
    "用户登录": 'User login',
    "安卓用户登录应用商店,或使用手机浏览器搜索下载": 'Android users could log in to appstore,or use the mobile browser to search and download',
    "在谷歌验证器中添加密钥并备份": 'Add key in google authenticator and backup',
    "打开谷歌验证器,扫描下方二维码或手动输入下述密钥添加验证令牌。": 'Open google authenticator  scan the QR code blow or manually enter the following key to add a verification  token ',
    "在谷歌验证器中获取验证码并输入": 'Get the verification code in google authenticator and enter it.',
    "U本位合约DCA使用教程": 'The tutorials course of DCA at USD-margine future',
    "验证码": 'code',
    "修改昵称": 'Change nickname',
    "验证": 'verification',
    "为了您的账号安全，请进行安全验证": 'For the security of your account, please verify your security',
    "副本": 'copy',


    "我的账户": "My Account",
    "添加账户": 'Add Account',
    "启用双重认证": 'Turn on  two-factor authentication',

    "操作成功": 'Operation successfully',
    "复制成功": 'Copy successfully',
    "解绑成功": "Unbind successfully",
    "GA验证": 'Google Authenticator',

    "确定删除": 'Confirm Detele',
    "请输入API名称": 'Enter api name',
    "删除账户": 'Delete account',
    "你还没有连接到交易所": 'You have not connected to an exchange',
    "录入成功": 'Connection succeeded',
    "补仓金额倍数": 'Add Funds ratio',
    "倍投起始单": 'Starting order multiplied',
    "补仓金额增量": 'Fixed Add Funds',
    "补仓间隔比例": 'Add Funds space Ratio',
    "补仓增幅": 'Add Funds Amplification',
    "补仓反弹比例": 'Trailing buy ratio',
    "最大做多单数": 'Max Long Order',
    "最大做空单数": 'Max Short Orders',
    "参数出错": 'Parameters Error',
    "调整杠杆": 'Adjust Leverage',
    "暂不支持": 'Not currently supported',
    "删除机器人": 'Delete bot',
    "退出成功": 'Exit successfully',
    "请勾选用户服务协议": 'Please select user Service Protocol',
    "多": 'Long',
    "空": 'short',
    "总盈亏": 'Total profit and loss',









    'tradeOffset': 'tradeOffset',
    'scaleIndex': 'scaleIndex',
    'openWaitTime': 'openWaitTime',
    'openSpaceAdd': 'openSpaceAdd',
    'amount': 'amount',
    'amountModel': 'amountModel',
    'amountScale': 'amountScale',
    'closeLossAfter': 'closeLossAfter',
    'closeLossModel': 'closeLossModel',
    'closeLossThreshold': 'closeLossThreshold',
    'closeModel': 'closeModel',
    'closeRebound': 'closeRebound',
    'closeThreshold': 'closeThreshold',
    'maxLongCount': 'maxLongCount',
    'openModel': 'openModel',
    'openRebound': 'openRebound',
    'openSpace': 'openSpace',
    'openSpaceAdd': 'openSpaceAdd',
    'openWaitTime': 'openWaitTime',
    'scaleIndex': 'scaleIndex',
    'tradeOffset': 'tradeOffset',
    'maxShortCount': 'maxShortCount',
    "不能绑定自己的邀请码": 'You cannot bind your own invitation code',

    "您录入的API与当前API来自不同账户，确认录入将创建新的连接": 'The API you entered is from another account ,confirming the entry will create a new link',


    "您录入的API与名称为": 'The API named',
    "的API来自同一账户": 'as same account as your entered',
    "确认录入将更新原有API?": 'comforming will update the original API?',
    "未知API，确认导入可能会导致运行中的交易错误": 'API is not recoglized,Importing may result transaction errors',
    "今日资产变动": 'Assets Today',
    "近7天资产变动": 'Assets in last 7 days',
    "总资产变动": 'Total Assets',
    "年": 'Year',
    "月": 'Month',
    "日": 'Day',

    "交易账户-现货": 'Trade account-spot',
    "交易账户-合约": 'Trade account-future',
    "网格机器人": 'Grid Bot',
    "创建网格机器人": 'Create a grid bot',
    "如何开始交易": 'How to start trading',
    "活跃机器人": 'Active Bot',
    "查看教程": 'Check the tutorial',
    "U本位合约DCA机器人，可自由切换补仓模式": 'Future DCA bot (golden standard)，freely switch adding modes',
    "U本位合约网格机器人，网格交易的合约版本": 'Future grid bot (golden standard), future version of grid trading',

    "帮助中心": 'Help Center',
    "在线客服": 'Online service',
    "新手引导": 'Beginner Guide',
    "开启交易前你需要将你的交易账户连接至Vtrading": 'Connect your account to Vtrading before you start',
    "账号信息可以修改账户信息，安全设置，登录设备": 'Modify account info, security setting and login devices',
    "修改账号信息": 'Modify account info',
    "API已过期,请重新录入": 'The API key is expired, please try with new one',
    "接管账户仓位": 'Take over positions',
    "立即开启": 'Start immediately',
    "稍后再说": 'Talk later',
    "是否立即开启机器人": 'Start the bot?',
    "上传头像图片大小不能超过 2MB": 'Images must be 2MB or less',
    "上传头像图片只能是 JPG、PNG、JPEG 格式": 'Images must be .jpg or .jpeg format',
    "U本位合约网格": 'Golden-standard future grid',
    "U本位合约网格-m": 'USD-M future Grid',
    "网格": 'Grid',
    "网格间隔": 'Grid Interval',
    "开仓上下限": 'Upper/Lower price',
    "取消修改": 'Cancle Modification',
    "复制参数到自定义": 'Copy to custom',
    "机器人仓位及盈亏计算规则：": 'Calculation rules of bot position and P&L',

    "正在启动": 'Turning on',
    "运行中": 'Running',
    "暂停中": 'Pausing',
    "正在终止": 'Terminating',
    "请先进行邮箱验证,再绑定邀请码": 'Please verify  your email adrress befor binding the invitation code',
    "做多等待开仓": 'Waiting  for a long  position to open',
    "做空等待开仓": 'Waiting  for a short position to open',
    "做多平仓中": 'Closing the long',
    "做空平仓中": 'Closing the short ',
    "做多追踪止盈": 'Long direction Trailing TP',
    "做空追踪止盈": ' Short direction Trailing TP',
    "实例已终止": 'Active orders terminated',
    "欢迎使用": 'Welcome',
    "编辑DCA机器人": 'Choose stratge DCA bot',
    "最大做空网格数": 'Max grids in short direction',
    "最大做多网格数": 'Max grids in long direction',

    "帮助": 'Help',
    "价格": 'Price',
    "市场价": 'Market price',

    "保证金比率": 'Margine ratio',
    "请选择账户": 'Select account',
    "请选择交易对": 'Select trading pair',
    "机器人名称长度限制10个字符": 'Robot name length is limited to 10 characters',
    "请输入最大初始保证金": 'Enter a max initial margine',

    "U本位合约DCA机器人，可自由切换补仓模式，止盈形式也更加灵活": 'The DCA bot witn  usd-m future can freely switch  the adding mode ,and  take profit more flexible',
    "U本位合约网格机器人，网格交易的合约版本，放大本金，分仓套利": 'Grid bot with USD-M future,the future version of grid trading, enlarge the principle,split positions and arbitrage',
    "保证金率": 'Margine ratio',
    "因交易所合并计算仓位成本，此类操作将影响到机器人的仓位成本，进而影响到止盈止损的基准价和机器人的盈亏计算。": 'The exchange caculate the position cost in a consolidated manner will affect the position cost of the bot which inturn affects the benchmark price oftake profit an d stop loss and the calculation of the profit and stop loss of the bot',
    "您可以灵活的在机器人实例、智能交易及交易所APP中手动干预仓位，": "You can flexibly manually intervene positions in bot's instance, intelligente trading and Exchang APPs.",
    "不能为零": 'Cannot be 0',
    "单格投入": 'Input per grid',
    "请确认修改的内容": 'Please confirm the modified content',
    "提交修改": 'Submit changes',
    "实例仓位": 'Running position',
    "量化托管": 'Quantitative account custody',
    "终止上下限": 'Terminate upper and lower limits',
    "网格机器人委托工具": 'Grid bot delegate tool',
    "网格委托工具使用手册": 'Grid toll user manual',
    "复位": 'Reset',
    "价格下限": 'Lower price',
    "价格上限": 'Upper price',
    "热门问题": 'Top questions',
    "支付问题": 'About payment',
    "消费问题": 'About consumption',
    "账户问题": 'About account',
    "请输入关键词搜索": 'Enter a keyword to search',
    "未生效": 'Not active',
    "此版本暂不支持直接编辑": "This version doesn't support edit",
    "永续": 'Perpetual',
    "退出": 'Sign out',
    "委托信息": 'Orders imformation',
    "相关文章": 'Related articles',
    "抱歉，没有找到相关内容": 'Sorry nothing found',
    "上限应大于下限": 'The upper limit should be greater than the lower limit',
    "最低价终止": 'Termination of lowest price',
    "最高价终止": 'Termination of highest price',
    "网格相关教程": 'How it works',
  }
}

export default en
