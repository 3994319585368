<template>
  <div class="nav">

    <component v-if="current"
    :is="'TopNavigation'+current" 

    :token="token"
    :accInfo="accInfo"
    :lang="lang"
    :langs="langs"
    :menu="menu"
    :menus="menus"
    
    @tapClick="tapClick"/>
  </div>
</template>

<script>


const metaTitlech = 'AI量化交易平台 | Vtrading AI Quantize Trading Platform | Vtrading';
const metaTitleen = 'Vtrading | Crypto AI Trading Bot Bitcoin  Platform';

const keyWordsch = 'DCA策略,美元平均成本,网格交易,马丁格尔追踪,区块链,数字货币,加密货币,量化交易,以太坊,比特币';
const keyWordsen = 'Dollar-Cost Averaging (DCA), Grid Trading, Martingale  Tracking, Blockchain, Digital currency, Quantitative Trading, Ethereum, Bitcoin,Altcoin';

const descriptionch = 'Vtrading 是数字资产量化交易平台，提供网格策略、马丁格尔追踪、定投、期现套利、DCA美元平均成本等策略，可通过API一键接入币安、欧易、火币等平台，从而快速启动量化策略。';
const descriptionen = 'Vtrading Automated AI cryptocurrency trading platform - provide cryptocurrency investors with  quantitative strategies such as DCA,  Martingale strategies,  grid trading, etc.,and uses the intelligent tools and trade bots on the platform to make trading Bitcoin, Ethereum and altcoins easier and more efficient intelligent.';

import {getShowPage, isLogin} from "@/utils/function.js"
import TopNavigationH5 from "./TopNavigationH5.vue"
import TopNavigationPc from "./TopNavigationPc.vue"
export default {
  components: {
    TopNavigationH5,
    TopNavigationPc
  },
  
  props: {
    /*菜单
     0(交易)、1(合约)、2(量化学院)*/
    menu: {
      type: Number,
      default: -1
    }, 
    seoMetaTitle: {
      type: String,
      default: ''
    }, 
    seoKeyWords: {
      type: String,
      default: ''
    }, 
    seoDescription: {
      type: String,
      default: ''
    }
  },
  
  data() {
    return {
      current: '', // 当前渲染的组件

      token: false, //登录状态
      accInfo: undefined, //用户信息

      menus: [ //功能菜单
        {'title':'交易策略', 'click':'toTransaction'},
        {'title':'合约', 'click':'toHeYue'},
        {'title':'量化学院', 'click':'toLhxy'},
        // {'title':'定价', 'click':'toDingJia'},
      ],

      lang: undefined, //当前语言
      langd: {'ch':0, 'en':1, 'ko':2, 'ja':3},
      langs: [
        {'name':'汉语', 'label':'Chinese', 'tag':'ZH', 'key':'ch', 
        'url':require('@/assets/image/v3/Chinese.png')},
        
        // {'name':'繁体', 'label':'Hong Kong', 'tag':'HK', 'key':'hk', 
        // 'url':require('@/assets/image/v3/Chinese.png')},
        
        {'name':'英文', 'label':'English', 'tag':'EN', 'key':'en', 
        'url':require('@/assets/image/v3/English.png')},
        
        {'name':'韩文', 'label':'Korean', 'tag':'KO', 'key':'ko', 
        'url':require('@/assets/image/v3/Korean.png')},
        
        {'name':'日文', 'label':'Japan', 'tag':'JA', 'key':'ja', 
        'url':require('@/assets/image/v3/Japan.png')}
      ],
    };
  },
  
  created() {

    this.intiData();
    this.initView();
  },
  
  //SEO优化
  metaInfo() {

    return {
      title: this.metaTitle,
      meta: [
        {name: "keyWords", content: this.keyWords},
        {name: "description", content: this.description},
      ]
    }
  },

  mounted() {
    window.addEventListener('resize', this.initView);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.initView);
  },

  computed: {
    
    metaTitle() {
      if (this.seoMetaTitle) {
        return this.seoMetaTitle;
      }
      return this.$store.state.lang == 'ch' ? metaTitlech : metaTitleen;
    },
    
    keyWords() {
      if (this.seoKeyWords) {
        return this.seoKeyWords;
      }
      return this.$store.state.lang == 'ch' ? keyWordsch : keyWordsen;
    },
    
    description() {
      if (this.seoDescription) {
        return this.seoDescription;
      }
      return this.$store.state.lang == 'ch' ? descriptionch : descriptionen;
    }
  },

  methods: {

    intiData() {
      
      this.token = !!localStorage.getItem("token");
      if(this.token) {
        this.accInfo = JSON.parse(localStorage.getItem("accInfo"));
      }
      
      var num = this.langd[this.$store.state.lang];
      this.lang = this.langs[num];
    },
    
    /******************************************** 其他 */
    //初始化界面判断是H5 还是  PC
    initView() {
      this.current = getShowPage('Pc', 'H5');
    },

    //切换语言
    switchLang(item) {
      this.lang = item;
      this.$store.commit('setLang', item.key)
    },

    /******************************************** 点击 */
    //点击事件(PC、H5)
    tapClick(name, param) {
      this[name](param);
    },

    //返回落地页
    goHome() {
      this.$router.push("/");
    },

    //转跳到个人中心
    goSetUp() {
      if (isLogin(this)) return;
      this.$router.push({ path: "/personal" });
    },

    //交易策略
    toTransaction() {

      if (isLogin(this)) return;
      this.Public.toTransaction();
    },

    //合约
    toHeYue() {

      if (isLogin(this)) return;
      this.$router.push({ path: '/robotList' })
    },

    //量化学院
    toLhxy() {
      this.$router.push({ path: '/newsHome' });
      // this.$message.warning(this.$t("m.暂未开放"));
    },

    //定价
    toDingJia() {
      this.$message.warning(this.$t("m.暂未开放"));
    },

  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/sass/_themeify.scss";
.nav {
  width: 100%;
}

</style>
