import axios from 'axios';
// import QS from 'qs';
import { Message } from 'element-ui'
import router from "../router";
import store from '../store/index'

import publicMethod from '../public/index'

// 环境的切换
// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = '/api';
// } else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL = '';
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = 'http://192.168.2.22:8006/';
// }

 

axios.defaults.baseURL = "https://vtrading.com/api"  //正式1
// axios.defaults.baseURL = "https://test.vtrading.com/api"  //正式2

// axios.defaults.baseURL = "http://159.138.104.103:8797/VtradingPc" //测试 
// axios.defaults.baseURL = "http://192.168.1.53:8066/api"  //炳林



let errorMsg = true

// 请求超时时间
axios.defaults.timeout = 100000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json';

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，统一在http请求的header都加上token
        // const token = store.state.token;
        const token = localStorage.getItem('token')
        let url = config.url


        if (!token 
            && url != '/user/register' && url != '/user/login'
            && url != '/user/updatePassword' && url != '/user/sendEmail'
            && url != '/system/getUserGAIsBind' && url != '/user/checkCode'
            && url != '/system/checkGACode' && url != '/user/updatePassword'
            && url != '/user/isEmailCheck' && url != '/user/getTemporaryToken'
            && url != '/user/scanCodeLogin' && url != '/inviteUser/inviteRequest'
            && url != '/strategy/getStrategyRank'
            && url != '/article/getArticleType' && url != '/article/articleList'
            && url != '/article/articleDetails' && url != '/article/recommendedArticle'
        ) {

            setTimeout(() => {
                store.commit("setIsSkeleton", false);
            }, 500);
            router.replace({
                path: '/login',
            });
            return
        }

        if (!token && url != '/user/scanCodeLogin' && url != '/user/getTemporaryToken'
        && url != '/article/getArticleType' && url != '/article/articleList'
        && url != '/article/articleDetails' && url != '/article/recommendedArticle') {
            store.commit('clearSession', null)
        }

        token && (config.headers.Authorization = token);
        return config;
    },
    error => {

        return Promise.error(error);
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        store.commit('setIsLoading', false);

        if (response.status === 200) {
            let lang = localStorage.getItem('lang')

            if ((response.data.status == 1005 || response.data.status == 1006) && lang != 'en') {

                if (errorMsg) {
                    Message({
                        message: '登录过期，请重新登录',
                        type: 'error',
                        duration: 5 * 1000
                    })
                    errorMsg = false
                }
                router.replace({
                    path: '/login',
                });
                store.commit('clearSession', null)
                return
            } else if ((response.data.status == 1005 || response.data.status == 1006) && lang == 'en') {
                if (errorMsg) {
                    Message({
                        message: 'Token Error',
                        type: 'error',
                        duration: 5 * 1000
                    })
                    errorMsg = false
                }
                router.replace({
                    path: '/login',
                });
                store.commit('clearSession', null)
                return
            }

            errorMsg = true

            if (lang == 'en') {
                response.data.msg = publicMethod.msgEvent(response.data.status)
            }

            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况    
    error => {
        store.commit('setIsLoading', false);

        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录                
                // 未登录则跳转登录页面，并携带当前页面的路径                
                // 在登录成功后返回当前页面，这一步需要在登录页操作。                
                case 401:
                    router.replace({
                        path: '/login',
                        query: { redirect: router.currentRoute.fullPath }
                    });
                    break;
                // 403 token过期                
                // 登录过期对用户进行提示                
                // 清除本地token和清空vuex中token对象                
                // 跳转登录页面                
                case 403:
                    Message({
                        message: '登录过期，请重新登录',
                        type: 'error',
                        duration: 5 * 1000
                    })
                    // 清除token                    
                    // localStorage.removeItem('token');
                    // store.commit('loginSuccess', null);
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    setTimeout(() => {
                        router.replace({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.fullPath
                            }
                        });
                    }, 1000);
                    break;
                // 404请求不存在                
                case 404:
                    Message({
                        message: '网络请求不存在',
                        type: 'error',
                        duration: 5 * 1000
                    })
                    break;
                // 其他错误，直接抛出错误提示                
                default:
                    Message({
                        message: error.response.data.message,
                        type: 'error',
                        duration: 5 * 1000
                    })
            }
            return Promise.reject(error.response);
        }
    }
);
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} data [请求时携带的参数] 
 */
export function post(url, data, loading) {
    if(loading) store.commit('setIsLoading', loading);
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
